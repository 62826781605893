import styled from "@emotion/styled";
import * as React from "react";
import { DARK_SECONDARY } from "../../colors";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";

type Props = {} & ShortcutProps;

function Help(props: Props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8 8C13.8 11.2033 11.2033 13.8 8 13.8C4.79675 13.8 2.2 11.2033 2.2 8C2.2 4.79675 4.79675 2.2 8 2.2C11.2033 2.2 13.8 4.79675 13.8 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8.38721 9.64844H7.08643C7.03564 8.25 7.43799 7.73438 8.18408 7.27344C8.67627 6.96484 9.02002 6.59766 9.02002 6.05859C9.02002 5.44531 8.53955 5.04688 7.94189 5.04688C7.39111 5.04688 6.85986 5.39844 6.82471 6.13281H5.43408C5.47314 4.64844 6.58252 3.89062 7.94971 3.89062C9.44189 3.89062 10.4692 4.71875 10.4692 6.03906C10.4692 6.89062 10.0591 7.48047 9.35205 7.91797C8.6333 8.35938 8.38721 8.69922 8.38721 9.64844ZM8.64893 11.2695C8.65283 11.7383 8.26221 12.1133 7.76611 12.1133C7.28174 12.1133 6.89111 11.7383 6.88721 11.2695C6.89111 10.7969 7.28174 10.4258 7.76611 10.4258C8.26221 10.4258 8.65283 10.7969 8.64893 11.2695Z"
        fill={DARK_SECONDARY}
      />
    </Svg>
  );
}

export default withShortcuts(Help);

const Svg = styled.svg`
  height: 16px;
  width: 16px;
  fill: none;
`;

const Path = styled.path``;

import { Spinner } from "evergreen-ui";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { RouteComponentProps } from "react-router";
import Contacts from "../components/contacts/Contacts";
import DashboardShell from "../components/shared/DashboardShell";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";
import { CurrentUser } from "../graphql/generated/types";
import { CURRENT_USER } from "../graphql/queries";
import { TAB } from "../types";

class ContactsPage extends React.Component<RouteComponentProps<any>, void> {
  render() {
    return (
      <Query query={CURRENT_USER}>
        {({ loading, error, data }: QueryResult<CurrentUser>) => {
          if (loading) {
            return <Spinner></Spinner>;
          }
          if (error || !data || !data.me || !data.me.user) {
            return <ErrorShowIntercom />;
          }

          return (
            <DashboardShell activeTab={TAB.CONTACTS} user={data.me.user}>
              <Contacts user={data.me.user} />
            </DashboardShell>
          );
        }}
      </Query>
    );
  }
}

export default ContactsPage;

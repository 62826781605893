import styled from "@emotion/styled";
import * as React from "react";

type Props = {};

type State = {};

class Enter extends React.Component<Props, State> {
  render() {
    return (
      <Svg viewBox="0 0 110 110">
        <path d="M101.99 0C97.5666 0 93.9797 3.5858 93.9797 8.00986V76.8925H34.4625L38.0835 74.4786C41.7643 72.0258 42.7595 67.0523 40.3045 63.3725C37.8506 59.6928 32.8769 58.6977 29.198 61.1515L3.56694 78.2383C1.33885 79.724 0 82.2238 0 84.9024C0 87.581 1.33785 90.0817 3.56694 91.5664L29.198 108.653C30.5639 109.564 32.1076 110 33.6332 110C36.221 110 38.7619 108.747 40.3045 106.432C42.7585 102.752 41.7643 97.7789 38.0835 95.3261L34.4625 92.9122H101.99C106.413 92.9122 110 89.3264 110 84.9024V8.01087C110 3.58581 106.413 0 101.99 0Z" />
      </Svg>
    );
  }
}

export default Enter;

const Svg = styled.svg`
  height: 10px;
  width: 10px;
  fill: rgb(187, 188, 190);
`;

import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY_LIGHT
} from "../../../colors";
import { SubmitFeedback } from "../../../graphql/generated/types";
import { SUBMIT_FEEDBACK } from "../../../graphql/mutations";
import withShortcuts from "../../../shortcuts/withShortcuts";
import { ShortcutProps, UserWithProfilePicture } from "../../../types";
import { Button } from "../StyledComponents";

type Props = {
  hideModal: () => any;
  user: UserWithProfilePicture;
} & ShortcutProps;

function FeedbackModal(props: Props) {
  useEffect(() => {
    props.manager.bind(
      "esc",
      props.hideModal,
      "FeedbackModal",
      "Hide the feedback modal",
      5
    );

    return () => {
      props.manager.unbind("esc", "FeedbackModal");
    };
  });
  const [feedback, setFeedback] = useState<string>("");
  const [submitFeedback, { loading: submitFeedbackLoading }] = useMutation<
    SubmitFeedback
  >(SUBMIT_FEEDBACK);
  return (
    <Background onClick={props.hideModal}>
      <Modal onClick={e => e.stopPropagation()}>
        <Body>
          <TextArea
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
            placeholder="Have an issue? Leave feedback here..."
            rows={5}
          />
          <ButtonRow>
            <CreateButton
              onClick={async () => {
                await submitFeedback({
                  variables: {
                    input: {
                      userId: props.user.id,
                      content: feedback
                    }
                  }
                });
                props.hideModal();
                props.setNotification({
                  notification: "Thanks for submitting feedback!",
                  timestamp: Date.now()
                });
              }}
            >
              Submit
            </CreateButton>
          </ButtonRow>
        </Body>
      </Modal>
    </Background>
  );
}

export default withShortcuts(FeedbackModal);

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 4px;
  display: flex;
  color: ${DARK_SECONDARY_LIGHT};
  background-color: ${DARK_PRIMARY};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${DARK_PRIMARY_LIGHT};
  padding: 20px 20px 20px 20px;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  background-color: ${DARK_PRIMARY};
  color: ${DARK_SECONDARY_LIGHT};
  border: none;
  outline: none;
  font-size: 1.5em;
`;

const CreateButton = styled(Button)`
  flex: 1;
`;

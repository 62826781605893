import { useQuery } from "@apollo/react-hooks";
import React from "react";
import Insights from "../components/insights/Insights";
import LoadingScreen from "../components/LoadingScreen";
import DashboardShell from "../components/shared/DashboardShell";
import { CurrentUser } from "../graphql/generated/types";
import { CURRENT_USER } from "../graphql/queries";
import { TAB } from "../types";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";

// fetch notes here

function InsightsPage() {
  const { data: meData, loading: meLoading, error: meError } = useQuery<
    CurrentUser
  >(CURRENT_USER);
  const user = meData?.me?.user;

  if (meLoading) {
    return <LoadingScreen />;
  } else if (!user || meError) {
    return <ErrorShowIntercom></ErrorShowIntercom>;
  }

  return (
    <DashboardShell activeTab={TAB.INSIGHTS} user={user}>
      <Insights user={user} />
    </DashboardShell>
  );
}

export default InsightsPage;

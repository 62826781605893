import styled from "@emotion/styled";
import * as React from "react";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_DARK,
  SECONDARY,
  SECONDARY_LIGHT,
  TERTIARY_DARK
} from "../../colors";

type Shortcut = {
  keys: string;
  callback: () => any;
};

type Props = {
  border?: boolean;
  forwardRef?: React.Ref<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

type State = {};

class StyledInput extends React.Component<Props, State> {
  render() {
    const { border, forwardRef } = this.props;

    return <Input border={border} ref={forwardRef} {...this.props}></Input>;
  }
}

export default StyledInput;

const Input = styled.input<any>`
  -webkit-appearance: none;
  border: 1px solid ${DARK_PRIMARY};
  background-image: none;
  background-color: ${DARK_PRIMARY_DARK};
  border-radius: 4px;
  color: ${SECONDARY_LIGHT};
  border: ${props => (props.border ? `1px solid ${TERTIARY_DARK}` : "none")};
  :focus {
    border: 1px solid ${TERTIARY_DARK};
    outline: none;
  }
  ::placeholder {
    color: ${SECONDARY};
  }
  padding: 10px 12px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.18);
  font-size: 14px;
`;

import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import * as React from "react";
import {
  DARK_PRIMARY_DARK,
  DARK_PRIMARY_LIGHT,
  PRIMARY_DARK,
  PRIMARY_LIGHT
} from "../../colors";
import {
  GetInboxForUser_getInboxForUser_notes_IntercomNote,
  getIntercomConversation,
  getIntercomConversation_getIntercomConversation,
  getIntercomConversation_getIntercomConversation_conversation_messages
} from "../../graphql/generated/types";
import { GET_INTERCOM_CONVERSATION } from "../../graphql/queries";
import withShortcuts from "../../shortcuts/withShortcuts";
import { HighlightableTypes, ShortcutProps } from "../../types";
import HighlightableText, { Highlight } from "../shared/HighlightableText";
import HighlightModal from "../shared/HighlightModal";
import HighlightTooltip from "../shared/HighlightTooltip";

type Props = {
  note: GetInboxForUser_getInboxForUser_notes_IntercomNote;
} & ShortcutProps;

function IntercomConversationEditor(props: Props) {
  const { note } = props;
  const { conversationId } = note;
  const [currentMessageId, setCurrentMessageId] = React.useState("");
  const [currentHighlight, setCurrentHighlight] = React.useState<Highlight>();
  const [showHighlightModal, setShowHighlightModal] = React.useState(false);
  const highlightModalDiv = React.useRef<HTMLDivElement>(null);
  const currentHighlightedMessage = React.useRef<HTMLDivElement>(null);

  // Handles closing the highlight modal
  React.useEffect(() => {
    const handleDocumentMouseDown = (event: any) => {
      if (
        setShowHighlightModal &&
        highlightModalDiv.current &&
        !highlightModalDiv.current.contains(event.target)
      ) {
        setShowHighlightModal(false);
      }
    };
    document.addEventListener("mousedown", handleDocumentMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown);
    };
  }, []);

  const { data, loading } = useQuery<getIntercomConversation>(
    GET_INTERCOM_CONVERSATION,
    {
      variables: {
        input: {
          conversationId: conversationId
        }
      }
    }
  );

  if (loading) {
    return (
      <Container style={{ alignItems: "center" }}>
        <Spinner></Spinner>
      </Container>
    );
  }

  const handleUnhighlight = () => {
    setShowHighlightModal(false);
    setCurrentHighlight(undefined);
    setCurrentMessageId("");
  };

  const handleHighlight = (highlight: Highlight) => {
    setCurrentHighlight(highlight);
  };

  const handleClickHighlight = (highlight: Highlight) => {
    setCurrentHighlight(highlight);
    // kind of a hack
    setTimeout(() => {
      setShowHighlightModal(true);
    }, 50);
  };

  const handleCreateNewHighlight = (highlight: Highlight) => {
    // If a new highlight was created, change the state highlight to reference the new one (which has an ID)
    setCurrentHighlight(highlight);
  };

  // Render conversation
  const {
    conversation
  } = data?.getIntercomConversation as getIntercomConversation_getIntercomConversation;
  const { messages } = conversation;
  const sortedMessages = messages.sort((a, b) => {
    return a.createdAt - b.createdAt;
  });

  function renderIntercomMessage(
    message: getIntercomConversation_getIntercomConversation_conversation_messages
  ) {
    if (!message.body) {
      return null;
    }
    const { highlights }: { highlights: Highlight[] } = message;

    const isCurrentHighlightedMessage = currentMessageId === message.id;

    return (
      <IntercomMessage
        ref={isCurrentHighlightedMessage ? currentHighlightedMessage : null}
        key={message.id}
        isUserMessage={message.isUser}
      >
        <HighlightableText
          doNotRemove={showHighlightModal}
          highlights={highlights}
          currentHighlight={
            isCurrentHighlightedMessage ? currentHighlight : undefined
          }
          active={isCurrentHighlightedMessage}
          text={message.body}
          onRemoveCurrentHighlight={handleUnhighlight}
          onHighlight={(highlight: Highlight) => {
            setShowHighlightModal(false);
            setCurrentMessageId(message.id);
            handleHighlight(highlight);
          }}
          onClickHighlight={(highlight: Highlight) => {
            setCurrentMessageId(message.id);
            handleClickHighlight(highlight);
          }}
          renderTooltip={() => {
            return (
              <HighlightTooltip
                onClick={(e: any) => {
                  setShowHighlightModal(true);
                }}
              ></HighlightTooltip>
            );
          }}
        ></HighlightableText>
      </IntercomMessage>
    );
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {sortedMessages &&
          sortedMessages.map(message => renderIntercomMessage(message))}
      </div>
      {showHighlightModal && (
        <div
          ref={highlightModalDiv}
          style={{
            position: "fixed",
            top: 70,
            left: 40,
            backgroundColor: PRIMARY_DARK,
            boxShadow: "0 3px 10px 0 rgba(71, 80, 89, 0.15)",
            border: `1px solid ${PRIMARY_LIGHT}`,
            width: 400,
            display: "flex",
            flexDirection: "column",
            zIndex: 100
          }}
        >
          <HighlightModal
            onClose={() => {
              setShowHighlightModal(false);
              setCurrentMessageId("");
            }}
            onCreateNewHighlight={handleCreateNewHighlight}
            highlight={currentHighlight!}
            targetId={currentMessageId}
            targetType={HighlightableTypes.INTERCOM_MESSAGE}
          ></HighlightModal>
        </div>
      )}
    </Container>
  );
}

export default withShortcuts(IntercomConversationEditor);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 40px 40px 0;
  position: relative;
`;

const IntercomMessage = styled.div<{ isUserMessage: boolean }>`
  margin-bottom: 12px;
  border-radius: 5px;
  width: 90%;
  background-color: ${props =>
    props.isUserMessage ? DARK_PRIMARY_LIGHT : DARK_PRIMARY_DARK};
  align-self: ${props => (props.isUserMessage ? "flex-start" : "flex-end")};
`;

import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { DARK_SECONDARY_LIGHT } from "../colors";
import Logo from "./icons/Logo";

type Props = {};

type State = {};

const MESSAGES = [
  "Understand what people want.",
  "Every customer is important.",
  "One person's bug is another person's feature."
];

function LoadingScreen(props: Props) {
  const [offset, setOffset] = useState<number>(0);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    let intervalId: any = null;
    const timeout = setTimeout(() => {
      setShowMessage(true);
      intervalId = setInterval(() => {
        setOffset((offset + 1) % MESSAGES.length);
      }, 3000);
    }, 3000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      clearTimeout(timeout);
    };
  });
  return (
    <LoaderContainer>
      <Logo animated height="300px" width="300px" />
      <MotivationalMessage>
        {showMessage && MESSAGES[offset]}
      </MotivationalMessage>
    </LoaderContainer>
  );
}

export default LoadingScreen;
const LoaderContainer = styled.div`
  padding-top: 20%;
  padding-bottom: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MotivationalMessage = styled.div`
  text-align: center;
  margin-top: 100px;
  color: ${DARK_SECONDARY_LIGHT};
  font-size: 20px;
  width: 40%;
`;

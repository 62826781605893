import * as queryString from "query-string";
import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { RouteComponentProps, Redirect } from "react-router";
import Contact from "../components/contacts/Contact";
import DashboardShell from "../components/shared/DashboardShell";
import { CurrentUserWithContact } from "../graphql/generated/types";
import { CURRENT_USER_WITH_CONTACT } from "../graphql/queries";
import { TAB } from "../types";
import { Spinner } from "evergreen-ui";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";

class ContactPage extends React.Component<RouteComponentProps<any>, void> {
  render() {
    const id = queryString.parse(this.props.location.search).id;

    if (!id) {
      return <Redirect to="/contacts"></Redirect>;
    }
    return (
      <Query query={CURRENT_USER_WITH_CONTACT} variables={{ id: id }}>
        {({ loading, error, data }: QueryResult<CurrentUserWithContact>) => {
          if (loading) {
            return <Spinner></Spinner>;
          }
          if (error || !data || !data.me || !data.me.user) {
            return <ErrorShowIntercom></ErrorShowIntercom>;
          }

          return (
            <DashboardShell activeTab={TAB.CONTACTS} user={data.me.user}>
              <Contact user={data.me.user} />
            </DashboardShell>
          );
        }}
      </Query>
    );
  }
}

export default ContactPage;

import styled from "@emotion/styled";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Node } from "slate";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY,
  DARK_SECONDARY_LIGHT
} from "../../colors";
import {
  CurrentUserWithInsight_me_user_organization_tag,
  CurrentUserWithInsight_me_user_organization_tag_manualNotes
} from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import { generateRandomColor, getTaggedContentFromNote } from "../../utils";

type Props = {
  tag: CurrentUserWithInsight_me_user_organization_tag;
  note: CurrentUserWithInsight_me_user_organization_tag_manualNotes;
  public?: boolean;
} & ShortcutProps &
  RouteComponentProps;

function DetailedManualNoteItem(props: Props) {
  const relevantTaggedContent = getTaggedContentFromNote(
    JSON.parse(props.note.content),
    props.tag.id
  );

  const content = JSON.parse(props.note.content).map((node: Node) => {
    return node.children.map((leaf: any) => {
      return leaf.text;
    });
  });

  return (
    <Container
      onClick={() => props.history.push(`/notes/${props.note.id}`)}
      link={!props.public}
    >
      <TitleRow>
        <Title>{props.note.title}</Title>
        <Separator />
        <ContactInformation>
          <ContactName>{props.note.contact?.name || "Anonymous"}</ContactName>
        </ContactInformation>
      </TitleRow>
      <TaggedContent>
        {relevantTaggedContent.map((relevantContent, index) => {
          return (
            <TaggedLeaf key={index} color={generateRandomColor(props.tag.id)}>
              {relevantContent}
            </TaggedLeaf>
          );
        })}
      </TaggedContent>
      <Content>{content}</Content>
    </Container>
  );
}

export default withShortcuts(withRouter(DetailedManualNoteItem));

const TaggedLeaf = styled.div<{ color: string }>`
  display: flex;
  color: ${DARK_SECONDARY_LIGHT};
  background-color: ${props => props.color};
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 6px 12px;
  max-width: calc(100% - 24px);
  word-break: break-word;
`;

const TaggedContent = styled.div`
  align-items: flex-start;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;

const Container = styled.div<{ link: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${DARK_PRIMARY};
  width: 100%;
  border: 1px solid ${DARK_PRIMARY_LIGHT};
  border-radius: 4px;
  margin-bottom: 20px;
  ${props =>
      props.link
        ? `:hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
  cursor: pointer;
  `
        : ""}
    :last-child {
    margin-bottom: 0px;
  }
  transition: 0.16s ease-in-out;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
  height: 20px;
`;

const Title = styled.div`
  color: ${DARK_SECONDARY_LIGHT};
`;

const Separator = styled.div`
  width: 1px;
  height: 18px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: ${DARK_PRIMARY_LIGHT};
`;

const ContactInformation = styled.div`
  display: flex;
`;

const ContactName = styled.div`
  color: ${DARK_SECONDARY};
`;

const Content = styled.div`
  display: block;
  color: ${DARK_SECONDARY_LIGHT};
  margin-left: 20px;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 80px);
  overflow: hidden;
  margin-bottom: 20px;
`;

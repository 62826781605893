import styled from "@emotion/styled";
import * as React from "react";
import { Mutation, MutationFunction, MutationResult } from "react-apollo";
import { DARK_SECONDARY, DARK_SECONDARY_LIGHT } from "../../colors";
import {
  CurrentUser_me_user,
  CreateContact
} from "../../graphql/generated/types";
import { CREATE_CONTACT } from "../../graphql/mutations";
import { CURRENT_USER } from "../../graphql/queries";
import withShortcuts from "../../shortcuts/withShortcuts";
import { CREATE_CONTACT_ERROR, ShortcutProps } from "../../types";
import { validateEmail } from "../../utils/index";
import Command from "../icons/Keys/Command";
import Enter from "../icons/Keys/Enter";
import ClickOutside from "../shared/ClickOutside";
import Input from "../shared/InputWithShortcuts";
import Shortcut from "../shared/Shortcut";
import { Button } from "../shared/StyledComponents";

type Props = {
  user: CurrentUser_me_user;
  onHideModal: () => any;
  onCreateContact?: (data: CreateContact) => void;
} & ShortcutProps;

type State = {
  email: string;
  name: string;
  error: CREATE_CONTACT_ERROR | null;
  anotherContact: boolean;
};

class AddContactModal extends React.Component<Props, State> {
  submitFunction: any = null;
  commandSubmitFunction: any = null;

  emailRef = React.createRef<any>();

  state: State = {
    email: "",
    name: "",
    error: null,
    anotherContact: false
  };

  UNSAFE_componentWillMount() {
    // Prevent tab switching when at modal
    this.props.manager.bind(
      "tab",
      (ev: any) => {
        ev.stopPropagation();
      },
      this.constructor.name,
      "",
      1
    );
    this.props.manager.bind(
      "enter",
      this.submitFunction,
      this.constructor.name,
      "Create Contact",
      1
    );
    this.props.manager.bind(
      "command+enter",
      this.commandSubmitFunction,
      this.constructor.name,
      "Fast Add Contact",
      1
    );
    this.props.manager.bind(
      "esc",
      this.props.onHideModal,
      this.constructor.name,
      "Hide Add Contact Modal",
      3
    );
  }

  componentWillUnmount() {
    this.props.manager.unbind("command+enter", this.constructor.name);
    this.props.manager.unbind("enter", this.constructor.name);
  }

  onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    this.setState({
      email: event.target.value
    });
  };

  onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    this.setState({
      name: event.target.value
    });
  };

  validateFields = () => {
    if (this.state.email === "" || !validateEmail(this.state.email)) {
      this.setState({
        error: CREATE_CONTACT_ERROR.EMAIL
      });
      return false;
    }

    return true;
  };

  onCreateContact = (data: any) => {
    if (this.state.anotherContact) {
      this.setState({
        email: "",
        name: "",
        error: null
      });
      this.emailRef.current && this.emailRef.current.focus();
    } else {
      this.props.onHideModal();
    }
    if (this.props.onCreateContact) {
      this.props.onCreateContact(data);
    }
  };

  render() {
    return (
      <ClickOutside onClickOutside={this.props.onHideModal}>
        <Background onClick={this.props.onHideModal}>
          <Container
            onClick={ev => {
              ev.stopPropagation();
            }}
          >
            <Mutation
              mutation={CREATE_CONTACT}
              onCompleted={this.onCreateContact}
              refetchQueries={[{ query: CURRENT_USER }]}
            >
              {(
                createContact: MutationFunction,
                { data, loading }: MutationResult
              ) => {
                const submit = () => {
                  if (!this.validateFields()) {
                    return;
                  }

                  createContact({
                    variables: {
                      input: {
                        email: this.state.email,
                        name: this.state.name
                      }
                    }
                    // optimisticResponse: {
                    //   createContact: {
                    //     email: this.state.email,
                    //     name: this.state.name,
                    //     __typename: "Contact"
                    //   }
                    // }
                  });
                };

                const submitContact = (another: boolean) => {
                  this.setState(
                    {
                      anotherContact: another
                    },
                    () => {
                      submit();
                    }
                  );
                };

                this.props.manager.updateCallback(
                  "enter",
                  () => {
                    submitContact(true);
                  },
                  this.constructor.name
                );
                this.props.manager.updateCallback(
                  "command+enter",
                  () => {
                    submitContact(true);
                  },
                  this.constructor.name
                );

                return (
                  <Modal>
                    <Header>New Person</Header>
                    <Body>
                      <Label>NAME</Label>
                      <StyledInput
                        placeholder="John Doe"
                        value={this.state.name}
                        onChange={this.onChangeName}
                        shortcuts={[
                          {
                            keys: "mod+enter",
                            callback: () => {
                              submitContact(true);
                            }
                          },
                          {
                            keys: "enter",
                            callback: () => {
                              submitContact(false);
                            }
                          },
                          {
                            keys: "escape",
                            callback: this.props.onHideModal
                          }
                        ]}
                        autoFocus
                      />
                      <Label>EMAIL</Label>
                      <StyledInput
                        ref={this.emailRef}
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        shortcuts={[
                          {
                            keys: "mod+enter",
                            callback: () => {
                              submitContact(true);
                            }
                          },
                          {
                            keys: "enter",
                            callback: () => {
                              submitContact(false);
                            }
                          },
                          {
                            keys: "escape",
                            callback: this.props.onHideModal
                          }
                        ]}
                      />
                      <ButtonRow>
                        <CreateButton
                          onClick={() => {
                            submitContact(false);
                          }}
                        >
                          Create
                          <StyledShortcut>
                            <Enter />
                          </StyledShortcut>
                        </CreateButton>
                        <CreateButton
                          onClick={() => {
                            submitContact(true);
                          }}
                        >
                          Create Another
                          <StyledShortcut>
                            <Command />
                          </StyledShortcut>
                          <StyledShortcut>
                            <Enter />
                          </StyledShortcut>
                        </CreateButton>
                      </ButtonRow>
                    </Body>
                  </Modal>
                );
              }}
            </Mutation>
          </Container>
        </Background>
      </ClickOutside>
    );
  }
}

export default withShortcuts(AddContactModal);

const StyledShortcut = styled(Shortcut)`
  margin-left: 4px;
`;

const CreateButton = styled(Button)`
  flex: 1;
  :last-child {
    margin-left: 10px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Label = styled.div`
  color: ${DARK_SECONDARY}
  font-size: 12px;
  display: flex;
  margin-bottom: 4px;
  margin-top: 10px;
  :first-div {
    margin-top: 0px;
  }
`;

const Header = styled.div`
  color: ${DARK_SECONDARY}
  display: flex;
  padding: 10px 20px 10px 20px;
  background-color: rgb(45, 47, 49);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(36, 38, 40);
  padding: 10px 20px 20px 20px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const Container = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  left: calc(50% - 200px);
  top: 5%;
  margin: auto;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  color: ${DARK_SECONDARY_LIGHT};
  z-index: 5;
`;

const StyledInput = styled(Input)`
  padding: 10px 12px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.18);
  font-size: 14px;
`;

import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import produce from "immer";
import React from "react";
import { useHistory } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { CurrentUser, GetInboxForUser } from "../../graphql/generated/types";
import { CURRENT_USER, GET_INBOX } from "../../graphql/queries";
import IntercomConversationItem from "../notes/IntercomConversationItem";
import ManualNoteItem from "../notes/ManualNoteItem";
import { InboxFilterOptions } from "./Inbox";
import { useParams } from "react-router";

type InboxFilter = {
  selection: InboxFilterOptions;
  searchQuery: string;
};

type Props = {
  filter: InboxFilter;
};

const defaultCursor = {
  limit: 15,
  offset: 0
};

function InboxNotesList(props: Props) {
  const { id } = useParams();
  const { filter } = props;
  const { data: meData } = useQuery<CurrentUser>(CURRENT_USER);

  const { data, loading, fetchMore } = useQuery<GetInboxForUser>(GET_INBOX, {
    variables: {
      input: {
        userId: meData?.me.user?.id,
        filter,
        cursor: defaultCursor
      }
    }
  });
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const history = useHistory();
  const containerRef = React.useRef<any>(null);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px 32px"
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }

  // Get inbox data
  const notes = data?.getInboxForUser?.notes || [];

  // Filter the notes
  let inboxNotes: typeof notes = notes
    // .filter(note => {
    //   switch (filter.selection) {
    //     case InboxFilterOptions.NEW:
    //       return !note.archived;
    //     case InboxFilterOptions.ARCHIVED:
    //       return note.archived;
    //     case InboxFilterOptions.ALL:
    //     default:
    //       return true;
    //   }
    // })
    // sort most by most recent
    .sort((a, b) => {
      return b.createdAt - a.createdAt;
    });

  return (
    <div style={{ flex: 1, height: 1 }}>
      <SimpleBar
        style={{ height: "100%", zIndex: 2 }}
        ref={containerRef as any}
        onScroll={async e => {
          if (containerRef.current && !isLoadingMore) {
            const scrollElement = containerRef.current.getScrollElement();
            if (
              data?.getInboxForUser.hasMore &&
              scrollElement.scrollHeight - scrollElement.scrollTop <=
                scrollElement.clientHeight
            ) {
              setIsLoadingMore(true);
              await fetchMore({
                variables: {
                  input: {
                    userId: meData?.me.user?.id,
                    filter,
                    cursor: {
                      ...defaultCursor,
                      offset: inboxNotes.length
                    }
                  }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return produce(prev, draft => {
                    draft.getInboxForUser.notes = draft.getInboxForUser.notes.concat(
                      fetchMoreResult?.getInboxForUser?.notes || []
                    );
                    draft.getInboxForUser.hasMore =
                      fetchMoreResult?.getInboxForUser?.hasMore;
                  });
                }
              });
              setIsLoadingMore(false);
            }
          }
        }}
      >
        <NotesList>
          {inboxNotes.map((note, index) => {
            if (note.__typename === "IntercomNote") {
              return (
                <IntercomConversationItem
                  key={index}
                  active={id === note.id}
                  onClick={() => {
                    history.push(`/notes/${note.id}`);
                  }}
                  note={note}
                />
              );
            } else if (note.__typename === "ManualNote") {
              return (
                <ManualNoteItem
                  key={index}
                  active={id === note.id}
                  openNote={() => {
                    history.push(`/notes/${note.id}`);
                  }}
                  note={note}
                  contact={null as any}
                />
              );
            }
          })}
          {isLoadingMore && <div>Loading more...</div>}
        </NotesList>
      </SimpleBar>
    </div>
  );
}

const NotesList = styled.div`
  display: flex;
  flex-direction: column;
`;

export default InboxNotesList;

import React from "react";

export type ThemeContext = {
  dark: boolean;
  toggle: () => void;
};

export const ThemeContext = React.createContext<ThemeContext>({
  dark: false,
  toggle: () => {} // this will be replaced
});

function ThemeProvider(props: { children: React.ReactChild }) {
  const [dark, setDark] = React.useState(true); // Default to dark theme, too lazy to change everything
  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggle: () => {
          setDark(!dark);
        }
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}

export const useThemeContext = () => React.useContext(ThemeContext);

export default ThemeProvider;

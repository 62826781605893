import { useMutation } from "@apollo/react-hooks";
import Button from "../shared/Button";
import gql from "graphql-tag";
import produce from "immer";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getCurrentUser, loginWithGoogle } from "../../firebase";
import { CURRENT_USER } from "../../graphql/queries";

const LOGIN_FIREBASE = gql`
  mutation loginWithFirebaseToken($token: String!) {
    loginWithFirebaseToken(token: $token) {
      error {
        message
      }
      user {
        id
        email
      }
      authToken
    }
  }
`;

type Props = {
  onLogin?: (authToken: string) => void;
} & RouteComponentProps;

function SignupForm(props: Props) {
  const { onLogin, history } = props;
  const [loginWithFirebaseToken] = useMutation(LOGIN_FIREBASE, {
    update: (cache, result) => {
      try {
        const {
          data: {
            loginWithFirebaseToken: { user }
          }
        } = result;
        const meData = cache.readQuery({ query: CURRENT_USER });
        const nextState = produce(meData, (draftState: any) => {
          draftState.me.user = user;
        });
        cache.writeQuery({
          query: CURRENT_USER,
          data: nextState
        });
      } catch (e) {}
    }
  });

  const handleSignup = () => {
    return loginWithGoogle()
      .then(user => {
        if (user) {
          return getCurrentUser();
        }
        return null;
      })
      .then(token => {
        if (token) {
          // verify token on backend
          return loginWithFirebaseToken({ variables: { token } });
        }
      })
      .then(result => {
        if (!result) {
          // Fail to verify
          return;
        }
        const {
          data: { loginWithFirebaseToken }
        } = result;
        const { error, authToken } = loginWithFirebaseToken;
        if (error) {
          console.error(error.message);
          return;
        }
        // Save user session
        localStorage.setItem("omnitionauthtoken", authToken);

        // Call complete login handler
        if (onLogin) {
          onLogin(authToken);
        }
      });
  };
  return (
    <Button onClick={handleSignup}>
      <span style={{ paddingLeft: 4 }}>Continue with Google</span>
    </Button>
  );
}

export default withRouter(SignupForm);

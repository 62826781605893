import styled from "@emotion/styled";
import * as React from "react";
import { SECONDARY, SECONDARY_LIGHT } from "../../colors";
import { CurrentUserWithInsight_me_user_organization_tag_contacts } from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";

type Props = {
  contact: CurrentUserWithInsight_me_user_organization_tag_contacts;
} & ShortcutProps;

function ContactItem(props: Props) {
  return (
    <Container onClick={() => {}}>
      <Title>{props.contact.name}</Title>
      <ContactInformation>
        <ContactName>{props.contact.email}</ContactName>
      </ContactInformation>
    </Container>
  );
}

export default withShortcuts(ContactItem);

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  height: 30px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const Title = styled.div`
  color: ${SECONDARY_LIGHT};
`;

const ContactInformation = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
`;

const ContactName = styled.div`
  color: ${SECONDARY};
`;

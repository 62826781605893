import styled from "@emotion/styled";
import * as React from "react";
import {
  DARK_SECONDARY_LIGHT,
  PRIMARY,
  PRIMARY_LIGHT,
  SECONDARY,
  SECONDARY_LIGHT
} from "../../colors";
import {
  CurrentUserWithInsight_me_user_organization_tag,
  CurrentUserWithInsight_me_user_organization_tag_highlights,
  CurrentUserWithInsight_me_user_organization_tag_highlights_target_IntercomMessage
} from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import { generateRandomColor } from "../../utils";

type Props = {
  tag: CurrentUserWithInsight_me_user_organization_tag;
  highlight: CurrentUserWithInsight_me_user_organization_tag_highlights;
} & ShortcutProps;

function DetailedIntercomMessageItem(props: Props) {
  const intercomMessage = props.highlight
    .target as CurrentUserWithInsight_me_user_organization_tag_highlights_target_IntercomMessage;
  const intercomNote = intercomMessage.intercomNote;
  const contact = intercomNote.contact;

  return (
    <Container onClick={() => {}}>
      <TitleRow>
        <Title>{intercomNote.title}</Title>
        <Separator />
        <ContactInformation>
          <ContactName>{contact?.name}</ContactName>
        </ContactInformation>
      </TitleRow>
      <TaggedContent>
        <TaggedLeaf color={generateRandomColor(props.tag.id)}>
          {props.highlight.content}
        </TaggedLeaf>
      </TaggedContent>
      <Content>{intercomMessage.body}</Content>
    </Container>
  );
}

export default withShortcuts(DetailedIntercomMessageItem);

const TaggedLeaf = styled.div<{ color: string }>`
  display: flex;
  color: ${DARK_SECONDARY_LIGHT};
  background-color: ${props => props.color};
  border-radius: 15px;
  height: 18px;
  margin-bottom: 10px;
  padding: 6px 12px;
`;

const TaggedContent = styled.div`
  align-items: flex-start;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${PRIMARY};
  width: 100%;
  margin-bottom: 4px;
  border: 1px solid ${PRIMARY_LIGHT};
  border-radius: 4px;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
  height: 20px;
`;

const Title = styled.div`
  color: ${SECONDARY_LIGHT};
`;

const Separator = styled.div`
  width: 1px;
  height: 18px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: ${PRIMARY_LIGHT};
`;

const ContactInformation = styled.div`
  display: flex;
`;

const ContactName = styled.div`
  color: ${SECONDARY};
`;

const Content = styled.div`
  display: block;
  color: ${SECONDARY_LIGHT};
  margin-left: 20px;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 80px);
  overflow: hidden;
  margin-bottom: 20px;
`;

import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { TextInput } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { RouteComponentProps, withRouter } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  DARK_PRIMARY_DARK,
  DARK_PRIMARY_LIGHT,
  PRIMARY_LIGHT
} from "../../colors";
import {
  CreateManualNote,
  CurrentUser_me_user
} from "../../graphql/generated/types";
import { CREATE_MANUAL_NOTE } from "../../graphql/mutations";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import NoNoteSelected from "../notes/NoNoteSelected";
import Note from "../notes/Note";
import { useInboxFilterContext } from "./InboxFilterProvider";
import InboxNotesList from "./InboxNotesList";

type Props = {
  user: CurrentUser_me_user;
} & ShortcutProps &
  RouteComponentProps;

export enum InboxFilterOptions {
  // NEW = "NEW",
  ALL = "ALL"
  // ARCHIVED = "ARCHIVED"
}

const optionsMapping = {
  // [InboxFilterOptions.NEW]: "New",
  [InboxFilterOptions.ALL]: "All"
  // [InboxFilterOptions.ARCHIVED]: "Archived"
};

function Inbox(props: Props) {
  const [searching, setSearching] = useState(false);
  const [showAddNoteModal, setShowAddNoteModal] = useState<boolean>(false);
  const { filter, setFilter } = useInboxFilterContext();
  const { selection, searchQuery } = filter;
  const [createManualNote] = useMutation<CreateManualNote>(CREATE_MANUAL_NOTE);
  const location = useLocation();

  const handleCreateNewNote = async () => {
    const result = await createManualNote({
      variables: {
        input: {
          title: "New Note",
          content: `[{"type":"paragraph","children":[{"text":""}]}]`,
          tagIds: []
        }
      }
    });
    props.history.push(
      "/notes/" + result.data?.createManualNote.note?.id || ""
    );
  };

  useEffect(() => {
    props.manager.bind("n", handleCreateNewNote, "Inbox", "Add new note", 1);
    return () => {
      props.manager.unbind("n", "Inbox");
    };
  });

  return (
    <Container>
      <NotesMainContainer>
        <Filters>
          <SearchContainer style={{ position: "relative" }}>
            {!searching && (
              <FiSearch
                style={{
                  position: "absolute",
                  color: "black",
                  top: 0,
                  left: 8,
                  bottom: 0,
                  margin: "auto 0"
                }}
              ></FiSearch>
            )}
            <TextInput
              placeholder={searching ? "Start typing..." : "Search..."}
              style={{ paddingLeft: searching ? undefined : 28 }}
              onFocus={() => {
                setSearching(true);
              }}
              onBlur={(e: any) => {
                if (e.target.value === "") {
                  setSearching(false);
                }
              }}
              onChange={(e: any) => {
                setFilter({
                  ...filter,
                  searchQuery: e.target.value
                });
              }}
              value={searchQuery}
            ></TextInput>
          </SearchContainer>
          <div
            style={{
              paddingTop: 12,
              display: "flex"
            }}
          >
            {Object.values(InboxFilterOptions).map(val => {
              return (
                <FilterOption
                  key={val}
                  active={selection === val}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      selection: val
                    });
                  }}
                >
                  {optionsMapping[val]}
                </FilterOption>
              );
            })}
          </div>
        </Filters>

        <Switch>
          <Route
            path="/"
            exact
            render={() => {
              return <InboxNotesList filter={filter as any}></InboxNotesList>;
            }}
          />
          <Route
            path="/notes/:id"
            render={() => {
              return <InboxNotesList filter={filter as any}></InboxNotesList>;
            }}
          />
        </Switch>
      </NotesMainContainer>

      <Switch>
        <Route
          path="/"
          exact
          render={() => {
            return <NoNoteSelected />;
          }}
        />
        <Route
          path="/notes/:id"
          render={() => {
            return <Note />;
          }}
        />
      </Switch>
    </Container>
  );
}

export default withRouter(withShortcuts(Inbox));

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 8px;
  color: white;
  border-bottom: 1px solid ${PRIMARY_LIGHT};
  z-index: 0;
`;

const NotesMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  z-index: 0;
`;

const FilterOption = styled.div<{ active?: boolean }>`
  padding: 8px 12px;
  margin-right: 4px;
  background-color: ${props =>
    props.active ? DARK_PRIMARY_LIGHT : DARK_PRIMARY_DARK};
  cursor: pointer;
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

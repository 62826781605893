import React from "react";
import styled from "@emotion/styled";
import { TERTIARY_LIGHT } from "../../colors";
import { useQuery } from "@apollo/react-hooks";
import { getTagById } from "../../graphql/generated/types";
import gql from "graphql-tag";
import { Spinner } from "evergreen-ui";
import { generateRandomColor } from "../../utils";

const GET_TAG_BY_ID = gql`
  query getTagById($id: ID!) {
    getTagById(id: $id) {
      id
      name
    }
  }
`;

type Props = {
  tagId: string;
  onClick: () => void;
};

function HighlightModalTag(props: Props) {
  const { tagId, onClick } = props;
  const { data, loading } = useQuery<getTagById>(GET_TAG_BY_ID, {
    variables: {
      id: tagId
    }
  });

  if (loading) {
    return <Spinner></Spinner>;
  }

  const tag = data?.getTagById!;

  return (
    <TagContainer onClick={onClick} color={generateRandomColor(tagId)}>
      {tag.name}
    </TagContainer>
  );
}

const TagContainer = styled.div<any>`
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 8px;
  background-color: ${props => props.color};
  :hover {
    background-color: ${TERTIARY_LIGHT};
  }
`;

export default HighlightModalTag;

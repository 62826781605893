import styled from "@emotion/styled";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { MdEdit } from "react-icons/md";
import { PRIMARY, PRIMARY_LIGHT } from "../../colors";
import {
  CurrentUserWithContact_me_user_organization_contact,
  UpdateContact
} from "../../graphql/generated/types";
import { UPDATE_CONTACT } from "../../graphql/mutations";
import { CURRENT_USER_WITH_CONTACT } from "../../graphql/queries";
import InputWithShortcuts from "../shared/InputWithShortcuts";
import ContactProperties from "./ContactProperties";

type Props = {
  contact: {
    id: string;
    email: string;
    name: string;
  };
};

function ContactProfile(props: Props) {
  const [name, setName] = useState<string>(props.contact.name || "");
  const [email, setEmail] = useState<string>(props.contact.email || "");
  const [nameEditable, setNameEditable] = useState<boolean>(false);
  const [emailEditable, setEmailEditable] = useState<boolean>(false);
  const [showEditName, setShowEditName] = React.useState(false);
  const [showEditEmail, setShowEditEmail] = React.useState(false);
  const [updateContact, { loading: updateContactLoading }] = useMutation<
    UpdateContact
  >(UPDATE_CONTACT);
  const updateContactInformation = async () => {
    await updateContact({
      variables: {
        contactId: props.contact.id,
        input: {
          name: name,
          email: email
        }
      },
      refetchQueries: [
        {
          query: CURRENT_USER_WITH_CONTACT,
          variables: { id: props.contact.id }
        }
      ],
      awaitRefetchQueries: true
    });

    setNameEditable(false);
    setEmailEditable(false);
  };
  return (
    <Container>
      <div
        onMouseEnter={() => {
          setShowEditName(true);
        }}
        onMouseLeave={() => {
          setShowEditName(false);
        }}
      >
        {nameEditable ? (
          <InputWithShortcuts
            style={{ fontSize: "2em" }}
            value={name}
            onChange={e => setName(e.target.value)}
            shortcuts={[
              { keys: "mod+enter", callback: updateContactInformation },
              { keys: "enter", callback: updateContactInformation }
            ]}
            onBlur={updateContactInformation}
            autoFocus
          />
        ) : (
          <Name>
            {props.contact.name}
            {showEditName && (
              <MdEdit
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => setNameEditable(true)}
              />
            )}
          </Name>
        )}
      </div>
      <div
        style={{ paddingTop: 8 }}
        onMouseEnter={() => {
          setShowEditEmail(true);
        }}
        onMouseLeave={() => {
          setShowEditEmail(false);
        }}
      >
        {emailEditable ? (
          <InputWithShortcuts
            value={email}
            onChange={e => setEmail(e.target.value)}
            shortcuts={[
              { keys: "mod+enter", callback: updateContactInformation },
              { keys: "enter", callback: updateContactInformation }
            ]}
            autoFocus
            onBlur={updateContactInformation}
          />
        ) : (
          <Email>
            {props.contact.email}

            {showEditEmail && (
              <MdEdit
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => setEmailEditable(true)}
              />
            )}
          </Email>
        )}
      </div>
      <div style={{ paddingTop: 16 }}>
        <ContactProperties contactId={props.contact.id}></ContactProperties>
      </div>
    </Container>
  );
}

export default ContactProfile;

const Email = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  padding: 80px;
  background-color: ${PRIMARY};
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  font-size: 2em;
`;

import React from "react";

function ErrorShowIntercom() {
  return (
    <div>
      Something went wrong. Please let us know through{" "}
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => (window as any).Intercom("showNewMessage")}
      >
        Intercom.
      </span>
    </div>
  );
}

export default ErrorShowIntercom;

import styled from "@emotion/styled";
import * as React from "react";
import { ClipLoader } from "react-spinners";
import {
  DARK_SECONDARY,
  DARK_TERTIARY_DARK,
  DARK_TERTIARY_LIGHT
} from "../../colors";

type Props = {
  disabled?: boolean;
  color?: string;
  secondary?: boolean;
  style?: any;
  isLoading?: boolean;
  className?: any;
  onClick: () => void;
  children: any;
};

function Button(props: Props) {
  const {
    disabled,
    color,
    secondary,
    isLoading,
    onClick,
    className,
    children,
    style
  } = props;
  return (
    <ButtonContainer
      className={className}
      style={style}
      color={color}
      disabled={disabled}
      secondary={secondary}
      onClick={onClick}
    >
      {isLoading && (
        <span style={{ verticalAlign: "middle", paddingRight: 4 }}>
          <ClipLoader color="white" size={12}></ClipLoader>
        </span>
      )}
      {children}
    </ButtonContainer>
  );
}

const ButtonContainer = styled.span<any>`
  display: inline;
  font-size: 1em;
  padding: 8px 16px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: ${props => {
    if (props.color) {
      return props.color;
    } else if (props.secondary) {
      return DARK_SECONDARY;
    }
    return DARK_TERTIARY_DARK;
  }};
  ${props => {
      if (props.disabled) {
        return `
        cursor: not-allowed;
        background-color: #bcb4f8;
        box-shadow: none !important
      `;
      } else if (props.secondary) {
        return {};
      }
    }}
    :hover,
  :focus {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    background-color: ${props => {
      return DARK_TERTIARY_LIGHT;
    }};
  }
`;

export default Button;

import * as firebase from "firebase/app";
import "firebase/auth";

const firebaseDevConfig = {
  apiKey: "AIzaSyDZWvMV2Rqqi-IYLVBJCNqFBQOHHiOCQ80",
  authDomain: "omnition.firebaseapp.com",
  databaseURL: "https://omnition.firebaseio.com",
  projectId: "omnition",
  storageBucket: "omnition.appspot.com",
  messagingSenderId: "16493518082",
  appId: "1:16493518082:web:acc76302833fab28b7b61b",
  measurementId: "G-3G05RP6QVH"
};

// Add prod firebase config

const firebaseApp = firebase.initializeApp(firebaseDevConfig);

export function createUserWithEmailAndPassword(
  email: string,
  password: string
) {
  firebase.auth().createUserWithEmailAndPassword(email, password);
}

export async function loginWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope("profile");
  provider.addScope("email");
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(result => {
      const user = result.user;
      return user;
    })
    .catch(function(error) {
      // Handle Errors here.
      throw error;
    });
}

export async function getCurrentUser() {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return null;
  }
  return currentUser.getIdToken();
}

export default firebaseApp;

import gql from "graphql-tag";

export const CURRENT_USER = gql`
  query CurrentUser {
    me {
      user {
        id
        email
        name
        profilePictureUrl
        organization {
          id
          name
          tags {
            id
            name
          }
          contacts {
            id
            name
            email
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export const CURRENT_USER_WITH_CONTACTS = gql`
  query CurrentUserWithContacts($contactsFilter: ContactsFilter) {
    me {
      user {
        id
        email
        name
        profilePictureUrl
        organization {
          id
          name
          tags {
            id
            name
          }
          contacts(filter: $contactsFilter) {
            id
            name
            email
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export const CURRENT_USER_WITH_CONTACT = gql`
  query CurrentUserWithContact($id: ID!) {
    me {
      user {
        id
        email
        profilePictureUrl
        organization {
          id
          contact(id: $id) {
            id
            email
            name
            manualNotes {
              id
              type
              title
              content
            }
            intercomNotes {
              id
              type
              title
              archived
              type
              title
              createdAt
              updatedAt
              contact {
                id
                name
              }
              conversationId
            }
          }
          tags {
            id
            name
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export const GET_NOTE = gql`
  query getNote($input: GetNoteInput!) {
    getNote(input: $input) {
      note {
        id
        archived
        type
        title
        createdAt
        updatedAt
        contact {
          id
          name
          email
        }
        ... on IntercomNote {
          conversationId
        }
        ... on ManualNote {
          content
          editingUserId
        }
      }
    }
  }
`;

export const GET_INBOX = gql`
  query GetInboxForUser($input: GetInboxForUserInput!) {
    getInboxForUser(input: $input) {
      error {
        message
      }
      hasMore
      notes {
        id
        archived
        type
        title
        createdAt
        updatedAt
        contact {
          id
          name
        }
        ... on IntercomNote {
          conversationId
        }
        ... on ManualNote {
          content
        }
      }
    }
  }
`;

export const GET_INTERCOM_CONVERSATION = gql`
  query getIntercomConversation($input: GetIntercomConversationInput!) {
    getIntercomConversation(input: $input) {
      error {
        message
      }
      conversation {
        id
        createdAt
        updatedAt
        type
        messages {
          id
          body
          isUser
          authorType
          createdAt
          highlights {
            id
            content
            startOffset
            endOffset
            tags {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_TAGS_BY_ORGANIZATION = gql`
  query GetTagsByOrganization($id: ID!) {
    getTagsByOrganization(id: $id) {
      error {
        message
      }
      tags {
        id
        name
      }
    }
  }
`;

export const CURRENT_USER_WITH_TAGS = gql`
  query CurrentUserWithTags {
    me {
      user {
        id
        email
        name
        profilePictureUrl
        organization {
          id
          tags {
            id
            name
            noteCount
            contactCount
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export const CURRENT_USER_WITH_INSIGHT = gql`
  query CurrentUserWithInsight($tagId: ID!) {
    me {
      user {
        id
        email
        name
        profilePictureUrl
        organization {
          id
          tag(id: $tagId) {
            id
            name
            noteCount
            contactCount
            contacts {
              id
              name
              email
            }
            manualNotes {
              id
              content
              title
              contact {
                id
                name
              }
            }
            highlights {
              id
              content
              startOffset
              endOffset
              target {
                ... on IntercomMessage {
                  id
                  body
                  intercomNote {
                    id
                    title
                    contact {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CURRENT_USER_WITH_SETTINGS = gql`
  query CurrentUserWithSettings {
    me {
      user {
        id
        email
        name
        profilePictureUrl
        organization {
          id
          name
          users {
            id
            email
            name
          }
          integrations {
            id
            type
            token
          }
          googleAuth {
            id
          }
          googleAuthEmail
        }
      }
      error {
        message
      }
    }
  }
`;

export const SHARED_ORGANIZATION_WITH_INSIGHT = gql`
  query SharedOrganizationWithInsight($organizationId: ID!, $insightId: ID!) {
    organization(id: $organizationId) {
      id
      name
      tag(id: $insightId) {
        id
        name
        noteCount
        contactCount
        contacts {
          id
          name
          email
        }
        manualNotes {
          id
          content
          title
          contact {
            id
            name
          }
        }
        highlights {
          id
          content
          startOffset
          endOffset
          target {
            ... on IntercomMessage {
              id
              body
              intercomNote {
                id
                title
                contact {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTACT_BY_ID = gql`
  query GetContactById($id: ID!) {
    getContactById(id: $id) {
      id
      name
      email
      properties {
        id
        name
        value
      }
    }
  }
`;

import React from "react";
import { FiEdit2 } from "react-icons/fi";
import styled from "@emotion/styled";

type Props = {
  onClick: (e: any) => void;
};

function HighlightTooltip(props: Props) {
  return (
    <Container
      onMouseDown={e => {
        e.stopPropagation();
      }}
      onClick={e => {
        props.onClick(e);
      }}
    >
      <FiEdit2></FiEdit2>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 30px;
  background-color: white;
  color: black;
  padding: 4px;
  cursor: pointer;
  z-index: 100;
`;

export default HighlightTooltip;

import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { CURRENT_USER } from "../graphql/queries";
import SignupForm from "./signup/GoogleSignupForm";
import { DARK_PRIMARY_LIGHT, DARK_PRIMARY, DARK_PRIMARY_DARK } from "../colors";
import Logo from "../components/icons/Logo";
import ErrorShowIntercom from "./shared/ErrorShowIntercom";
import { Spinner } from "evergreen-ui";

type Props = {} & RouteComponentProps;

function AuthPage(props: Props) {
  const { history } = props;
  const { loading: meLoading, error: meError, data: meData } = useQuery(
    CURRENT_USER
  );

  if (meLoading) {
    return <Spinner></Spinner>;
  }
  if (meError) {
    return <ErrorShowIntercom></ErrorShowIntercom>;
  }

  const {
    me: { user }
  } = meData;
  const loggedIn = !!user;
  if (loggedIn) {
    return <Redirect to="/"></Redirect>;
  }

  const handleLogin = () => {
    history.push("/");
  };

  return (
    <Container>
      <CardContainer>
        <Logo width="64px" height="64px"></Logo>
        <h1>Welcome to Omnition</h1>
        <div style={{ marginTop: 40 }}>
          <SignupForm onLogin={handleLogin}></SignupForm>
        </div>
      </CardContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 200px;
`;

const CardContainer = styled.div`
  padding: 80px 60px;
  background-color: ${DARK_PRIMARY_LIGHT};
  border: 1px solid ${DARK_PRIMARY_LIGHT};
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default AuthPage;

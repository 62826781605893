import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { RouteComponentProps, withRouter } from "react-router";
import SimpleBar from "simplebar-react";
import {
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY,
  DARK_SECONDARY_DARK,
  DARK_SECONDARY_LIGHT,
  DARK_TERTIARY,
  DARK_TERTIARY_LIGHT
} from "../../colors";
import {
  CurrentUserWithInsight_me_user,
  UpdateTag
} from "../../graphql/generated/types";
import { UPDATE_TAG } from "../../graphql/mutations";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import { generateRandomColor } from "../../utils";
import { getFrontendUrl } from "../../utils/urls";
import Contact from "../icons/Contact";
import ShareLink from "../icons/ShareLink";
import Tag from "../icons/Tag";
import InputWithShortcuts from "../shared/InputWithShortcuts";
import ContactItem from "./ContactItem";
import DetailedIntercomMessage from "./DetailedIntercomMessage";
import DetailedManualNote from "./DetailedManualNote";

type Props = {
  user: CurrentUserWithInsight_me_user;
} & ShortcutProps &
  RouteComponentProps;

function Insight(props: Props) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [tagTitle, setTagTitle] = useState<string>(
    props.user.organization && props.user.organization.tag
      ? props.user?.organization?.tag.name
      : ""
  );
  const [isShowingTags, setIsShowingTags] = useState<boolean>(true);

  const [updateTag, { loading: updateTagLoading }] = useMutation<UpdateTag>(
    UPDATE_TAG
  );

  const copyLink = () => {
    copy(
      `${getFrontendUrl()}/shared-insight?insightId=${
        props.user.organization?.tag!.id
      }&organizationId=${props.user.organization?.id}`
    );
    props.setNotification({
      notification: "Insights Link Copied!",
      timestamp: Date.now()
    });
  };

  useEffect(() => {
    props.manager.bind(
      "esc",
      () => props.history.push("/insights"),
      "Insight",
      "Go back to insights",
      3
    );
    props.manager.bind(
      "tab",
      (e: any) => {
        setIsShowingTags(!isShowingTags);
        if (e) {
          e.preventDefault();
        }
      },
      "Insight",
      "Toggle between showing tags and people",
      1
    );
    props.manager.bind(
      "mod+c",
      () => copyLink(),
      "Insight",
      "Copy a link to this insights page",
      1
    );

    return () => {
      props.manager.unbind("esc", "Insight");
      props.manager.unbind("tab", "Insight");
      props.manager.unbind("mod+c", "Insight");
    };
  });

  const organization = props.user.organization;
  if (!organization || !organization.tag) {
    return null;
  }

  const tag = organization.tag;

  const submitUpdateTag = async () => {
    await updateTag({
      variables: {
        tagId: tag.id,
        input: {
          name: tagTitle
        }
      }
    });
    setIsEditing(false);
  };

  const allNotes = tag.manualNotes
    .map((manualNote, index) => {
      return (
        <DetailedManualNote
          key={index}
          note={manualNote}
          tag={tag as any}
          // TODO: View a singular note
        />
      );
    })
    .concat(
      tag.highlights.map((highlight, index) => {
        return (
          <DetailedIntercomMessage
            key={index}
            highlight={highlight}
            tag={tag as any}
            // TODO: View a singular highlight
          />
        );
      })
    );

  const allPeople = tag.contacts.map((contact, index) => {
    return <ContactItem key={index} contact={contact} />;
  });

  return (
    <SimpleBar
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Container>
        <TagHeader>
          <Title>
            {isEditing ? (
              <InputWithShortcuts
                value={tagTitle}
                onChange={e => setTagTitle(e.target.value)}
                shortcuts={[
                  { keys: "mod+enter", callback: submitUpdateTag },
                  { keys: "enter", callback: submitUpdateTag }
                ]}
                onBlur={submitUpdateTag}
                autoFocus
              />
            ) : (
              <>
                <TagTitle color={generateRandomColor(organization.tag.id)}>
                  {tagTitle}
                </TagTitle>
                <MdEdit
                  style={{
                    fontSize: "20px",
                    marginLeft: "10px",
                    color: `${DARK_SECONDARY_DARK}`
                  }}
                  onClick={() => setIsEditing(true)}
                />
              </>
            )}
            <LinkSharing onClick={copyLink}>
              <ShareLink />
            </LinkSharing>
          </Title>
          <Counts>
            <TagCountContainer>
              <TagCount>{tag.noteCount}</TagCount>
              <Tag style={{ height: "16px", width: "16px" }} />
            </TagCountContainer>
            <TagContactContainer>
              <TagContactCount>{tag.contactCount}</TagContactCount>
              <Contact style={{ height: "16px", width: "16px" }} />
            </TagContactContainer>
          </Counts>
        </TagHeader>
        <Toggler>
          <TogglerTitles>
            <Switch
              active={isShowingTags}
              onClick={() => setIsShowingTags(true)}
            >
              TAGS
            </Switch>
            <Switch
              active={!isShowingTags}
              onClick={() => setIsShowingTags(false)}
            >
              PEOPLE
            </Switch>
          </TogglerTitles>
          <BarHolder>
            <Bar isTag={isShowingTags} />
          </BarHolder>
        </Toggler>
        <Notes>{isShowingTags ? allNotes : allPeople}</Notes>
      </Container>
    </SimpleBar>
  );
}

export default withRouter(withShortcuts(Insight));

const LinkSharing = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: auto;
`;

const TogglerTitles = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Switch = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? DARK_TERTIARY_LIGHT : DARK_SECONDARY)};
  transition: 0.16s ease-in-out;
`;

const Bar = styled.div<{ isTag: boolean }>`
  height: 100%;
  width: 300px;
  margin-left: ${props => (props.isTag ? "0px" : "300px")};
  transition: margin 0.16s ease-in-out;
  background-color: ${DARK_TERTIARY};
`;

const BarHolder = styled.div`
  display: flex;
  width: 100%;
  background-color: ${DARK_PRIMARY_LIGHT};
  height: 2px;
`;

const Toggler = styled.div`
  margin-bottom: 40px;
`;

const Counts = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const TagCountContainer = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const TagContactContainer = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const TagCount = styled.div`
  color: ${DARK_SECONDARY_DARK};
  margin-right: 10px;
`;

const TagContactCount = styled.div`
  color: ${DARK_SECONDARY_DARK};
  margin-right: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const TagHeader = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const TagTitle = styled.div<{ color: string }>`
  border-radius: 15px;
  font-size: 20px;
  background-color: ${props => props.color};
  padding: 6px 12px;
  color: ${DARK_SECONDARY_LIGHT};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
`;

const Notes = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-top: 20px;
`;

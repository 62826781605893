import styled from "@emotion/styled";
import React, { useState } from "react";
import { DARK_SECONDARY, DARK_SECONDARY_DARK } from "../../colors";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";

type Props = {
  style?: any;
} & ShortcutProps;

function ShareLink(props: Props) {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props.style}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Path
        active={isHovering}
        d="M8.33008 10.8334C8.68796 11.3118 9.14454 11.7077 9.66887 11.9942C10.1932 12.2807 10.773 12.451 11.369 12.4937C11.9649 12.5364 12.5631 12.4504 13.1229 12.2416C13.6827 12.0327 14.191 11.706 14.6134 11.2834L17.1134 8.78341C17.8724 7.99756 18.2924 6.94505 18.2829 5.85257C18.2734 4.76008 17.8352 3.71503 17.0627 2.9425C16.2901 2.16996 15.2451 1.73176 14.1526 1.72226C13.0601 1.71277 12.0076 2.13275 11.2217 2.89174L9.78841 4.31674"
      />
      <Path
        active={isHovering}
        d="M11.662 9.16665C11.3041 8.68821 10.8475 8.29233 10.3232 8.00587C9.79883 7.7194 9.21903 7.54905 8.62308 7.50637C8.02712 7.46369 7.42896 7.54968 6.86916 7.7585C6.30936 7.96732 5.80102 8.29409 5.37862 8.71665L2.87862 11.2167C2.11963 12.0025 1.69965 13.055 1.70914 14.1475C1.71864 15.24 2.15684 16.285 2.92937 17.0576C3.70191 17.8301 4.74696 18.2683 5.83945 18.2778C6.93193 18.2873 7.98444 17.8673 8.77028 17.1083L10.1953 15.6833"
      />
    </Svg>
  );
}

export default withShortcuts(ShareLink);

const Svg = styled.svg`
  fill: none;
`;

const Path = styled.path<{ active: boolean }>`
  stroke: ${props => (props.active ? DARK_SECONDARY : DARK_SECONDARY_DARK)};
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke 0.16s ease-in-out;
`;

import styled from "@emotion/styled";
import React, { useState } from "react";
import { Editor } from "slate";
import { CurrentUserWithContact_me_user_organization } from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import { generateRandomColorNoOpacity } from "../../utils";

type Props = {
  noteId: string;
  tagIds: string[];
  organization: CurrentUserWithContact_me_user_organization;
  children: any;
  text: string;
  saveContent: () => Promise<any>;
  editor: Editor;
  onClickHighlight: (highlight: any) => void;
} & ShortcutProps;

function Highlight(props: Props) {
  const { onClickHighlight } = props;

  return (
    <>
      <HighlightedDiv
        color={generateRandomColorNoOpacity(
          props.tagIds.length > 0 ? props.tagIds[0] : "asdf"
        )}
        onClick={e => {
          if (onClickHighlight) {
            onClickHighlight({
              tagIds: props.tagIds,
              manualNoteId: props.noteId,
              content: props.text,
              draft: false
            });
          }
        }}
      >
        {props.children}
      </HighlightedDiv>
    </>
  );
}

export default withShortcuts(Highlight);

const HighlightedDiv = styled.span`
  position: relative;
  display: inline;
  background-color: rgb(${props => props.color}, 0.5);
  cursor: pointer;
`;

import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import * as React from "react";
import {
  Mutation,
  MutationFunction,
  MutationResult,
  Query,
  QueryResult
} from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  CurrentUserWithContacts,
  CurrentUser_me_user,
  CurrentUser_me_user_organization_contacts
} from "../../graphql/generated/types";
import { DELETE_CONTACTS } from "../../graphql/mutations";
import {
  CURRENT_USER,
  CURRENT_USER_WITH_CONTACTS
} from "../../graphql/queries";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ContactsFilter, ShortcutProps } from "../../types";
import ErrorShowIntercom from "../shared/ErrorShowIntercom";
import Table from "../shared/Table";
import ContactRow from "./ContactRow";
import ContactsHeader from "./ContactsHeader";

type Props = {
  user: CurrentUser_me_user;
} & RouteComponentProps &
  ShortcutProps;

type State = {
  search: string;
};

class Contacts extends React.Component<Props, State> {
  state: State = {
    search: ""
  };

  setFilter = (filter: ContactsFilter) => {
    this.setState({
      search: filter.search
    });
  };

  redirectToContact = (id: string) => {
    this.props.history.push(`/person?id=${id}`);
  };

  getContactListing = (
    index: number,
    element: CurrentUser_me_user_organization_contacts,
    checked: boolean,
    focused: boolean,
    focusCurrentElement: () => any,
    toggleCheckbox: () => any
  ) => {
    return (
      <ContactRow
        key={index}
        contact={element}
        checked={checked}
        focused={focused}
        focusCurrentElement={focusCurrentElement}
        toggleCheckbox={toggleCheckbox}
      />
    );
  };

  render() {
    // Placeholder until actual adding filters is needed
    return (
      <Container>
        <ContactsHeader
          user={this.props.user}
          filter={{ search: this.state.search }}
          setFilter={this.setFilter}
        />
        <Query
          query={CURRENT_USER_WITH_CONTACTS}
          variables={{ contactsFilter: { searchQuery: this.state.search } }}
        >
          {({ loading, error, data }: QueryResult<CurrentUserWithContacts>) => {
            if (loading) {
              return <Spinner />;
            }
            if (error || !data || !data.me || !data.me.user) {
              return <ErrorShowIntercom />;
            }

            const user = data.me.user;

            return (
              <Mutation
                mutation={DELETE_CONTACTS}
                onCompleted={() => {
                  this.props.setNotification({
                    notification: `Deleted contacts!`,
                    timestamp: Date.now()
                  });
                }}
                refetchQueries={[
                  {
                    query: CURRENT_USER
                  }
                ]}
              >
                {(
                  deleteContacts: MutationFunction,
                  { data, loading }: MutationResult
                ) => {
                  return (
                    <Table
                      onNextPage={() => {}}
                      onPreviousPage={() => {}}
                      currentPage={0}
                      disablePrevious={false}
                      totalCount={
                        user.organization?.contacts.length
                          ? user.organization.contacts.length
                          : 0
                      }
                      elementName="People"
                      disableNext={false}
                      tableArray={
                        user.organization ? user.organization.contacts : []
                      }
                      tableListing={this.getContactListing}
                      onClickTableListing={this.redirectToContact}
                      onDeleteElements={(elementIds: string[]) => {
                        deleteContacts({
                          variables: {
                            input: {
                              contactIds: elementIds
                            }
                          }
                        });
                      }}
                    />
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default withRouter(withShortcuts(Contacts));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

import React from "react";

enum InboxFilterOptions {
  NEW = "NEW",
  ALL = "ALL",
  ARCHIVED = "ARCHIVED"
}

type InboxFilter = {
  selection: InboxFilterOptions;
  searchQuery: string;
};

export type InboxFilterContext = {
  filter: InboxFilter;
  setFilter: (filter: InboxFilter) => void;
};

export const InboxFilterContext = React.createContext<InboxFilterContext>({
  filter: {
    selection: InboxFilterOptions.NEW,
    searchQuery: ""
  },
  setFilter: (filter: InboxFilter) => {}
});

function InboxFilterProvider(props: { children: React.ReactChild }) {
  const [filter, setFilter] = React.useState({
    selection: InboxFilterOptions.NEW,
    searchQuery: ""
  });
  return (
    <InboxFilterContext.Provider
      value={{
        filter,
        setFilter
      }}
    >
      {props.children}
    </InboxFilterContext.Provider>
  );
}

export const useInboxFilterContext = () => React.useContext(InboxFilterContext);

export default InboxFilterProvider;

import React, { useState, useEffect } from "react";

export default (value: any, timeout: number) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value]);

  return state;
};

import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import SimpleBar from "simplebar-react";
import {
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY,
  DARK_SECONDARY_DARK,
  DARK_SECONDARY_LIGHT,
  DARK_TERTIARY,
  DARK_TERTIARY_LIGHT
} from "../../colors";
import { SharedOrganizationWithInsight_organization } from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import { generateRandomColor } from "../../utils";
import { getFrontendUrl } from "../../utils/urls";
import Contact from "../icons/Contact";
import Tag from "../icons/Tag";
import ErrorShowIntercom from "../shared/ErrorShowIntercom";
import ContactItem from "./ContactItem";
import DetailedIntercomMessage from "./DetailedIntercomMessage";
import DetailedManualNote from "./DetailedManualNote";

type Props = {
  organization: SharedOrganizationWithInsight_organization;
} & ShortcutProps &
  RouteComponentProps;

function SharedInsight(props: Props) {
  const [isShowingTags, setIsShowingTags] = useState<boolean>(true);

  useEffect(() => {
    props.manager.bind(
      "tab",
      (e: any) => {
        setIsShowingTags(!isShowingTags);
        if (e) {
          e.preventDefault();
        }
      },
      "Insight",
      "Toggle between showing tags and people",
      1
    );

    return () => {
      props.manager.unbind("tab", "Insight");
    };
  });

  const tag = props.organization.tag;
  if (!tag) {
    return <ErrorShowIntercom />;
  }
  const allNotes = tag.manualNotes
    .map((manualNote, index) => {
      return (
        <DetailedManualNote
          key={index}
          note={manualNote}
          tag={tag as any}
          public={true}
          // TODO: View a singular note
        />
      );
    })
    .concat(
      tag.highlights.map((highlight, index) => {
        return (
          <DetailedIntercomMessage
            key={index}
            highlight={highlight}
            tag={tag as any}
            // TODO: View a singular highlight
          />
        );
      })
    );

  const allPeople = tag.contacts.map((contact, index) => {
    return <ContactItem key={index} contact={contact} />;
  });

  return (
    <SimpleBar
      style={{
        display: "flex",
        height: "100%",
        width: "600px",
        flexDirection: "column"
      }}
    >
      <Container>
        <InvitationHeader>
          <Disclosure>
            This insight is a part of the {props.organization.name} account.
          </Disclosure>
          <Disclosure>
            <JoinLink onClick={() => window.open(getFrontendUrl())}>
              Join {props.organization.name}
            </JoinLink>{" "}
            to see more.
          </Disclosure>
        </InvitationHeader>
        <TagHeader>
          <Title>
            <TagTitle color={generateRandomColor(tag.id)}>{tag.name}</TagTitle>
          </Title>
          <Counts>
            <TagCountContainer>
              <TagCount>{tag.noteCount}</TagCount>
              <Tag style={{ height: "16px", width: "16px" }} />
            </TagCountContainer>
            <TagContactContainer>
              <TagContactCount>{tag.contactCount}</TagContactCount>
              <Contact style={{ height: "16px", width: "16px" }} />
            </TagContactContainer>
          </Counts>
        </TagHeader>
        <Toggler>
          <TogglerTitles>
            <Switch
              active={isShowingTags}
              onClick={() => setIsShowingTags(true)}
            >
              TAGS
            </Switch>
            <Switch
              active={!isShowingTags}
              onClick={() => setIsShowingTags(false)}
            >
              PEOPLE
            </Switch>
          </TogglerTitles>
          <BarHolder>
            <Bar isTag={isShowingTags} />
          </BarHolder>
        </Toggler>
        <Notes>{isShowingTags ? allNotes : allPeople}</Notes>
      </Container>
    </SimpleBar>
  );
}

export default withShortcuts(withRouter(SharedInsight));

const Disclosure = styled.div`
  color: ${DARK_SECONDARY};
`;

const JoinLink = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${DARK_TERTIARY};
  :hover {
    color: ${DARK_TERTIARY_LIGHT};
  }
  transition: color 0.16s ease-in-out;
  font-weight: bold;
`;

const InvitationHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 60px;
`;

const TogglerTitles = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Switch = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? DARK_TERTIARY_LIGHT : DARK_SECONDARY)};
  transition: 0.16s ease-in-out;
`;

const Bar = styled.div<{ isTag: boolean }>`
  height: 100%;
  width: 300px;
  margin-left: ${props => (props.isTag ? "0px" : "300px")};
  transition: margin 0.16s ease-in-out;
  background-color: ${DARK_TERTIARY};
`;

const BarHolder = styled.div`
  display: flex;
  width: 100%;
  background-color: ${DARK_PRIMARY_LIGHT};
  height: 2px;
`;

const Toggler = styled.div`
  margin-bottom: 40px;
`;

const Counts = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const TagCountContainer = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const TagContactContainer = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const TagCount = styled.div`
  color: ${DARK_SECONDARY_DARK};
  margin-right: 10px;
`;

const TagContactCount = styled.div`
  color: ${DARK_SECONDARY_DARK};
  margin-right: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const TagHeader = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const TagTitle = styled.div<{ color: string }>`
  border-radius: 15px;
  font-size: 20px;
  background-color: ${props => props.color};
  padding: 6px 12px;
  color: ${DARK_SECONDARY_LIGHT};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Notes = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-top: 20px;
`;

import styled from "@emotion/styled";
import * as React from "react";
import { FiSearch } from "react-icons/fi";
import {
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY_DARK,
  TERTIARY,
  TERTIARY_DARK
} from "../../colors";
import InputWithShortcuts from "../../components/shared/InputWithShortcuts";
import { CurrentUser_me_user } from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ContactsFilter, ShortcutProps } from "../../types";
import Plus from "../icons/Plus";
import AddContactModal from "./AddContactModal";

type Props = {
  user: CurrentUser_me_user;
  filter: ContactsFilter;
  setFilter: (filter: ContactsFilter) => any;
} & ShortcutProps;

type State = {
  showNewContactModal: boolean;
};

class ContactsHeader extends React.Component<Props, State> {
  state: State = {
    showNewContactModal: false
  };

  UNSAFE_componentWillMount() {
    this.props.manager.bind(
      "n",
      (event: any) => {
        this.showNewContactModal();
        event.preventDefault();
      },
      this.constructor.name,
      "Create Contact",
      1
    );
  }

  componentWillUnmount() {
    this.props.manager.unbind("n", this.constructor.name);
  }

  showNewContactModal = () => {
    this.setState({
      showNewContactModal: true
    });
  };

  hideNewContactModal = () => {
    this.setState({
      showNewContactModal: false
    });
  };

  render() {
    return (
      <>
        <Header>
          <SearchIcon>
            <FiSearch />
          </SearchIcon>
          <StyledInput
            shortcuts={[]}
            onChange={e => this.props.setFilter({ search: e.target.value })}
            value={this.props.filter.search}
            placeholder="Search for a contact..."
          />
          <NewContactButton onClick={this.showNewContactModal}>
            <Plus />
          </NewContactButton>
        </Header>
        {this.state.showNewContactModal && (
          <AddContactModal
            onHideModal={this.hideNewContactModal}
            user={this.props.user}
          />
        )}
      </>
    );
  }
}

export default withShortcuts(ContactsHeader);

const SearchHeader = styled.div`
  height: 59px;
  border-bottom: 1px solid ${DARK_PRIMARY_LIGHT};
  display: flex;
  align-items: center;
`;

const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${DARK_SECONDARY_DARK};
  margin-left: 30px;
  margin-right: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledInput = styled(InputWithShortcuts)`
  font-size: 16px;
  border: none;
  :focus {
    border: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid ${DARK_PRIMARY_LIGHT};
`;

const Count = styled.div<{ active: boolean }>`
  color: ${props => (props.active ? "rgb(127, 128, 130)" : "rgb(97, 98, 100)")};
  margin-left: 10px;
  font-size: 16px;
`;

const NewContactButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: auto;
  width: 30px;
  height: 30px;
  background-color: ${TERTIARY};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${TERTIARY_DARK};
  }
  transition: background-color 0.16s ease-in-out;
`;

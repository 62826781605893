import React from "react";
import { CurrentUserWithSettings_me_user_organization } from "../../graphql/generated/types";
import { IntegrationTypes } from "../../types";
import IntercomIntegration from "./IntercomIntegration";

type Props = {
  organization: CurrentUserWithSettings_me_user_organization;
};

function Integrations(props: Props) {
  const { integrations } = props.organization;
  const intercomIntegration = integrations.find(
    integration => integration.type === IntegrationTypes.INTERCOM
  );

  return (
    <>
      <IntercomIntegration activated={!!intercomIntegration} />
      {/* <GmailIntegration organization={props.organization} /> */}
    </>
  );
}

export default Integrations;

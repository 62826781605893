import styled from "@emotion/styled";
import * as React from "react";

type Props = {
  style?: any;
};

function Contact(props: Props) {
  return (
    <Svg
      width={props.style ? props.style.width : "12"}
      height={props.style ? props.style.height : "12"}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00004 6.33333C6.74897 6.33333 8.16671 4.91557 8.16671 3.16667C8.16671 1.41777 6.74897 0 5.00004 0C3.25114 0 1.83337 1.41777 1.83337 3.16667C1.83337 4.91557 3.25114 6.33333 5.00004 6.33333Z"
        fill="#838587"
      />
      <path
        d="M10 11.6667C10 10.3406 9.4732 9.0688 8.53553 8.13114C7.59787 7.19347 6.32608 6.66667 5 6.66667C3.67392 6.66667 2.40215 7.19347 1.46447 8.13114C0.526784 9.0688 0 10.3406 0 11.6667C0 11.7551 0.0351189 11.8399 0.0976313 11.9024C0.160143 11.9649 0.244928 12 0.333333 12H9.66667C9.75507 12 9.83987 11.9649 9.9024 11.9024C9.96487 11.8399 10 11.7551 10 11.6667Z"
        fill="#838587"
      />
    </Svg>
  );
}

export default Contact;

const Svg = styled.svg``;

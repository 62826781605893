import { useApolloClient } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import isHotkey from "is-hotkey";
import React, { useEffect, useMemo, useState } from "react";
import {
  createEditor,
  Editor,
  Node,
  Point,
  Range,
  Text,
  Transforms
} from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import { CurrentUserWithContact_me_user_organization } from "../../graphql/generated/types";
import {
  CREATE_MANUAL_NOTE,
  UPDATE_MANUAL_NOTE
} from "../../graphql/mutations";
import useDebouncedValue from "../../hooks/useDebouncedValue";
import withShortcuts from "../../shortcuts/withShortcuts";
import { HOTKEYS, NoteTypes, ShortcutProps } from "../../types";
import HighlightModal from "../shared/HighlightModal";
import Highlight from "./EditorHighlight";
import EditorToolbar, {
  deserialize,
  toggleBlock,
  toggleMark
} from "./EditorToolbar";
import { runTransformOnAllChildren } from "./utils/editorUtils";
import gql from "graphql-tag";

const CLEAR_EDITING_USER = gql`
  mutation clearEditingUserForNoteId($noteId: ID!) {
    clearEditingUserForNoteId(noteId: $noteId) {
      id
      editingUserId
    }
  }
`;

const HOTKEY_MAP = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+h": "tag",
  "mod+alt+1": "heading-one",
  "mod+alt+2": "heading-two",
  "mod+alt+3": "heading-three"
};

const MARKDOWN = {
  "*": "list-item",
  "-": "list-item",
  "+": "list-item",
  "#": "heading-one",
  "##": "heading-two",
  "###": "heading-three"
};

type Shortcut = { keys: string; callback: () => any };

const prefilledText = [{ type: "paragraph", children: [{ text: "" }] }];

type Props = {
  currentNoteId?: string | null;
  organization: CurrentUserWithContact_me_user_organization;
  content: Node[];
  shortcuts?: Shortcut[];
  showToolbar?: boolean;
} & ShortcutProps;

function OmniscientEditor(props: Props) {
  const currentNoteId = props.currentNoteId;
  const [content, setContent] = useState<Node[]>(
    props.content || prefilledText
  );
  const [currentHighlight, setCurrentHighlight] = React.useState();
  const [showTextSelectionModal, setShowTextSelectionModal] = useState<boolean>(
    false
  );
  const debouncedContent = useDebouncedValue(content, 300);

  // NEEDS TO BE A REF, trying to keep track of it as state messes up the editor re-renders
  const isMouseDown = React.useRef(false);
  const highlightModalDiv = React.useRef<HTMLDivElement>(null);
  const client = useApolloClient();

  const saveContent = () => {
    if (currentNoteId) {
      client
        .mutate({
          mutation: UPDATE_MANUAL_NOTE,
          variables: {
            noteId: currentNoteId,
            input: {
              content: JSON.stringify(editor.children),
              tagIds: []
            }
          }
        })
        .then(() => {
          client.mutate({
            mutation: CLEAR_EDITING_USER,
            variables: {
              noteId: currentNoteId
            }
          });
        });
    } else {
      // NEVER GETS CALLED
      client.mutate({
        mutation: CREATE_MANUAL_NOTE,
        variables: {
          content: JSON.stringify(editor.children),
          tagIds: []
        }
      });
    }
    console.log(currentNoteId);
  };

  useEffect(() => {
    // Need to save here so that we avoid a re-render, messing up the experience
    console.log("TRYING TO SAVE");
    saveContent();

    return () => {
      // Before component un-omounts, save one more time
      console.log("SAVE BEFORE CLOSE");
      saveContent();
    };
  }, [debouncedContent]);

  const editor = useMemo(
    () => withMarkdown(withHistory(withReact(createEditor()))),
    []
  );
  const editorOnChange = React.useRef(editor.onChange);

  // Need to have this ref to remember where the editor selection was before the modal opened
  const editorSelection = React.useRef(editor.selection);
  useEffect(() => {
    if (showTextSelectionModal) {
      editorSelection.current = editor.selection;
    } else {
    }
  }, [showTextSelectionModal]);

  React.useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      if (e.key === "Escape") {
        if (showTextSelectionModal) {
          removeDraftHighlight();
          // Need to reset the cursor position, but remove the highlight
          if (editorSelection.current) {
          } else {
            // no idea what to do lol
          }
        }
      }
    }
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    const handleDocumentMouseDown = (event: any) => {
      if (
        setShowTextSelectionModal &&
        highlightModalDiv.current &&
        !highlightModalDiv.current.contains(event.target)
      ) {
        runTransformOnAllChildren(editor, editor => {
          Editor.removeMark(editor, "draftTag");
        });
        setShowTextSelectionModal(false);
      }
      isMouseDown.current = true;
    };
    document.addEventListener("mousedown", handleDocumentMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown);
    };
  }, []);
  useEffect(() => {
    const handleMouseUp = (event: any) => {
      isMouseDown.current = false;
      if (!editor.selection) {
        return;
      }
      const selectionObj = window.getSelection();
      if (
        selectionObj &&
        !selectionObj.isCollapsed &&
        !Range.isCollapsed(editor.selection!)
      ) {
        addDraftHighlight();
      }
    };

    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    // This tries to detect when user is highlighting
    editor.onChange = () => {
      editorOnChange.current();
      const selectionObj = window.getSelection();
      if (isMouseDown.current) {
        return;
      }
      if (
        selectionObj &&
        !selectionObj.isCollapsed &&
        !Range.isCollapsed(editor.selection!)
      ) {
        // my main jsut tried to highliht something
        console.log("Editor on change");
        addDraftHighlight();
      }
    };
  }, []);

  const addDraftHighlight = () => {
    console.log("ADD DRAFT HIGHLIGHT");
    const editorSelection = editor.selection;
    const selectionObj = window.getSelection();
    if (editorSelection && selectionObj) {
      // In sync
      // SET CURRENT HIGHLIGHT THIS HAS TO GO FIRST
      setCurrentHighlight({
        content: Editor.string(editor, editorSelection),
        manualNoteId: props.currentNoteId,
        draft: true
      });
      // This the new, stateful highlight, not persisted
      Transforms.select(editor, editorSelection);
      Editor.addMark(editor, "draftTag", true);
      setShowTextSelectionModal(true);
    }
  };

  const removeDraftHighlight = () => {
    console.log("REMOVE DRAFT HIGHLIGHTS");
    // need to remove all the draft highlights
    runTransformOnAllChildren(editor, editor => {
      Editor.removeMark(editor, "draftTag");
    });
    setShowTextSelectionModal(false);
  };

  const handleClickHighlight = (highlight: any) => {
    console.log("CLICK HIGHLIGHT");
    setCurrentHighlight(highlight);
    setShowTextSelectionModal(true);
  };

  const handleAddTag = (tagId: string) => {
    const selection = editor.selection || editorSelection.current;
    if (!selection) {
      // editor selection is too slow, use HACK to get the right highlight
      if (currentHighlight.draft) {
        runTransformOnAllChildren(editor, editor => {
          Transforms.setNodes(
            editor,
            {
              tagIds: [tagId],
              draft: false
            },
            {
              match: node => {
                return (
                  Text.isText(node) && node.text === currentHighlight.content
                );
              }
            }
          );
        });
        setCurrentHighlight((prev: any) => ({
          ...prev,
          tagIds: [tagId]
        }));
      } else {
        const newTags = [...currentHighlight.tagIds, tagId];
        runTransformOnAllChildren(editor, editor => {
          Transforms.setNodes(
            editor,
            {
              tagIds: newTags
            },
            {
              match: node => {
                return (
                  Text.isText(node) && node.text === currentHighlight.content
                );
              }
            }
          );
        });
        setCurrentHighlight((prev: any) => ({
          ...prev,
          tagIds: newTags
        }));
      }
      return;
    }

    // There is a proper selection, update shit properly
    if (currentHighlight.draft) {
      Transforms.select(editor, selection);
      Editor.removeMark(editor, "draftTag");
      Editor.addMark(editor, "tagIds", [tagId]);
      setCurrentHighlight((prev: any) => ({
        ...prev,
        draft: false,
        tagIds: [tagId]
      }));
    } else {
      if (currentHighlight.tagIds.find((id: string) => id === tagId)) {
        // Ignore if the tag is already part of it... should be fixed later
        return;
      }
      const newTags = [...currentHighlight.tagIds, tagId];
      Transforms.select(editor, editorSelection.current!);
      Transforms.setNodes(
        editor,
        {
          tagIds: newTags
        },
        {
          match: node => {
            return Text.isText(node) && node.text === currentHighlight.content;
          }
        }
      );
      setCurrentHighlight((prev: any) => ({
        ...prev,
        tagIds: newTags
      }));
    }
  };

  const handleRemoveTag = (tagId: string) => {
    console.log("REMOVE TAG");
    const selection = editor.selection || editorSelection.current;
    if (!selection) {
      // editor selection is too slow, use HACK to get the right highlight
      const newTags = currentHighlight.tagIds.filter(
        (id: string) => id !== tagId
      );
      runTransformOnAllChildren(editor, editor => {
        Transforms.setNodes(
          editor,
          {
            tagIds: newTags
          },
          {
            match: node => {
              return (
                Text.isText(node) && node.text === currentHighlight.content
              );
            }
          }
        );
      });
      setCurrentHighlight((prev: any) => ({
        ...prev,
        tagIds: newTags
      }));
      return;
    }

    // There is a proper selection, update shit properly
    const newTags = currentHighlight.tagIds.filter(
      (id: string) => id !== tagId
    );
    setCurrentHighlight((prev: any) => ({
      ...prev,
      tagIds: newTags
    }));
    Transforms.select(editor, editorSelection.current!);
    Transforms.setNodes(
      editor,
      {
        tagIds: newTags
      },
      {
        match: node =>
          Text.isText(node) && node.text === currentHighlight.content
      }
    );
  };

  return (
    <Slate
      editor={editor}
      value={content}
      onChange={value => {
        setContent(value);
      }}
    >
      {props.showToolbar && <EditorToolbar />}
      <Editable
        placeholder="Enter notes or feedback"
        renderElement={props => {
          return <Element {...props} />;
        }}
        renderLeaf={(props: any) => {
          return (
            <Leaf
              {...({
                ...props,
                organization: props.organization,
                editor: editor,
                noteId: currentNoteId as string,
                saveContent: saveContent,
                onClickHighlight: handleClickHighlight
              } as any)}
            />
          );
        }}
        onKeyDown={e => {
          if (props.shortcuts) {
            for (const index in props.shortcuts) {
              const hotkey = props.shortcuts[index].keys;
              if (isHotkey(hotkey, e as any)) {
                handleHotkey(hotkey, props.shortcuts, editor, e);
                return;
              }
            }
          }
          for (const hotkey in HOTKEYS) {
            const keyboardHotkey = (HOTKEYS as any)[hotkey];
            if (isHotkey(keyboardHotkey, e as any)) {
              handleHotkey(keyboardHotkey, props.shortcuts, editor, e);
              return;
            }
          }
        }}
      />
      {showTextSelectionModal && currentHighlight && (
        <div
          ref={highlightModalDiv}
          style={{
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, .4)",
            top: "0px",
            left: "0px",
            width: "432px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "60px"
          }}
        >
          <HighlightModal
            highlight={currentHighlight} // useless since manuaul notes don't use this
            targetId={currentNoteId!}
            targetType={NoteTypes.MANUAL}
            onClose={() => {
              removeDraftHighlight();
              setShowTextSelectionModal(false);
            }}
            onCreateNewHighlight={(highlight: any) => {}}
            onAddTag={handleAddTag}
            onRemoveTag={handleRemoveTag}
          />
        </div>
      )}
    </Slate>
  );
}

export default withShortcuts(OmniscientEditor);

// Markdown handling
const withMarkdown = (editor: ReactEditor) => {
  const { deleteBackward, insertText, insertData } = editor;

  editor.insertText = text => {
    const { selection } = editor;

    if (text === " " && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range);
      const type = (MARKDOWN as any)[beforeText];
      if (type) {
        Transforms.select(editor, range);
        Transforms.delete(editor);
        Transforms.setNodes(
          editor,
          {
            type: type
          },
          { match: n => Editor.isBlock(editor, n) }
        );
        if (type === "list-item") {
          const list = { type: "bulleted-list", children: [] };
          Transforms.wrapNodes(editor, list, {
            match: n => n.type === "list-item"
          });
        }

        return;
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          block.type === "list-item" &&
          path.length <= 2 &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, {
            type: "paragraph"
          });
          Transforms.liftNodes(editor, {
            match: n => n.type === "paragraph"
          });

          return;
        } else if (
          block.type !== "paragraph" &&
          Point.equals(selection.anchor, start)
        ) {
          Transforms.setNodes(editor, { type: "paragraph" });

          if (block.type === "list-item") {
            Transforms.unwrapNodes(editor, {
              match: n => n.type === "bulleted-list"
            });
          }

          return;
        }
      }

      deleteBackward(...args);
    }
  };

  editor.insertData = data => {
    const html = data.getData("text/html");
    const plaintext = data.getData("text/plain");

    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

// Hotkey Handling
const handleHotkey = (
  keypress: string,
  shortcuts: Shortcut[] | undefined,
  editor: Editor,
  event: any // some keyboard event`
) => {
  // Predefined shortcuts
  if (shortcuts) {
    for (const index in shortcuts) {
      const hotkey = shortcuts[index].keys;
      const callback = shortcuts[index].callback;
      if (hotkey === keypress) {
        callback();
        event.preventDefault();
        return;
      }
    }
  }

  // Editor's built in shortcuts
  switch (keypress) {
    case HOTKEYS.ENTER:
      handleEnter(editor, event);
      return;
    case HOTKEYS.TAB:
      handleTab(editor);
      event.preventDefault();
      return;
    case HOTKEYS.SHIFT_TAB:
      handleShiftTab(editor);
      event.preventDefault();
      return;
    case HOTKEYS.CMD_B:
    case HOTKEYS.CMD_I:
    case HOTKEYS.CMD_U:
      toggleMark(HOTKEY_MAP[keypress], editor);
      event.preventDefault();
      return;
    case HOTKEYS.CMD_ALT_1:
    case HOTKEYS.CMD_ALT_2:
    case HOTKEYS.CMD_ALT_3:
      toggleBlock(HOTKEY_MAP[keypress], editor);
      event.preventDefault();
      return;
  }
};

const handleEnter = (editor: Editor, event: any) => {
  const { selection } = editor;

  const match = Editor.above(editor, {
    match: n => Editor.isBlock(editor, n)
  });

  if (match && selection && Range.isCollapsed(selection)) {
    const [block, path] = match;
    const start = Editor.start(editor, path);

    if (
      block.type === "list-item" &&
      path.length <= 2 &&
      Point.equals(selection.anchor, start)
    ) {
      event.preventDefault();
      Transforms.setNodes(editor, {
        type: "paragraph"
      });
      Transforms.liftNodes(editor, {
        match: n => n.type === "paragraph"
      });
    } else if (
      block.type === "list-item" &&
      Point.equals(selection.anchor, start)
    ) {
      event.preventDefault();
      Transforms.liftNodes(editor, {
        match: n => n.type === "list-item"
      });
    }
  }
};

const handleTab = (editor: Editor) => {
  const match = Editor.above(editor, {
    match: n => Editor.isBlock(editor, n)
  });

  if (match) {
    const [block, path] = match;
    if (block.type === "list-item") {
      const list = { type: "bulleted-list", children: block.children };
      Transforms.wrapNodes(editor, list, {
        match: n => n.type === "list-item"
      });
    }
  }
};

const handleShiftTab = (editor: Editor) => {
  const match = Editor.above(editor, {
    match: n => Editor.isBlock(editor, n)
  });
  if (match) {
    const [block, path] = match;

    if (block.type === "list-item") {
      if (path.length <= 2) {
        Transforms.setNodes(editor, {
          type: "paragraph"
        });
        Transforms.liftNodes(editor, {
          match: n => n.type === "paragraph"
        });
      } else {
        Transforms.liftNodes(editor, {
          match: n => n.type === "list-item"
        });
      }
    }
  }
};

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <Ul {...attributes}>{children}</Ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    default:
      return <P {...attributes}>{children}</P>;
  }
};

const Ul = styled.ul`
  margin-block-start: 0px;
  margin-block-end: 0px;
  list-style-type: disc;
`;

const P = styled.p`
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`;

const Leaf = (props: any) => {
  const {
    attributes,
    leaf,
    organization,
    editor,
    noteId,
    saveContent,
    onClickHighlight
  } = props;
  let { children } = props;
  if (leaf.draftTag) {
    children = <DraftHighlight>{children}</DraftHighlight>;
  }

  if (leaf.tagIds && leaf.tagIds.length) {
    children = (
      <Highlight
        text={leaf.text}
        tagIds={leaf.tagIds}
        organization={organization}
        editor={editor}
        noteId={noteId}
        saveContent={saveContent}
        onClickHighlight={onClickHighlight}
      >
        {children}
      </Highlight>
    );
  }

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const DraftHighlight = styled.span`
  background-color: gray;
`;

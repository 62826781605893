import { Node } from "slate";

export function isProd() {
  return process.env.NODE_ENV === "production";
}

export function validateEmail(email: string): boolean {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function hashCode(s: string) {
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

const RANDOM_COLORS: string[] = [
  "#FF9191",
  "#FFB890",
  "#FFD990",
  "#50BF55",
  "#90D7FF",
  "#90A2FF",
  "#AD90FF",
  "#F690FF",
  "#5CA65A",
  "#5A9DA6",
  "#605AA6",
  "#A6765A",
  "#925AA6",
  "#A6915A",
  "#192F68",
  "#456819"
];

const RANDOM_COLORS_NO_OPACITY: string[] = [
  "255, 145, 145",
  "255, 184, 144",
  "255, 217, 144",
  "80, 191, 85",
  "144, 215, 255",
  "144, 162, 255",
  "173, 144, 255",
  "246, 144, 255",
  "92, 166, 90",
  "90, 157, 166",
  "96, 90, 166",
  "166, 118, 90",
  "146, 90, 166",
  "166, 145, 90",
  "25, 47, 104",
  "69, 104, 25"
];

export function generateRandomColor(tagName: string) {
  const index = Math.abs(hashCode(tagName)) % 16;
  return (RANDOM_COLORS as any)[index];
}

export function generateRandomColorNoOpacity(tagName: string) {
  const index = Math.abs(hashCode(tagName)) % 16;
  return (RANDOM_COLORS_NO_OPACITY as any)[index];
}

export function getTaggedContentFromNote(content: Node[], tagId: string) {
  const contentList: string[] = [];
  content.map(piece => {
    getTaggedContentFromLeaf(piece, tagId, contentList);
  });
  return contentList;
}

export function getTaggedContentFromLeaf(
  obj: any,
  tagId: string,
  contentList: string[]
) {
  Object.keys(obj).forEach(key => {
    if (key === "tagIds" && obj[key].indexOf(tagId) >= 0) {
      contentList.push(obj.text);
    } else if (typeof obj[key] === "object") {
      getTaggedContentFromLeaf(obj[key], tagId, contentList);
    }
  });

  return obj;
}

import styled from "@emotion/styled";
import * as React from "react";
import { FiSettings, FiInbox, FiList, FiUsers } from "react-icons/fi";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY,
  DARK_SECONDARY_DARK,
  DARK_TERTIARY_LIGHT,
  SECONDARY,
  SECONDARY_LIGHT
} from "../../../colors";
import withShortcuts from "../../../shortcuts/withShortcuts";
import { ShortcutProps, TAB, UserWithProfilePicture } from "../../../types";
import Help from "../../icons/Help";
import Logo from "../../icons/Logo";
import FeedbackModal from "./FeedbackModal";
import SidebarActions from "./SidebarActions";

type Props = {
  activeTab: TAB;
  user: UserWithProfilePicture;
} & ShortcutProps &
  RouteComponentProps;

type State = {
  showFeedbackModal: boolean;
};

class SideBar extends React.Component<Props, State> {
  state: State = {
    showFeedbackModal: false
  };

  UNSAFE_componentWillMount() {
    this.props.manager.bind(
      "g i",
      () => {
        this.props.history.push("/");
      },
      this.constructor.name,
      "Go to inbox",
      1
    );

    this.props.manager.bind(
      "g f",
      () => {
        this.props.history.push("/insights");
      },
      this.constructor.name,
      "Go to insights",
      1
    );

    this.props.manager.bind(
      "g p",
      () => {
        this.props.history.push("/people");
      },
      this.constructor.name,
      "Go to people",
      1
    );

    this.props.manager.bind(
      "g s",
      () => {
        this.props.history.push("/settings");
      },
      this.constructor.name,
      "Go to settings",
      1
    );
  }

  componentWillUnmount() {
    this.props.manager.unbind("g i", this.constructor.name);
    this.props.manager.unbind("g f", this.constructor.name);
    this.props.manager.unbind("g s", this.constructor.name);
    this.props.manager.unbind("g p", this.constructor.name);
  }

  render() {
    return (
      <Container>
        <LogoContainer>
          <Logo width="30px" height="30px" />
          <Version>v1.0.0</Version>
          <ProfilePicture
            onClick={() => {
              this.props.history.push("/settings");
            }}
          >
            {this.props.user.profilePictureUrl ? (
              <Image src={this.props.user.profilePictureUrl} />
            ) : null}
          </ProfilePicture>
        </LogoContainer>
        <Link to="/">
          <Tab active={this.props.activeTab === TAB.HOME}>
            <FiInbox />
            <div style={{ paddingLeft: 12 }}>Inbox</div>
          </Tab>
        </Link>
        <Link to="/people">
          <Tab active={this.props.activeTab === TAB.CONTACTS}>
            <FiUsers />
            <div style={{ paddingLeft: 12 }}>People</div>
          </Tab>
        </Link>
        <Link to="/insights">
          <Tab active={this.props.activeTab === TAB.INSIGHTS}>
            <FiList />
            <div style={{ paddingLeft: 12 }}>Insights</div>
          </Tab>
        </Link>
        <Link to="/settings">
          <Tab active={this.props.activeTab === TAB.SETTINGS}>
            <FiSettings />
            <div style={{ paddingLeft: 12 }}>Settings</div>
          </Tab>
        </Link>
        <ContactUsContainer
          onClick={() => {
            this.setState({
              showFeedbackModal: true
            });
          }}
        >
          <Help />
          &nbsp; Help
        </ContactUsContainer>
        <SidebarActions />
        {this.state.showFeedbackModal && (
          <FeedbackModal
            user={this.props.user}
            hideModal={() => {
              this.setState({
                showFeedbackModal: false
              });
            }}
          />
        )}
      </Container>
    );
  }
}

export default withShortcuts(withRouter(SideBar));

const ContactUsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: auto;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
  color: ${DARK_SECONDARY};
  transition: background-color 0.16s ease-in-out;
`;

const Image = styled.img`
  display: block;
  max-width: 30px;
  max-height: 30px;
  object-fit: cover;
`;

const Version = styled.div`
  margin-left: 10px;
  font-size: 12px;
  color: ${DARK_SECONDARY_DARK};
`;

const ProfilePicture = styled.div`
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: ${DARK_TERTIARY_LIGHT};
  overflow: hidden;
  display: flex;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 219px;
  align-items: center;
  height: 60px;
  margin-left: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${DARK_PRIMARY};
  padding-top: 60px;
  width: 240px;
  justify-content: space-between;
`;

const Tab = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 60px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: ${props => (props.active ? SECONDARY_LIGHT : SECONDARY)};
  background-color: ${props =>
    props.active ? DARK_PRIMARY_LIGHT : DARK_PRIMARY};
  font-size: 1em;
  :focus {
    outline: none;
  }
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
  transition: 0.16s ease-in-out;
`;

import React from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import * as _ from "lodash";
import useOutsideClick from "../../hooks/useClickedOutside";
import { generateRandomColor } from "../../utils";

export type Highlight = {
  id?: string;
  content: string;
  startOffset: number;
  endOffset: number;
  tags?: any[];
};

type Props = {
  highlights?: Highlight[];
  active?: boolean;
  text: string;
  onHighlight?: (highlight: Highlight) => void;
  renderTooltip?: (highlight?: Highlight) => React.ReactChild;
  onClickHighlight: (highlight: Highlight) => void;
  onRemoveCurrentHighlight: () => void;
  currentHighlight?: Highlight;
  doNotRemove: boolean;
};

// https://medium.com/unprogrammer/a-simple-text-highlighting-component-with-react-e9f7a3c1791a
// negatives: can't highlight multiple ranges in a text, can't copy and paste selected portion
function HighlightableText(props: Props) {
  const {
    onHighlight,
    renderTooltip,
    active,
    highlights,
    text,
    onClickHighlight,
    currentHighlight,
    onRemoveCurrentHighlight,
    doNotRemove
  } = props;

  const highlightContainerRef = React.useRef<HTMLDivElement>(null);
  const currentHighlightRef = React.useRef<HTMLSpanElement>(null);
  const tooltipContainerRef = React.useRef<HTMLDivElement>(null);
  const [isHighlighting, setIsHighlighting] = React.useState(false);

  useOutsideClick(tooltipContainerRef, () => {
    if (!doNotRemove) {
      onRemoveCurrentHighlight();
    }
  });

  const hasHighlights = highlights && highlights.length;
  // Initialize the shown highlights with the existingHighlgihts
  let showHighlights: typeof highlights = hasHighlights
    ? highlights!.slice() // Make a copy so we don't mutate the prop
    : []; // default empty array for highlights

  if (currentHighlight) {
    showHighlights.push(currentHighlight);
  }

  // Remove any duplicate highlights (determining uniqueness by their start and end offsets), clean the array
  showHighlights = _.uniqWith(showHighlights, (a, b) => {
    const range1 = [a.startOffset, a.endOffset];
    const range2 = [b.startOffset, b.endOffset];
    return _.isEqual(range1, range2);
  });
  // sort the highlights by startOffset
  showHighlights.sort((a, b) => {
    return a.startOffset - b.startOffset;
  });

  // Build array of text segments, either highlighted or not
  let segments: {
    text: string;
    highlighted: boolean;
    highlight?: Highlight;
  }[] = [];
  if (showHighlights.length) {
    let currentTextIndex = 0; // counter for where we are in the string
    showHighlights.forEach(highlight => {
      const { startOffset, endOffset } = highlight;
      if (startOffset > currentTextIndex) {
        // Put all text before the highlight into a segment
        const slice = text.slice(currentTextIndex, startOffset);
        segments.push({
          text: slice,
          highlighted: false
        });
        currentTextIndex = startOffset;
      }
      // now put all the highlighted text into a segment and move on
      const slice = text.slice(startOffset, endOffset);
      segments.push({
        text: slice,
        highlighted: true,
        highlight
      });
      currentTextIndex = endOffset;
    });
    if (currentTextIndex < text.length) {
      segments.push({
        text: text.slice(currentTextIndex),
        highlighted: false
      });
    }
  }

  const onMouseUpHandler = (e: MouseEvent) => {
    // stop default behavior
    e.preventDefault();
    // Use selectionObj (https://developer.mozilla.org/en-US/docs/Web/API/Selection)
    // To calculate new partitions for first, middle, last
    const selectionObj = window.getSelection && window.getSelection();
    // If no selection obj cancel
    if (!selectionObj) {
      setIsHighlighting(false);
      return;
    }
    // stop if selection is across multiple nodes
    if (selectionObj.anchorNode !== selectionObj.focusNode) {
      selectionObj.empty();
      setIsHighlighting(false);
      return;
    }
    const selection = selectionObj.toString();
    // Sometimes this happens, abort
    if (selectionObj.rangeCount <= 0) {
      setIsHighlighting(false);
      return;
    }
    const range = selectionObj.getRangeAt(0);
    const nodeText = range.commonAncestorContainer.textContent!;
    // Now, need to identify which segment the highlight is in and calculate the right startOffset with respect to entire text
    let startOffset = 0;
    let endOffset = 0;
    if (segments.length) {
      for (const segment of segments) {
        const { text } = segment;
        if (text === nodeText) {
          // this is the segment
          startOffset += range.startOffset;
          endOffset = startOffset + selection.length;
          break;
        } else {
          startOffset += text.length;
        }
      }
    } else {
      // If no highlights are present
      startOffset = range.startOffset;
      endOffset = range.endOffset;
    }
    // Call onSelection handler if defined
    if (onHighlight && selection !== "") {
      onHighlight({
        content: selection,
        startOffset,
        endOffset
      });
    }
    // Have to set this to false to clear the listener
    setIsHighlighting(false);
  };

  React.useEffect(() => {
    if (isHighlighting) {
      window.addEventListener("mouseup", onMouseUpHandler);
    } else {
      window.removeEventListener("mouseup", onMouseUpHandler);
    }
    return () => {
      window.removeEventListener("mouseup", onMouseUpHandler);
    };
  });

  // Build the array of nodes to render, using segments
  let render: React.ReactChild[] = [];
  if (!segments.length) {
    render.push(<span key={0}>{text}</span>);
  }
  segments.forEach((segment, index) => {
    const { text, highlighted, highlight } = segment;
    if (highlighted) {
      const { id } = highlight!;
      const isCurrentHighlight = !id;
      render.push(
        <Highlighted
          ref={isCurrentHighlight ? currentHighlightRef : undefined}
          color={
            highlight!.tags
              ? generateRandomColor(highlight!.tags[0].id!)
              : "#a99128;"
          }
          onMouseDown={e => {
            // most likely trying to click n this
            e.stopPropagation();
            if (!isCurrentHighlight) {
              onClickHighlight(highlight!);
            }
          }}
          key={index}
        >
          {text}
        </Highlighted>
      );
    } else {
      render.push(<span key={index}>{text}</span>);
    }
  });

  if (!currentHighlight || !active) {
    return (
      <Container
        onMouseDown={() => {
          setIsHighlighting(true);
        }}
      >
        {render}
      </Container>
    );
  } else {
    // there's something highlighted, show tooltip right next to the highlighted prortion
    return (
      <Container
        ref={highlightContainerRef}
        onMouseDown={e => {
          setIsHighlighting(true);
          onRemoveCurrentHighlight();
        }}
      >
        <span>{render}</span>
        {currentHighlight &&
          !currentHighlight.id &&
          renderTooltip &&
          ReactDOM.createPortal(
            <div
              ref={tooltipContainerRef}
              style={{
                position: "absolute",
                top: currentHighlightRef.current?.getBoundingClientRect()
                  .bottom,
                left: currentHighlightRef.current?.getBoundingClientRect().right
              }}
            >
              {renderTooltip()}
            </div>,
            document.body
          )}
      </Container>
    );
  }
}

const Highlighted = styled.span<{ color?: string }>`
  background-color: ${props => props.color};
  border-bottom: 2px solid #f4cb06;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 28px 36px;
`;

export default HighlightableText;

import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { RouteComponentProps } from "react-router";
import LoadingScreen from "../components/LoadingScreen";
import Settings from "../components/settings/Settings";
import DashboardShell from "../components/shared/DashboardShell";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";
import { CurrentUser } from "../graphql/generated/types";
import { CURRENT_USER } from "../graphql/queries";
import { TAB } from "../types";

class SettingsPage extends React.Component<RouteComponentProps<any>, void> {
  render() {
    return (
      <Query query={CURRENT_USER}>
        {({ loading, error, data }: QueryResult<CurrentUser>) => {
          if (loading) {
            return <LoadingScreen />;
          }
          if (error || !data || !data.me || !data.me.user) {
            return <ErrorShowIntercom></ErrorShowIntercom>;
          }
          return (
            <DashboardShell activeTab={TAB.SETTINGS} user={data.me.user}>
              <Settings user={data.me.user} />
            </DashboardShell>
          );
        }}
      </Query>
    );
  }
}

export default SettingsPage;

import React from "react";
import styled from "@emotion/styled";
import { PRIMARY, PRIMARY_LIGHT } from "../../colors";

function NoNoteSelected() {
  return (
    <NoteContentContainer>
      <Trash></Trash>
    </NoteContentContainer>
  );
}

const NoteContentContainer = styled.div`
  flex: 3;
  background-color: ${PRIMARY};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

export default NoNoteSelected;

// Just to see what happens on overflow in the right pane
function Trash() {
  return <div style={{ padding: 40, color: "white" }}>No note selected.</div>;
}

import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { CurrentUser } from "../../graphql/generated/types";
import { CURRENT_USER } from "../../graphql/queries";
import useIntercomWithUser from "../../hooks/useIntercomWithUser";
import LoadingScreen from "../LoadingScreen";

type Props = {
  component: (props: any) => JSX.Element;
} & RouteProps;

function AuthRoute(props: Props) {
  const { component: Component, ...rest } = props;
  const { loading: meLoading, error: meError, data: meData } = useQuery<
    CurrentUser
  >(CURRENT_USER);
  useIntercomWithUser(meData?.me?.user);

  if (meLoading) {
    return <LoadingScreen />;
  } else if (meError) {
    return (
      <div>
        Error: {meError.message}. Our server is probably down. Please contact
        support@getomnition.com
      </div>
    );
  }

  // has data
  const {
    me: { user }
  } = meData as CurrentUser;

  let isAuthed = false;
  if (user) {
    isAuthed = true;
  }

  if (!isAuthed) {
    return <Redirect to="/login" />;
  }

  if (!user || !user.organization) {
    return <Redirect to="/welcome"></Redirect>;
  }

  return (
    <Route {...rest} render={props => <Component {...props} user={user} />} />
  );
}

export default AuthRoute;

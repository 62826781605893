import styled from "@emotion/styled";
import * as React from "react";
import { useEffect } from "react";
import SimpleBar from "simplebar-react";
import { SECONDARY_LIGHT } from "../../colors";
import {
  CurrentUserWithContact_me_user_organization,
  CurrentUserWithContact_me_user_organization_contact,
  CurrentUserWithContact_me_user_organization_contact_manualNotes,
  manualNoteEdited,
  CurrentUser
} from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import Editor from "./Editor";
import { CURRENT_USER } from "../../graphql/queries";
import { useSubscription, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const NOTE_EDITED_SUBSCRIPTION = gql`
  subscription manualNoteEdited($noteId: ID!) {
    manualNoteEdited(noteId: $noteId) {
      note {
        id
        content
      }
      editingUser
    }
  }
`;

const DEFAULT_CONTENT = [
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];

type Props = {
  contact?: CurrentUserWithContact_me_user_organization_contact;
  onHideNote: () => any;
  currentNote?: CurrentUserWithContact_me_user_organization_contact_manualNotes;
  organization: CurrentUserWithContact_me_user_organization;
  onNoteUpdate: (note: any) => void;
} & ShortcutProps;

function Note(props: Props) {
  const { currentNote, onNoteUpdate } = props;
  const { data: meData } = useQuery<CurrentUser>(CURRENT_USER);
  const { data: editingData, loading } = useSubscription<manualNoteEdited>(
    NOTE_EDITED_SUBSCRIPTION,
    {
      variables: {
        noteId: props.currentNote?.id
      },
      onSubscriptionData: data => {
        onNoteUpdate(data.subscriptionData.data?.manualNoteEdited?.note);
      }
    }
  );

  useEffect(() => {
    props.manager.bind("esc", onHideNote, "Notes", "Hide note editor", 3);

    return () => {
      props.manager.unbind("esc", "Notes");
    };
  });

  const noNeedToSave = () => {
    props.setNotification({
      notification: "Omniscient autosaves for you!",
      timestamp: Date.now()
    });
  };

  const onHideNote = async () => {
    // props.onHideNote();
  };

  return (
    <BlankScreen>
      <SimpleBar style={{ height: "100%" }}>
        <EditorContainer>
          <Editor
            currentNoteId={currentNote?.id}
            organization={props.organization}
            showToolbar={false}
            content={
              currentNote?.content
                ? JSON.parse(currentNote?.content!)
                : DEFAULT_CONTENT
            }
            shortcuts={[
              {
                keys: "mod+s",
                callback: noNeedToSave
              },
              {
                keys: "esc",
                callback: onHideNote
              }
            ]}
          />
        </EditorContainer>
      </SimpleBar>
    </BlankScreen>
  );
}

export default withShortcuts(Note);

const BlankScreen = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  overflow-y: auto;
  color: ${SECONDARY_LIGHT};
`;

const EditorContainer = styled.div`
  padding: 40px;
`;

import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import produce from "immer";
import React from "react";
import { useHistory } from "react-router-dom";
import { DARK_PRIMARY, DARK_PRIMARY_LIGHT, SECONDARY } from "../../../colors";
import { CreateManualNote, CurrentUser, GetInboxForUser } from "../../../graphql/generated/types";
import { CREATE_MANUAL_NOTE } from "../../../graphql/mutations";
import { CURRENT_USER, GET_INBOX } from "../../../graphql/queries";
import { useInboxFilterContext } from "../../inbox/InboxFilterProvider";

function SidebarActions() {
  const { data: meData } = useQuery<CurrentUser>(CURRENT_USER);
  const [createManualNote] = useMutation<CreateManualNote>(CREATE_MANUAL_NOTE, {
    update: (cache, result) => {
      const queryArgs = {
        query: GET_INBOX,
        variables: {
          input: {
            userId: meData!.me!.user!.id,
            filter,
            cursor: {
              limit: 15,
              offset: 0
            }
          }
        }
      };
      const getInboxData: GetInboxForUser | null = cache.readQuery({
        ...queryArgs
      });
      if (!getInboxData) {
        throw new Error("Should be user here");
      }
      const nextState = produce(getInboxData, draftState => {
        draftState.getInboxForUser.notes = [
          result.data?.createManualNote.note!,
          ...draftState.getInboxForUser.notes
        ];
      });
      cache.writeQuery({
        ...queryArgs,
        data: nextState
      });
    }
  });
  const history = useHistory();
  const { filter } = useInboxFilterContext();

  const handleCreateNewNote = async () => {
    const result = await createManualNote({
      variables: {
        input: {
          title: "New Note",
          content: `[{"type":"paragraph","children":[{"text":""}]}]`,
          tagIds: []
        }
      }
    });
    history.push("/notes/" + result.data?.createManualNote.note?.id || "");
  };

  return (
    <Container>
      <Tab onClick={handleCreateNewNote}>+ New Note</Tab>
    </Container>
  );
}

const Container = styled.div`
  border-top: 1px solid ${DARK_PRIMARY_LIGHT};
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 60px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: ${SECONDARY};
  background-color: ${DARK_PRIMARY};
  font-size: 1em;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
  transition: 0.16s ease-in-out;
`;

export default SidebarActions;

import React from "react";

const DEFAULT_SIZE = 20;

type Props = {
  size?: number;
  contact?: {
    name: string;
  };
  name?: string;
};

function getInitials(name: string) {
  if (!name) {
    name = "Anonymous";
  }
  const split = name.split(/[ ,]+/).filter(Boolean);

  if (split.length === 1) {
    return split[0].slice(0, 1).toUpperCase();
  } else if (split.length === 2) {
    return (
      split[0].slice(0, 1).toUpperCase() + split[1].slice(0, 1).toUpperCase()
    );
  } else {
    return split[0].slice(0, 1).toUpperCase();
  }
}

function ContactAvatar(props: Props) {
  const { size, contact, name } = props;

  return (
    <div
      style={{
        width: size || DEFAULT_SIZE,
        height: size || DEFAULT_SIZE,
        color: "black",
        backgroundColor: "white",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: 600,
        padding: 4
      }}
    >
      {contact && getInitials(contact.name)}
      {name && getInitials(name)}
      {!contact && !name && getInitials("Anonymous")}
    </div>
  );
}

export default ContactAvatar;

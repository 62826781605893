import hoistNonReactStatics from "hoist-non-react-statics";
import * as React from "react";
import { ShortcutProps } from "../types";
import ShortcutContext, { ShortcutContextType } from "./shortcutContext";

const withShortcuts = <P extends object>(Component: React.ComponentType<P>) => {
  type WrappedComponentPropsExceptProvided = Exclude<
    keyof P,
    keyof ShortcutProps
  >;
  type ForwardedProps = Pick<P, WrappedComponentPropsExceptProvided>;
  return hoistNonReactStatics(
    class WithShortcutComponent extends React.Component<ForwardedProps> {
      render() {
        return (
          <ShortcutContext.Consumer>
            {(ShortcutContext: ShortcutContextType) => {
              const {
                manager,
                notification,
                setNotification,
                notificationTimestamp
              } = ShortcutContext;
              return (
                <>
                  <Component
                    {...(this.props as P)}
                    manager={manager}
                    notification={notification}
                    notificationTimestamp={notificationTimestamp}
                    setNotification={setNotification}
                  />
                </>
              );
            }}
          </ShortcutContext.Consumer>
        );
      }
    },
    Component
  );
};

export default withShortcuts;

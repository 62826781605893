import { useQuery, useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import React from "react";
import {
  Redirect,
  RouteComponentProps,
  useParams,
  withRouter
} from "react-router";
import { PRIMARY, PRIMARY_LIGHT } from "../../colors";
import {
  CurrentUser,
  getNote,
  clearEditingUserForNoteId
} from "../../graphql/generated/types";
import { CURRENT_USER, GET_NOTE } from "../../graphql/queries";
import IntercomConversationEditor from "./IntercomConversationEditor";
import NoteEditor from "./NoteEditor";
import NoteHeader from "./NoteHeader";
import ContactPane from "./ContactPane";
import * as _ from "lodash";
import gql from "graphql-tag";

type Props = {
  id?: string;
} & RouteComponentProps;

function Note(props: Props) {
  let { id } = useParams();
  if (!id) {
    id = props.id;
  }
  const [showContactPane, setShowContactPane] = React.useState(false);

  const { data, loading, error, refetch } = useQuery<getNote>(GET_NOTE, {
    variables: {
      input: {
        id
      }
    },
    pollInterval: 400
  });
  const { data: meData } = useQuery<CurrentUser>(CURRENT_USER);

  const handleNoteUpdate = (note: any) => {
    refetch();
  };

  if (loading) {
    return (
      <NoteContainer>
        <Spinner></Spinner>
      </NoteContainer>
    );
  }

  const note = data?.getNote?.note;
  if (!note) {
    return <Redirect to="/"></Redirect>;
  }
  if (
    note.__typename === "ManualNote" &&
    !_.isEmpty(note.editingUserId) &&
    note.editingUserId !== meData?.me.user?.id
  ) {
    // can't edit this someone else already is
    return (
      <NoteContainer>
        <NoteContentContainer>
          <div style={{ padding: 40 }}>
            <p>
              Another team member is currently editing this note. Updating...
            </p>
          </div>
        </NoteContentContainer>
      </NoteContainer>
    );
  }

  const noteType = note.type;

  // Get inbox data
  let rightPane = null;
  if (noteType === "INTERCOM_NOTE") {
    rightPane = <IntercomConversationEditor key={note.id} note={note as any} />;
  } else if (noteType === "MANUAL") {
    rightPane = (
      <NoteEditor
        onNoteUpdate={handleNoteUpdate}
        key={note.id}
        organization={meData?.me?.user?.organization as any}
        contact={note.contact as any}
        currentNote={note as any}
        onHideNote={() => props.history.push("/")}
      />
    );
  }

  const handleClickContact = () => {
    setShowContactPane(prev => !prev);
  };

  return (
    <NoteContainer>
      <NoteContentContainer>
        <NoteHeader
          onClickContact={handleClickContact}
          key={note.id}
          contact={note.contact}
          note={note}
        ></NoteHeader>
        <div>{rightPane}</div>
      </NoteContentContainer>
      {showContactPane && (
        <ContactPane
          contact={note.contact!}
          onClose={() => setShowContactPane(false)}
        ></ContactPane>
      )}
      {/* <NoteFooter note={note}></NoteFooter> */}
    </NoteContainer>
  );
}

export default withRouter(Note);

const NoteContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: ${PRIMARY};
`;

const NoteContentContainer = styled.div`
  flex: 1;
  background-color: ${PRIMARY};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

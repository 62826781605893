import { useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import gql from "graphql-tag";
import React from "react";
import SimpleBar from "simplebar-react";
import { PRIMARY_DARK, PRIMARY_LIGHT } from "../../colors";
import { getSuggestedTagsForHighlight } from "../../graphql/generated/types";

const GET_SUGGESTED_TAGS_FOR_HIGHLIGHT = gql`
  query getSuggestedTagsForHighlight(
    $input: GetSuggestedTagsForHighlightInput!
  ) {
    getSuggestedTagsForHighlight(input: $input) {
      error {
        message
      }
      tags {
        id
        name
      }
    }
  }
`;

type TagSearchResultsProps = {
  searchQuery: string;
  targetId: string;
  targetType: string;
  currentTagIds: string[];
  onCreateTag: () => void;
  onAddTag: (tagId: string) => void;
};

function TagSearchResults(props: TagSearchResultsProps) {
  const {
    searchQuery,
    onCreateTag,
    onAddTag,
    targetId,
    targetType,
    currentTagIds
  } = props;
  const { data: tagsData, loading: tagsLoading } = useQuery<
    getSuggestedTagsForHighlight
  >(GET_SUGGESTED_TAGS_FOR_HIGHLIGHT, {
    variables: {
      input: {
        searchQuery,
        targetId,
        targetType,
        currentTagIds
      }
    },
    fetchPolicy: "no-cache"
  });
  const tags = tagsData?.getSuggestedTagsForHighlight?.tags || [];
  const noTagsFound = !tags.length && searchQuery !== "";
  const showCreateOption =
    noTagsFound ||
    (tags.length && tags[0].name !== searchQuery && searchQuery !== "");

  // Handling keyboard events
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    setCurrentIndex(0);
  }, [searchQuery]);
  const handleKeyPress = (event: any) => {
    const { key } = event;
    let handled = true;
    const totalTags = showCreateOption ? tags.length + 1 : tags.length;
    if (key === "Enter") {
      handleSubmit();
    } else if (key === "ArrowDown") {
      if (currentIndex === totalTags - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(prev => prev + 1);
      }
    } else if (key === "ArrowUp") {
      if (currentIndex === 0) {
        setCurrentIndex(totalTags - 1);
      } else {
        setCurrentIndex(prev => prev - 1);
      }
    } else {
      handled = false;
    }
    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const handleSubmit = (index?: number) => {
    if (noTagsFound) {
      // create tag
      onCreateTag();
    } else if (
      (showCreateOption && index === 0) ||
      (showCreateOption && currentIndex === 0)
    ) {
      // create tag
      onCreateTag();
    } else {
      // use currentIndex to add the tag
      if (index) {
        return onAddTag(tags[showCreateOption ? index - 1 : index].id);
      }
      onAddTag(tags[showCreateOption ? currentIndex - 1 : currentIndex].id);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  if (tagsLoading) {
    return <Spinner></Spinner>;
  }

  const renderList = () => {
    const startIndex = showCreateOption ? 1 : 0;
    return (
      <>
        {showCreateOption && (
          <ResultItem active={currentIndex === 0}>
            Create feature: {searchQuery}
          </ResultItem>
        )}
        {tags.map((tag, index) => {
          const myIndex = showCreateOption ? index + startIndex : index;
          return (
            <ResultItem
              active={myIndex === currentIndex}
              key={tag.id}
              onClick={() => {
                handleSubmit(index);
              }}
            >
              {tag.name}
            </ResultItem>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <SimpleBar style={{ height: "100%" }}>{renderList()}</SimpleBar>
    </div>
  );
}

const ResultItem = styled.div<{ active?: boolean }>`
  padding: 20px;
  background-color: ${props => (props.active ? PRIMARY_DARK : PRIMARY_LIGHT)};
  cursor: pointer;
  :hover {
    background-color: ${PRIMARY_DARK};
  }
`;

export default TagSearchResults;

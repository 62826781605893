import styled from "@emotion/styled";
import * as React from "react";
import { PRIMARY_LIGHT } from "../../colors";
import {
  CurrentUserWithContact_me_user,
  CurrentUserWithContact_me_user_organization_contact
} from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import IntercomConversationItem from "../notes/IntercomConversationItem";
import ManualNoteItem from "../notes/ManualNoteItem";

type Props = {
  user: CurrentUserWithContact_me_user;
  contact: CurrentUserWithContact_me_user_organization_contact;
  openNote: (id: string) => any;
  openIntercomMessage: (id: string) => any;
} & ShortcutProps;

type State = {};

class ContactActivityLog extends React.Component<Props, State> {
  state: State = {};

  render() {
    return (
      <Container>
        {/* <ActivityLogHeader /> */}
        <NoteItemContainer>
          {this.props.contact.manualNotes.map((note, index) => {
            return (
              <ManualNoteItem
                contact={this.props.contact}
                note={note}
                key={index}
                openNote={() => this.props.openNote(note.id)}
              />
            );
          })}
          {this.props.contact.intercomNotes.map((note, index) => {
            return (
              <IntercomConversationItem
                key={index}
                note={note}
                onClick={() => this.props.openIntercomMessage(note.id)}
              />
            );
          })}
        </NoteItemContainer>
      </Container>
    );
  }
}

export default withShortcuts(ContactActivityLog);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 400px;
  color: rgb(255, 255, 255);
`;

const ActivityLogHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  height: 40px;
  border-bottom: 1px solid ${PRIMARY_LIGHT};
`;

const NoteItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

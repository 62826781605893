import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { IconContext } from "react-icons/lib/cjs";
import { DARK_PRIMARY_LIGHT, DARK_TERTIARY_LIGHT } from "../../../colors";
import withShortcuts from "../../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../../types";
type Props = {} & ShortcutProps;

function Notification(props: Props) {
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const debouncedSetFalse = useMemo(
    () => () =>
      setTimeout(() => {
        setShowNotification(false);
      }, 5000),
    []
  );

  useEffect(() => {
    setShowNotification(true);
    debouncedSetFalse();
  }, [props.notificationTimestamp]);

  if (!props.notification || !showNotification) {
    return null;
  }

  return (
    <Container>
      {props.notification}
      <CloseNotification onClick={() => setShowNotification(false)}>
        <IconContext.Provider value={{ style: { fontSize: "40px" } }}>
          <IoIosClose />
        </IconContext.Provider>
      </CloseNotification>
    </Container>
  );
}

export default withShortcuts(Notification);

const Container = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 50px;
  background-color: ${DARK_PRIMARY_LIGHT};
  bottom: 50px;
  left: 50px;
  color: ${DARK_TERTIARY_LIGHT};
  border-radius: 4px;
  padding-left: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
`;

const CloseNotification = styled.div`
  margin-right: 20px;
  margin-left: 20px;
  background-color: ${DARK_PRIMARY_LIGHT};
`;

import styled from "@emotion/styled";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_DARK,
  DARK_SECONDARY_DARK,
  DARK_SECONDARY_LIGHT
} from "../../colors";
import { CurrentUserWithTags_me_user_organization_tags } from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import { generateRandomColor } from "../../utils";
import Contact from "../icons/Contact";
import Tag from "../icons/Tag";

type Props = {
  tag: CurrentUserWithTags_me_user_organization_tags;
} & ShortcutProps &
  RouteComponentProps;

function TagItem(props: Props) {
  return (
    <Container
      onClick={() => {
        props.history.push(`/insight?id=${props.tag.id}`);
      }}
    >
      <Row>
        <Dot color={generateRandomColor(props.tag.id)} />
        <TagName>{props.tag.name}</TagName>
      </Row>
      <Row>
        <TagCountContainer>
          <TagCount>{props.tag.noteCount}</TagCount>
          <Tag />
        </TagCountContainer>
        <TagContactContainer>
          <TagContactCount>{props.tag.contactCount}</TagContactCount>
          <Contact />
        </TagContactContainer>
      </Row>
    </Container>
  );
}

export default withRouter(withShortcuts(TagItem));

const Row = styled.div`
  display: flex;
  align-items: center;
  :last-child {
    margin-top: 5px;
  }
`;

const Container = styled.div`
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: calc(100% - 60px);
  background-color: ${DARK_PRIMARY_DARK};
  :hover {
    background-color: ${DARK_PRIMARY};
  }
  transition: 0.16s ease-in-out;
`;

const Dot = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${props => props.color};
`;

const TagName = styled.div`
  margin-left: 20px;
  color: ${DARK_SECONDARY_LIGHT};
`;

const TagCountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const TagContactContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const TagCount = styled.div`
  color: ${DARK_SECONDARY_DARK};
  margin-right: 4px;
`;

const TagContactCount = styled.div`
  color: ${DARK_SECONDARY_DARK};
  margin-right: 4px;
`;

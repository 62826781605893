import { useQuery } from "@apollo/react-hooks";
import * as queryString from "query-string";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import SharedInsight from "../components/insights/SharedInsight";
import LoadingScreen from "../components/LoadingScreen";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";
import { SharedOrganizationWithInsight } from "../graphql/generated/types";
import { SHARED_ORGANIZATION_WITH_INSIGHT } from "../graphql/queries";

type Props = {} & RouteComponentProps;

function InsightsPage(props: Props) {
  const { insightId, organizationId } = queryString.parse(
    props.location.search
  );

  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError
  } = useQuery<SharedOrganizationWithInsight>(
    SHARED_ORGANIZATION_WITH_INSIGHT,
    { variables: { insightId: insightId, organizationId: organizationId } }
  );

  const organization = organizationData?.organization;

  if (organizationLoading) {
    return <LoadingScreen />;
  } else if (!organization || organizationError) {
    return <ErrorShowIntercom />;
  }

  return <SharedInsight organization={organization} />;
}

export default withRouter(InsightsPage);

import styled from "@emotion/styled";
import React, { useEffect } from "react";
import {
  DARK_PRIMARY,
  DARK_SECONDARY_DARK,
  DARK_SECONDARY_LIGHT
} from "../../../colors";
import ShortcutManager from "../../../shortcuts/ShortcutManager";
import withShortcuts from "../../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../../types";
import Command from "../../icons/Keys/Command";
import Enter from "../../icons/Keys/Enter";
import Shift from "../../icons/Keys/Shift";
import ClickOutside from "../ClickOutside";
import Shortcut from "../Shortcut";

type Props = {
  hideCheatsheet: () => any;
} & ShortcutProps;

function Cheatsheet(props: Props) {
  useEffect(() => {
    props.manager.bind(
      "esc",
      props.hideCheatsheet,
      "Cheatsheet",
      "Hide cheatsheet",
      100
    );
    return () => {
      props.manager.unbind("esc", "Cheatsheet");
    };
  });

  const shortcuts = Object.keys(props.manager.activeShortcuts)
    .map((shortcutHotkey, index) => {
      let maxShortcutClassName = null;
      let maxShortcutDescription = "";
      let maxPriority = -1;
      Object.keys(props.manager.activeShortcuts[shortcutHotkey]).forEach(
        shortcutClassName => {
          const count =
            props.manager.activeShortcuts[shortcutHotkey][shortcutClassName]
              .count;
          const priority =
            props.manager.activeShortcuts[shortcutHotkey][shortcutClassName]
              .priority;
          const description =
            props.manager.activeShortcuts[shortcutHotkey][shortcutClassName]
              .description;
          if (count && priority > maxPriority) {
            maxShortcutClassName = shortcutClassName;
            maxPriority = priority;
            maxShortcutDescription = description;
          }
        }
      );

      return maxShortcutClassName && maxShortcutDescription ? (
        <ShortcutItem
          key={index}
          manager={props.manager}
          shortcutHotkey={shortcutHotkey}
          shortcutClassName={maxShortcutClassName}
          description={maxShortcutDescription}
        />
      ) : null;
    })
    .filter(shortcutItem => {
      return shortcutItem;
    });

  return (
    <ClickOutside onClickOutside={props.hideCheatsheet}>
      <Background onClick={props.hideCheatsheet}>
        <Container onClick={e => e.stopPropagation()}>{shortcuts}</Container>
      </Background>
    </ClickOutside>
  );
}

export default withShortcuts(Cheatsheet);

type ShortcutItemProps = {
  manager: ShortcutManager;
  shortcutHotkey: string;
  description: string;
  shortcutClassName: string;
};

function ShortcutItem(props: ShortcutItemProps) {
  const shortcutActions = props.shortcutHotkey
    .split(" ")
    .map((action, index) => {
      if (index) {
        return [
          <Then key={`then${index}`}>, then</Then>,
          ...action.split("+").map(subAction => {
            return (
              <StyledShortcut key={`shortcut${index}`}>
                {iconedAction(subAction)}
              </StyledShortcut>
            );
          })
        ];
      }

      return action.split("+").map((subAction, index) => {
        return (
          <StyledShortcut key={`shortcut${index}`}>
            {iconedAction(subAction)}
          </StyledShortcut>
        );
      });
    })
    .flat();
  return (
    <ShortcutItemContainer>
      {shortcutActions}
      <Description>{props.description}</Description>
    </ShortcutItemContainer>
  );
}

function iconedAction(action: string) {
  switch (action) {
    case "enter":
      return <Enter />;
    case "command":
      return <Command />;
    case "shift":
      return <Shift />;
    default:
      return action;
  }
}

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const Container = styled.div`
  margin-left: auto;
  height: calc(100vh - 40px);
  padding-top: 40px;
  width: 30vw;
  background-color: ${DARK_PRIMARY};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
`;

const ShortcutItemContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 40px;
`;

const Description = styled.div`
  margin-left: 20px;
  color: ${DARK_SECONDARY_LIGHT};
`;

const StyledShortcut = styled(Shortcut)`
  margin-left: 10px;
  :first-div {
    margin-left: 0px;
  }
`;

const Then = styled.div`
  margin-left: 2px;
  color: ${DARK_SECONDARY_DARK};
`;

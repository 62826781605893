import { useEffect } from "react";

const useIntercomWithUser = (userData: any) => {
  useEffect(() => {
    (window as any).Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      ...userData
    });
  }, [userData]);
};

export default useIntercomWithUser;

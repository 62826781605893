import { useQuery } from "@apollo/react-hooks";
import * as queryString from "query-string";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Insight from "../components/insights/Insight";
import DashboardShell from "../components/shared/DashboardShell";
import { CurrentUserWithInsight } from "../graphql/generated/types";
import { CURRENT_USER_WITH_INSIGHT } from "../graphql/queries";
import { TAB } from "../types";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";
import { Spinner } from "evergreen-ui";

// fetch notes here

function InsightPage(props: RouteComponentProps) {
  const id = queryString.parse(props.location.search).id;

  const { data: meData, loading } = useQuery<CurrentUserWithInsight>(
    CURRENT_USER_WITH_INSIGHT,
    {
      variables: {
        tagId: id
      }
    }
  );
  const user = meData?.me?.user;

  if (loading) {
    return <Spinner></Spinner>;
  }

  if (!user) {
    return <ErrorShowIntercom></ErrorShowIntercom>;
  }

  return (
    <DashboardShell activeTab={TAB.INSIGHTS} user={user}>
      <Insight user={user} />
    </DashboardShell>
  );
}

export default InsightPage;

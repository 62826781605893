import React from "react";
import styled from "@emotion/styled";
import ContactProfile from "../contacts/ContactProfile";
import { FiX } from "react-icons/fi";
import { DARK_PRIMARY_LIGHT } from "../../colors";

type Props = {
  contact: {
    id: string;
    name: string;
    email: string;
  };
  onClose: () => void;
};

function ContactPane(props: Props) {
  const { contact, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    if (!contact) {
      handleClose();
    }
  });

  if (!contact) {
    return null;
  }

  return (
    <Container>
      <CloseContainer onClick={handleClose}>
        <FiX></FiX>
      </CloseContainer>
      <ContactProfile contact={contact}></ContactProfile>
    </Container>
  );
}

const CloseContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  font-size: 2em;
  cursor: pointer;
  border-radius: 50%;

  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

const Container = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  width: 700px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export default ContactPane;

import * as React from "react";
import ShortcutManager from "./ShortcutManager";

export type ShortcutContextType = {
  manager: ShortcutManager;
  notification: string | null;
  notificationTimestamp: number | null;
  setNotification: (notificationWithTimestamp: {
    notification: string;
    timestamp: number;
  }) => any;
};

const ShortcutContext = React.createContext<ShortcutContextType>({
  manager: new ShortcutManager(),
  notification: null,
  notificationTimestamp: null,
  setNotification: (notificationWithTimestamp: {
    notification: string;
    timestamp: number;
  }) => {}
});

export default ShortcutContext;

import gql from "graphql-tag";

export const CREATE_CONTACT = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      error {
        message
      }
      contact {
        id
        name
        email
      }
    }
  }
`;

export const CREATE_MANUAL_NOTE = gql`
  mutation CreateManualNote($input: CreateManualNoteInput!) {
    createManualNote(input: $input) {
      error {
        message
      }
      note {
        id
        archived
        type
        title
        createdAt
        updatedAt
        content
        contact {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_MANUAL_NOTE = gql`
  mutation UpdateManualNote($noteId: ID!, $input: UpdateManualNoteInput!) {
    updateManualNote(noteId: $noteId, input: $input) {
      error {
        message
      }
      note {
        id
      }
    }
  }
`;

export const CREATE_TAG = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      error {
        message
      }
      tag {
        id
        name
      }
    }
  }
`;

export const ADD_TAG_TO_MANUAL_NOTE = gql`
  mutation AddTagToManualNote($input: AddTagToManualNoteInput!) {
    addTagToManualNote(input: $input) {
      error {
        message
      }
      note {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_CONTACTS = gql`
  mutation DeleteContacts($input: DeleteContactsInput!) {
    deleteContacts(input: $input) {
      error {
        message
      }
      contacts
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: ID!, $input: CreateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      error {
        message
      }
      user {
        id
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $organizationId: ID!
    $input: UpdateOrganizationInput!
  ) {
    updateOrganization(organizationId: $organizationId, input: $input) {
      error {
        message
      }
      organization {
        id
      }
    }
  }
`;

export const REMOVE_TAG_FROM_NOTE_IF_NO_OCCURRENCES = gql`
  mutation RemoveTagFromNoteIfNoOccurrences(
    $input: RemoveTagFromNoteIfNoOccurrencesInput!
  ) {
    removeTagFromNoteIfNoOccurrences(input: $input) {
      error {
        message
      }
      note {
        id
      }
    }
  }
`;

export const SUBMIT_FEEDBACK = gql`
  mutation SubmitFeedback($input: SubmitFeedbackInput!) {
    submitFeedback(input: $input) {
      error {
        message
      }
      feedback {
        id
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($contactId: ID!, $input: CreateContactInput!) {
    updateContact(contactId: $contactId, input: $input) {
      error {
        message
      }
      contact {
        id
        name
        email
      }
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($tagId: ID!, $input: CreateTagInput!) {
    updateTag(tagId: $tagId, input: $input) {
      error {
        message
      }
      tag {
        id
        name
      }
    }
  }
`;

export const ADD_GOOGLE_AUTH = gql`
  mutation AddGoogleAuth($organizationId: ID!) {
    addGoogleAuth(organizationId: $organizationId)
  }
`;

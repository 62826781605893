import { Editor, Transforms } from "slate";

export function runTransformOnAllChildren(
  editor: Editor,
  action: (editor: Editor) => void
) {
  // mutate editor state directly
  const { children } = editor;
  console.log(children);

  editor.selection = {
    anchor: {
      path: [0, 0], // not sure if it's always 2d
      offset: 0
    },
    focus: {
      path: [
        children.length - 1,
        children[children.length - 1].children
          ? children[children.length - 1].children.length - 1
          : 0
      ],
      offset: 100
    }
  };
  action(editor);
  editor.selection = null;
  return null;
}

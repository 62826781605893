import React from "react";
import * as queryString from "query-string";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { verifyInviteToken, completeInvite } from "../graphql/generated/types";
import styled from "@emotion/styled";
import Input from "../components/shared/InputWithShortcuts";
import Button from "../components/shared/Button";
import { useHistory } from "react-router-dom";
import SignupForm from "../components/signup/GoogleSignupForm";

const VERIFY_INVITE_TOKEN = gql`
  mutation verifyInviteToken($token: String!) {
    verifyInvite(token: $token) {
      user {
        id
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

const COMPLETE_INVITE = gql`
  mutation completeInvite($input: CompleteInviteInput!) {
    completeInvite(input: $input) {
      authToken
      error {
        message
      }
    }
  }
`;

function InvitationPage() {
  const parsed = queryString.parse(window.location.search);
  const history = useHistory();
  const [name, setName] = React.useState("");
  const [isAuthed, setIsAuthed] = React.useState(false);
  const [alreadyInOrg, setAlreadyInOrg] = React.useState(false);
  const [verifyInvite, { data, loading }] = useMutation<verifyInviteToken>(
    VERIFY_INVITE_TOKEN
  );
  const [completeInvite, { loading: completeLoading }] = useMutation<
    completeInvite
  >(COMPLETE_INVITE);
  React.useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const { token } = parsed;
    verifyInvite({
      variables: {
        token
      }
    });
  }, [verifyInvite]);

  if (loading || completeLoading) {
    return <div>Loading invitation...</div>;
  }

  const userWithOrg = data?.verifyInvite?.user!;

  if (alreadyInOrg) {
    return (
      <CenteredContainer>
        <h1>Unfortunately we only support one team per user at the moment.</h1>
        <h1>Please email eric@langapi.co and we'll assist you directly!</h1>
      </CenteredContainer>
    );
  }

  if (userWithOrg) {
    // Valid invitation link
    const handleCompleteInvite = async () => {
      // save auth data
      const result = await completeInvite({
        variables: {
          input: {
            name,
            token: parsed.token
          }
        }
      });
      if (
        result?.data?.completeInvite?.error?.message ===
        "User can only be in one org"
      ) {
        setAlreadyInOrg(true);
      } else {
        history.push("/");
      }
    };

    if (!isAuthed) {
      return (
        <CenteredContainer>
          <h1>
            {userWithOrg.name} is inviting you to join{" "}
            {userWithOrg.organization!.name}.
          </h1>
          <SignupForm onLogin={() => setIsAuthed(true)}></SignupForm>
        </CenteredContainer>
      );
    } else {
      // authenticated, ask for the name
      return (
        <CenteredContainer>
          <h1>Welcome! What should we call you?</h1>
          <Input
            autoFocus
            border={true}
            shortcuts={[]}
            style={{ marginBottom: 8 }}
            placeholder="Ex. John Smith"
            onChange={e => setName(e.target.value)}
            value={name}
          ></Input>
          <Button onClick={handleCompleteInvite}>Get started</Button>
        </CenteredContainer>
      );
    }
  } else {
    return (
      <div>
        <div>Loading invitation...</div>
      </div>
    );
  }
}

const CenteredContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;
`;

export default InvitationPage;

import React from "react";
import styled from "@emotion/styled";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Spinner } from "evergreen-ui";
import {
  getContacts,
  getContacts_getContacts_contacts,
  updateNoteContact,
  CurrentUser,
  CreateContact
} from "../../graphql/generated/types";
import {
  DARK_PRIMARY_LIGHT,
  DARK_PRIMARY_DARK,
  PRIMARY_DARK,
  PRIMARY_LIGHT
} from "../../colors";
import SimpleBar from "simplebar-react";
import ContactAvatar from "../shared/ContactAvatar";
import Input from "../shared/Input";
import AddContactModal from "../contacts/AddContactModal";
import { CURRENT_USER } from "../../graphql/queries";

const GET_CONTACTS = gql`
  query getContacts($input: GetContactsInput!) {
    getContacts(input: $input) {
      contacts {
        id
        name
        email
      }
    }
  }
`;

const UPDATE_NOTE_CONTACT = gql`
  mutation updateNoteContact($input: UpdateNoteContactInput!) {
    updateNoteContact(input: $input) {
      id
      contact {
        id
        name
      }
    }
  }
`;

type Props = {
  onContactClick: (contact: getContacts_getContacts_contacts) => void;
  note: any;
};

function ContactSelector(props: Props) {
  const { onContactClick, note } = props;
  const [searchQuery, setSearchQuery] = React.useState("");
  const [showAddContactModal, setShowAddContactModal] = React.useState(false);
  const [
    updateNoteContact,
    { data: updateData, loading: updateLoading }
  ] = useMutation<updateNoteContact>(UPDATE_NOTE_CONTACT);
  const { data: meData } = useQuery<CurrentUser>(CURRENT_USER);

  if (updateLoading) {
    return <Spinner></Spinner>;
  }

  const handleContactClick = async (
    contact: getContacts_getContacts_contacts
  ) => {
    await updateNoteContact({
      variables: {
        input: {
          note: {
            id: note.id,
            type: note.type
          },
          contactId: contact.id
        }
      }
    });
    onContactClick(contact);
  };

  const handleCreateContact = () => {
    setShowAddContactModal(true);
  };

  const handleCreateContactComplete = (data: CreateContact) => {
    const contact = data?.createContact?.contact!;
    handleContactClick(contact);
  };

  return (
    <Container>
      <Input
        autoFocus
        placeholder="Seach for a user..."
        onChange={e => setSearchQuery(e.target.value)}
        value={searchQuery}
      ></Input>
      <ContactSelectorResults
        onCreateContact={handleCreateContact}
        searchQuery={searchQuery}
        onContactClick={handleContactClick}
      ></ContactSelectorResults>
      {showAddContactModal && (
        <AddContactModal
          onCreateContact={handleCreateContactComplete}
          onHideModal={() => setShowAddContactModal(false)}
          user={meData?.me?.user!}
        ></AddContactModal>
      )}
    </Container>
  );
}

type ContactSelectorResultsProps = {
  onCreateContact: () => void;
  searchQuery: string;
  onContactClick: (contact: getContacts_getContacts_contacts) => void;
};

function ContactSelectorResults(props: ContactSelectorResultsProps) {
  const { searchQuery, onContactClick, onCreateContact } = props;
  const { data, loading } = useQuery<getContacts>(GET_CONTACTS, {
    variables: {
      input: {
        searchQuery
      }
    },
    fetchPolicy: "no-cache"
  });

  const contacts = data?.getContacts.contacts || [];
  const noContactsFound = !contacts.length && searchQuery !== "";
  const showCreateOption =
    noContactsFound ||
    (contacts.length && contacts[0].name !== searchQuery && searchQuery !== "");

  // Handling keyboard events
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    setCurrentIndex(0);
  }, [searchQuery]);

  const handleKeyPress = (event: any) => {
    const { key } = event;
    let handled = true;
    const totalLength = showCreateOption
      ? contacts.length + 1
      : contacts.length;

    if (key === "Enter") {
      // submitting
      if (showCreateOption && currentIndex === 0) {
        onCreateContact();
      } else {
        onContactClick(
          contacts[showCreateOption ? currentIndex - 1 : currentIndex]
        );
      }
    } else if (key === "ArrowDown") {
      if (currentIndex === totalLength - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(prev => prev + 1);
      }
    } else if (key === "ArrowUp") {
      if (currentIndex === 0) {
        setCurrentIndex(totalLength - 1);
      } else {
        setCurrentIndex(prev => prev - 1);
      }
    } else {
      handled = false;
    }
    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  };
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  if (loading) {
    return <Spinner></Spinner>;
  }

  const renderList = () => {
    const startIndex = showCreateOption ? 1 : 0;
    return (
      <>
        {showCreateOption && (
          <ContactItem
            active={currentIndex === 0}
            onClick={e => {
              e.stopPropagation();
              onCreateContact();
            }}
          >
            Create contact: {searchQuery}
          </ContactItem>
        )}
        {contacts.map((contact, index) => {
          const myIndex = showCreateOption ? index + startIndex : index;
          return (
            <ContactItem
              active={myIndex === currentIndex}
              key={contact.id}
              onClick={e => {
                e.stopPropagation();
                onContactClick(contact);
              }}
            >
              {contact.name}
            </ContactItem>
          );
        })}
      </>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <SimpleBar style={{ maxHeight: 400 }}>{renderList()}</SimpleBar>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContactItem = styled.div<{ active: boolean }>`
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: ${props => (props.active ? PRIMARY_DARK : PRIMARY_LIGHT)};

  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

export default ContactSelector;

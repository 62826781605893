import styled from "@emotion/styled";
import * as React from "react";
import { DARK_PRIMARY_DARK } from "../../colors";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps, TAB, UserWithProfilePicture } from "../../types";
import CommandLine from "./platform/CommandLine";
import SideBar from "./platform/SideBar";

type Props = {
  children: any;
  activeTab: TAB;
  user: UserWithProfilePicture;
} & ShortcutProps;

type State = {
  isCommandLineOpen: boolean;
};

class DashboardShell extends React.Component<Props, State> {
  state: State = {
    isCommandLineOpen: false
  };

  UNSAFE_componentWillMount() {
    // this.props.manager.bind(
    //   "command+k",
    //   () => {
    //     this.setState({
    //       isCommandLineOpen: true
    //     });
    //   },
    //   this.constructor.name,
    //   "Open command line",
    //   1
    // );
    // this.props.manager.bind(
    //   "esc",
    //   () => {
    //     this.setState({
    //       isCommandLineOpen: false
    //     });
    //   },
    //   this.constructor.name,
    //   "Close command line",
    //   1
    // );
  }

  componentWillUnmount() {
    this.props.manager.unbind("command+k", this.constructor.name);
    this.props.manager.unbind("esc", this.constructor.name);
  }

  hideCommandLine = () => {
    this.setState({
      isCommandLineOpen: false
    });
  };

  render() {
    return (
      <>
        <Container>
          <SideBar activeTab={this.props.activeTab} user={this.props.user} />
          <MainContainer>{this.props.children}</MainContainer>
        </Container>
        {this.state.isCommandLineOpen && (
          <CommandLine onClickOutside={this.hideCommandLine} />
        )}
      </>
    );
  }
}

export default withShortcuts(DashboardShell);

// TODO theme this properly
const Container = styled.div`
  display: flex;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;
  background-color: ${DARK_PRIMARY_DARK};
`;

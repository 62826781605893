import styled from "@emotion/styled";
import * as React from "react";

type Props = {
  style?: any;
};

function Tag(props: Props) {
  return (
    <Svg
      width={props.style ? props.style.width : "12"}
      height={props.style ? props.style.height : "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7741 5.63281L6.38425 0.226482C6.31284 0.154786 6.228 0.0978767 6.13457 0.0590137C6.04115 0.0201507 5.94098 9.67013e-05 5.83979 0L0.770189 0C0.344223 0 0 0.344973 0 0.770189V5.83379C0 6.03777 0.0809936 6.23426 0.225732 6.37825L5.62681 11.7741C5.69809 11.8457 5.78282 11.9025 5.87612 11.9413C5.96943 11.98 6.06947 12 6.17051 12C6.27155 12 6.3716 11.98 6.4649 11.9413C6.55821 11.9025 6.64294 11.8457 6.71422 11.7741L11.7726 6.72097C12.074 6.42099 12.0755 5.93278 11.7741 5.63281V5.63281ZM9.93147 6.43974L6.43599 9.93222C6.36567 10.0025 6.27032 10.042 6.17089 10.042C6.07146 10.042 5.9761 10.0025 5.90578 9.93222L1.49988 5.53156V1.87485C1.49988 1.7754 1.53939 1.68003 1.60971 1.60971C1.68003 1.53939 1.7754 1.49988 1.87485 1.49988H5.53606L9.93297 5.90878C10.0027 5.97962 10.0417 6.07514 10.0415 6.17457C10.0412 6.274 10.0016 6.3693 9.93147 6.43974V6.43974ZM4.12467 2.99976C3.9769 2.99981 3.83058 3.02897 3.69407 3.08556C3.55757 3.14216 3.43354 3.22509 3.32909 3.32962C3.22463 3.43414 3.14178 3.55822 3.08528 3.69477C3.02877 3.83131 2.99971 3.97765 2.99976 4.12542C2.99981 4.2732 3.02897 4.41952 3.08556 4.55602C3.14216 4.69253 3.22509 4.81655 3.32962 4.92101C3.43414 5.02547 3.55822 5.10832 3.69477 5.16482C3.83131 5.22133 3.97765 5.25038 4.12542 5.25034C4.42387 5.25024 4.71005 5.13158 4.92101 4.92048C5.13197 4.70938 5.25044 4.42312 5.25034 4.12467C5.25024 3.82623 5.13158 3.54005 4.92048 3.32909C4.70938 3.11813 4.42312 2.99966 4.12467 2.99976Z"
        fill="#838587"
      />
    </Svg>
  );
}

export default Tag;

const Svg = styled.svg``;

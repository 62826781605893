import styled from "@emotion/styled";
import * as React from "react";
import {
  PRIMARY,
  PRIMARY_LIGHT,
  SECONDARY,
  SECONDARY_LIGHT,
  DARK_PRIMARY_DARK,
  DARK_TERTIARY,
  DARK_TERTIARY_DARK
} from "../../colors";
import { GetInboxForUser_getInboxForUser_notes_IntercomNote } from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import ContactAvatar from "../shared/ContactAvatar";

const AVATAR_SIZE = 20;

type Props = {
  note: GetInboxForUser_getInboxForUser_notes_IntercomNote;
  onClick: () => any;
  active?: boolean;
} & ShortcutProps;

function IntercomNoteItem(props: Props) {
  const { note } = props;
  const { contact } = note;
  return (
    <Container onClick={props.onClick}>
      <TitleRow>
        <ContactAvatar size={AVATAR_SIZE}></ContactAvatar>
        <ContactName>{contact ? contact.name : "Anonymous"} </ContactName>
      </TitleRow>
      <Content>{note.title}</Content>
    </Container>
  );
}

export default withShortcuts(IntercomNoteItem);

const Container = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  background-color: ${DARK_PRIMARY_DARK};
  cursor: pointer;
  ${props => {
      if (props.active) {
        return `
        border-left: 2px solid ${DARK_TERTIARY_DARK};
      
      `;
      }
    }}
    :hover {
    background-color: ${PRIMARY_LIGHT};
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const ContactName = styled.div`
  color: "white";
  padding-left: 4px;
`;

const Content = styled.div`
  padding-left: 32px;
  color: ${SECONDARY_LIGHT};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

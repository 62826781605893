import { isProd } from "./index";

export function getBackendGraphqlUrl() {
  return isProd()
    ? "https://lang-omnition-backend.herokuapp.com/graphql"
    : "http://localhost:4000/graphql";
}

export function getBackendSubscriptionsUrl() {
  return isProd()
    ? "wss://lang-omnition-backend.herokuapp.com/graphql"
    : "ws://localhost:4000/graphql";
}

export function getFrontendUrl() {
  return isProd()
    ? "https://lang-omnition-web.onrender.com"
    : "http://localhost:3000";
}

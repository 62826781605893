import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_CONTACT_BY_ID } from "../../graphql/queries";
import {
  GetContactById,
  AddContactProperty,
  UpdateContactProperty,
  DeleteContactProperty
} from "../../graphql/generated/types";
import { Spinner } from "evergreen-ui";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import styled from "@emotion/styled";
import { DARK_PRIMARY_LIGHT, DARK_PRIMARY } from "../../colors";
import gql from "graphql-tag";
import Input from "../shared/Input";
import produce from "immer";

const ADD_CONTACT_PROPERTY = gql`
  mutation AddContactProperty($input: AddContactPropertyInput!) {
    addContactProperty(input: $input) {
      property {
        id
        name
        value
      }
      contact {
        id
        properties {
          id
          name
          value
        }
      }
    }
  }
`;

const UPDATE_CONTACT_PROPERTY = gql`
  mutation UpdateContactProperty($input: UpdateContactPropertyInput!) {
    updateContactProperty(input: $input) {
      property {
        id
        name
        value
      }
    }
  }
`;

const DELETE_CONTACT_PROPERTY = gql`
  mutation DeleteContactProperty($input: DeleteContactPropertyInput!) {
    deleteContactProperty(input: $input) {
      error {
        message
      }
    }
  }
`;

type Props = {
  contactId: string;
};

function ContactProperties(props: Props) {
  const { contactId } = props;
  const [currentText, setCurrentText] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);
  const [isEditingName, setIsEditingName] = React.useState(false);
  const [currentPropertyId, setCurrentPropertyId] = React.useState("");
  const [hoveredId, setHoveredId] = React.useState("");
  const [newPropertyAdded, setNewPropertyAdded] = React.useState(false);
  const { data, loading } = useQuery<GetContactById>(GET_CONTACT_BY_ID, {
    variables: {
      id: contactId
    }
  });
  const lastNameInputRef = React.useRef<HTMLInputElement>(null);
  const [addContactProperty, { loading: addPropertyLoading }] = useMutation<
    AddContactProperty
  >(ADD_CONTACT_PROPERTY);
  const [updateContactProperty, { loading: updateLoading }] = useMutation<
    UpdateContactProperty
  >(UPDATE_CONTACT_PROPERTY);
  const [deleteContactProperty] = useMutation<DeleteContactProperty>(
    DELETE_CONTACT_PROPERTY
  );

  const handleKeyPress = async (event: any) => {
    const { key } = event;
    let handled = true;
    if (key === "Tab") {
      // submitting
      if (isEditing && isEditingName) {
        saveEdit();
        setIsEditingName(false);
      }
    } else if (key === "Enter" && isEditing) {
      saveAndClearEdit();
    } else {
      handled = false;
    }
    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  };
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  if (loading) {
    return <Spinner></Spinner>;
  }

  const contact = data?.getContactById!;
  const properties = contact.properties;

  const handleAddProperty = async () => {
    addContactProperty({
      variables: {
        input: {
          contactId
        }
      },
      optimisticResponse: {
        addContactProperty: {
          __typename: "AddContactPropertyPayload",
          property: {
            __typename: "ContactProperty",
            id: "GENERATED",
            name: "",
            value: ""
          },
          contact: {
            ...contact
          }
        }
      },
      update: (cache, result) => {
        const property = result?.data?.addContactProperty?.property!;
        const data = cache.readQuery<GetContactById>({
          query: GET_CONTACT_BY_ID,
          variables: { id: contactId }
        });
        const nextState = produce(data, draftState => {
          if (
            !draftState!.getContactById.properties
              .map(p => p.id)
              .includes(property.id)
          ) {
            draftState!.getContactById.properties.push(property);
          }
          setIsEditingName(true);
          setCurrentPropertyId(property.id);
          setIsEditing(true);
        });
        cache.writeQuery({
          query: GET_CONTACT_BY_ID,
          variables: { id: contactId },
          data: nextState
        });
      }
    });
  };

  const saveEdit = () => {
    const currentProperty = properties.find(
      property => property.id === currentPropertyId
    )!;
    updateContactProperty({
      variables: {
        input: {
          id: currentPropertyId,
          ...(isEditingName ? { name: currentText } : { value: currentText })
        }
      },
      optimisticResponse: {
        updateContactProperty: {
          __typename: "UpdateContactPropertyPayload",
          property: {
            ...currentProperty,
            ...(isEditingName ? { name: currentText } : { value: currentText })
          }
        }
      }
    });
  };

  const clearEdit = () => {
    setIsEditing(false);
    setCurrentText("");
  };

  const saveAndClearEdit = () => {
    saveEdit();
    clearEdit();
  };

  const handleClickDelete = async (propertyId: string) => {
    await deleteContactProperty({
      variables: {
        input: {
          id: propertyId
        }
      },
      optimisticResponse: {
        deleteContactProperty: {
          __typename: "DeleteContactPropertyPayload",
          error: null
        }
      },
      update: (cache, result) => {
        const data = cache.readQuery<GetContactById>({
          query: GET_CONTACT_BY_ID,
          variables: { id: contactId }
        });
        const nextState = produce(data, draftState => {
          draftState!.getContactById.properties = draftState?.getContactById.properties.filter(
            prop => prop.id !== propertyId
          )!;
        });
        cache.writeQuery({
          query: GET_CONTACT_BY_ID,
          variables: { id: contactId },
          data: nextState
        });
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {properties.map((property, index) => {
        const { id, name, value } = property;
        return (
          <ContactPropertyItem
            key={id}
            onMouseEnter={() => {
              setHoveredId(id);
            }}
            onMouseLeave={() => {
              setHoveredId("");
            }}
          >
            <TrashContainer
              hover={hoveredId === id}
              onClick={() => {
                handleClickDelete(id);
              }}
            >
              <FiTrash2 size={16}></FiTrash2>
            </TrashContainer>
            {isEditing && isEditingName && currentPropertyId === id ? (
              <Input
                onFocus={() => setCurrentText(name)}
                style={{ flex: 1 }}
                onBlur={saveAndClearEdit}
                autoFocus
                onChange={e => setCurrentText(e.target.value)}
                value={currentText}
                forwardRef={
                  index === properties.length - 1 ? lastNameInputRef : undefined
                }
              ></Input>
            ) : (
              <ContactPropertyName
                empty={name === ""}
                onClick={() => {
                  setIsEditing(true);
                  setIsEditingName(true);
                  setCurrentPropertyId(id);
                  setCurrentText(name);
                }}
              >
                {name ? name : "Empty"}
              </ContactPropertyName>
            )}
            {isEditing && !isEditingName && currentPropertyId === id ? (
              <Input
                onFocus={() => setCurrentText(value)}
                style={{ flex: 3 }}
                onBlur={saveAndClearEdit}
                autoFocus
                onChange={e => setCurrentText(e.target.value)}
                value={currentText}
              ></Input>
            ) : (
              <ContactPropertyValue
                empty={value === ""}
                onClick={() => {
                  setIsEditing(true);
                  setIsEditingName(false);
                  setCurrentPropertyId(id);
                  setCurrentText(value);
                }}
              >
                {value ? value : "Empty"}
              </ContactPropertyValue>
            )}
          </ContactPropertyItem>
        );
      })}
      <ContactPropertyItem>
        <AddPropertyContainer onClick={handleAddProperty}>
          <FiPlus style={{ marginLeft: -2 }}></FiPlus>
          <div>Add a Property</div>
        </AddPropertyContainer>
        <div style={{ flex: 3 }}></div>
      </ContactPropertyItem>
    </div>
  );
}

const TrashContainer = styled.div<{ hover?: boolean }>`
  position: absolute;
  top: 50%;
  margin-top: -12px;
  padding: 4px;
  border-radius: 4px;
  margin-left: -28px;
  color: ${DARK_PRIMARY};
  ${props =>
      props.hover &&
      `
      color: #adafaf;
    background-color: ${DARK_PRIMARY_LIGHT};
    cursor: pointer;
  
  `}
    :hover {
    color: #adafaf;
    background-color: ${DARK_PRIMARY_LIGHT};
    cursor: pointer;
  }
`;

const AddPropertyContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: #8a8d8e;
  padding: 8px 12px;
  cursor: pointer;
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

const ContactPropertyName = styled.div<{ empty?: boolean }>`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 12px;
  cursor: pointer;
  color: #adafaf;
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

const ContactPropertyValue = styled.div<{ empty?: boolean }>`
  flex: 3;
  padding: 8px 12px;
  cursor: pointer;
  color: ${props => (props.empty ? "#8a8d8e" : "white")};
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

const ContactPropertyItem = styled.div`
  display: flex;
  position: relative;
`;

export default ContactProperties;

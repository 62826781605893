import { useMutation, useQuery } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import * as React from "react";
import { useState } from "react";
import {
  DARK_PRIMARY_DARK,
  DARK_SECONDARY_LIGHT,
  PRIMARY,
  SECONDARY,
  SECONDARY_LIGHT
} from "../../colors";
import {
  CurrentUserWithSettings,
  CurrentUser_me_user
} from "../../graphql/generated/types";
import { UPDATE_ORGANIZATION, UPDATE_USER } from "../../graphql/mutations";
import { CURRENT_USER_WITH_SETTINGS } from "../../graphql/queries";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import Button from "../shared/Button";
import Input from "../shared/InputWithShortcuts";
import Integrations from "./Integrations";
import InviteTeammateLink from "./InviteTeammateLink";

type Props = {
  user: CurrentUser_me_user;
} & ShortcutProps;

function Settings(props: Props) {
  const [userName, setUserName] = useState<string>(props.user.name || "");
  const [teamName, setTeamName] = useState<string>(
    props.user.organization?.name || ""
  );
  const [email, setEmail] = useState<string>(props.user.email || "");
  const [profilePicture, setProfilePicture] = useState<File | null>(null);

  const { data: userData, loading: userLoading } = useQuery<
    CurrentUserWithSettings
  >(CURRENT_USER_WITH_SETTINGS);
  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER);
  const [
    updateOrganization,
    { loading: updateOrganizationLoading }
  ] = useMutation(UPDATE_ORGANIZATION);

  const saveUserSettings = async (event?: KeyboardEvent) => {
    await updateUser({
      variables: {
        userId: props.user.id,
        input: {
          file: profilePicture || undefined,
          name: userName,
          email: email
        }
      }
    });
    props.setNotification({
      notification: "Saved user settings!",
      timestamp: Date.now()
    });
    event?.preventDefault();
  };

  const saveTeamSettings = async (event?: KeyboardEvent) => {
    if (!props.user.organization) {
      return;
    }
    await updateOrganization({
      variables: {
        organizationId: props.user.organization.id || "",
        input: {
          name: teamName
        }
      }
    });
    props.setNotification({
      notification: "Saved team settings!",
      timestamp: Date.now()
    });
    event?.preventDefault();
  };

  if (userLoading) {
    return <Spinner></Spinner>;
  }

  const user = userData?.me?.user!;

  return (
    <Container>
      <SectionTitle>Profile</SectionTitle>
      <SectionContent>
        <SectionContentSubtitle>Name</SectionContentSubtitle>
        <StyledInput
          value={userName}
          shortcuts={[{ keys: "mod+s", callback: saveUserSettings }]}
          onChange={event => setUserName(event.target.value)}
          placeholder="Name"
        />
        <SectionContentSubtitle style={{ marginTop: "20px" }}>
          Email
        </SectionContentSubtitle>
        <StyledInput
          value={email}
          shortcuts={[{ keys: "mod+s", callback: saveUserSettings }]}
          onChange={event => setEmail(event.target.value)}
          placeholder="Email"
        />
        <SectionContentSubtitle style={{ marginTop: "20px" }}>
          Profile Picture
        </SectionContentSubtitle>
        <StyledInput
          shortcuts={[{ keys: "mod+s", callback: saveUserSettings }]}
          type="file"
          onChange={event => {
            if (event?.target?.files?.length) {
              setProfilePicture(event.target.files[0]);
            }
          }}
        />
        <StyledButton onClick={saveUserSettings}>Save</StyledButton>
      </SectionContent>
      <Separator />
      <SectionTitle>Team</SectionTitle>
      <SectionContent>
        <SectionContentSubtitle>Name</SectionContentSubtitle>
        <StyledInput
          value={teamName}
          shortcuts={[{ keys: "mod+s", callback: saveTeamSettings }]}
          onChange={event => setTeamName(event.target.value)}
          placeholder="Name"
        />
        <SectionContentSubtitle style={{ marginTop: "20px" }}>
          Teammates
        </SectionContentSubtitle>
        {user.organization?.users.map(user => {
          return (
            <UserRow key={user.id}>
              <UserRowUserName>{user.name}</UserRowUserName>
              <UserRowUserEmail>{user.email}</UserRowUserEmail>
            </UserRow>
          );
        })}
        <SectionContentSubtitle style={{ marginTop: 20 }}>
          To add more teammates, share the link below:
        </SectionContentSubtitle>
        <InviteTeammateLink />
        <StyledButton onClick={saveTeamSettings}>Save</StyledButton>
      </SectionContent>
      <Separator />
      <SectionTitle>Integrations</SectionTitle>
      <SectionContent>
        <Integrations organization={user!.organization!} />
      </SectionContent>
    </Container>
  );
}

export default withShortcuts(Settings);

const UserRow = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const UserRowUserName = styled.div`
  color: ${DARK_SECONDARY_LIGHT};
`;

const UserRowUserEmail = styled.div`
  color: ${DARK_SECONDARY_LIGHT};
  margin-left: 40px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  max-width: 600px;
  width: 100%;
  height: 100%;
`;

const Separator = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  height: 1px;
  background-color: ${PRIMARY};
`;

const SectionTitle = styled.div`
  font-size: 20px;
  color: ${SECONDARY_LIGHT};
  margin-bottom: 20px;
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContentSubtitle = styled.div`
  color: ${SECONDARY};
  margin-bottom: 6px;
`;

const StyledInput = styled(Input)`
  background-color: ${DARK_PRIMARY_DARK};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-top: 20px;
  align-self: flex-start;
`;

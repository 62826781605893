import React from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory, Redirect } from "react-router-dom";
import { CURRENT_USER } from "../../graphql/queries";
import produce from "immer";
import { CurrentUser } from "../../graphql/generated/types";
import styled from "@emotion/styled";
import Input from "../shared/InputWithShortcuts";
import Button from "../shared/Button";
import { Spinner } from "evergreen-ui";

const COMPLETE_WELCOME = gql`
  mutation completeWelcomeForNewUser($input: CompleteWelcomeInput!) {
    completeWelcomeForNewUser(input: $input) {
      error {
        message
      }
      # Newly created organization
      organization {
        id
        name
        contacts {
          id
        }
      }
      # Updated user with name
      user {
        name
      }
    }
  }
`;

function WelcomePage(props: any) {
  const [organizationName, setOrganizationName] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameCompleted, setNameCompleted] = React.useState(false);
  const [completeWelcome, { loading }] = useMutation(COMPLETE_WELCOME, {
    update: (cache, result) => {
      const {
        data: {
          completeWelcomeForNewUser: { user, organization }
        }
      } = result;
      // Update apollo cache for current_user query
      const meData = cache.readQuery({ query: CURRENT_USER }) as CurrentUser;
      const nextState = produce(meData, draftState => {
        draftState!.me!.user!.name = user.name;
        draftState!.me!.user!.organization = organization;
      });
      cache.writeQuery({
        query: CURRENT_USER,
        data: nextState
      });
    }
  });
  const { data: meData } = useQuery(CURRENT_USER);
  const history = useHistory();

  if (meData?.me?.user?.organization) {
    // Already onboarded, skip this welcome
    return <Redirect to="/"></Redirect>;
  }

  if (loading) {
    return (
      <div>
        <Spinner></Spinner>
      </div>
    );
  }

  const handleSubmit = async () => {
    await completeWelcome({
      variables: {
        input: {
          organizationName,
          name
        }
      }
    });
    return history.push("/");
  };

  return (
    <CenteredContainer>
      {!nameCompleted ? (
        <>
          <Header>Welcome! What should we call you?</Header>

          <Input
            autoFocus
            border={true}
            shortcuts={[]}
            style={{ marginBottom: 8 }}
            placeholder="Ex. John Smith"
            onChange={e => setName(e.target.value)}
            value={name}
          ></Input>
          <Button onClick={() => setNameCompleted(true)}>Next</Button>
        </>
      ) : (
        <>
          <Header>What's the name of your company or team?</Header>
          <Input
            border={true}
            autoFocus
            shortcuts={[]}
            style={{ marginBottom: 8 }}
            type="text"
            onChange={e => setOrganizationName(e.target.value)}
            value={organizationName}
          />
          <Button onClick={handleSubmit}>Finish</Button>
        </>
      )}
    </CenteredContainer>
  );
}

const CenteredContainer = styled.div`
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 200px;
`;

const Header = styled.h1``;

export default WelcomePage;

import ShortcutManager from "./shortcuts/ShortcutManager";

export enum TAB {
  HOME = "HOME",
  CONTACTS = "CONTACTS",
  INSIGHTS = "INSIGHTS",
  SETTINGS = "SETTINGS"
}

export type ShortcutProps = {
  manager: ShortcutManager;
  notification: string | null;
  notificationTimestamp: number;
  setNotification: (notificationAndTimestamp: {
    notification: string;
    timestamp: number;
  }) => any;
};

export type User = {
  id: string;
};

export type Domain = string;

export type Company = {
  id: string;
  name: string;
  domain: Domain;
};

export type Filter = {
  id: string;
  user: User | null;
  name: string;
  count: number;
};

export enum CREATE_CONTACT_ERROR {
  EMAIL = "EMAIL"
}

export enum SEND_DRAFT_ERROR {
  EMAIL = "EMAIL"
}

export enum CREATE_TEMPLATE_ERROR {
  NAME = "NAME"
}

export enum HOTKEYS {
  ENTER = "enter",
  TAB = "tab",
  SHIFT_TAB = "shift+tab",
  CMD_B = "mod+b",
  CMD_I = "mod+i",
  CMD_U = "mod+u",
  CMD_ALT_1 = "mod+alt+1",
  CMD_ALT_2 = "mod+alt+2",
  CMD_ALT_3 = "mod+alt+3",
  CMD_H = "mod+h"
}

export interface UserWithProfilePicture {
  id: string;
  profilePictureUrl: string | null;
}

export enum IntegrationTypes {
  INTERCOM = "INTERCOM",
  GMAIL = "GMAIL"
}

export enum NoteTypes {
  INTERCOM_NOTE = "INTERCOM_NOTE",
  MANUAL = "MANUAL"
}

export enum HighlightableTypes {
  INTERCOM_MESSAGE = "INTERCOM_MESSAGE"
}

export type ContactsFilter = {
  search: string;
};

import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Switch } from "evergreen-ui";
import gql from "graphql-tag";
import React from "react";

const CREATE_INTERCOM_LINK = gql`
  mutation createIntercomOAuthLink {
    createIntercomOAuthLink {
      error {
        message
      }
      link
    }
  }
`;

const Logo = () => (
  <svg viewBox="0 0 36 36" style={{ width: 36, height: 36 }}>
    <path
      d="M31.5 0H4.497A4.499 4.499 0 0 0 0 4.5v27C0 33.985 2.014 36 4.498 36H31.5A4.5 4.5 0 0 0 36 31.5v-27A4.5 4.5 0 0 0 31.5 0zm-8.7 6.6a1.199 1.199 0 1 1 2.399 0v16.031a1.199 1.199 0 1 1-2.399 0V6.601zm-6.002-.608a1.2 1.2 0 0 1 2.401 0v17.4a1.2 1.2 0 1 1-2.4 0v-17.4zM10.8 6.6c0-.664.538-1.2 1.2-1.2A1.2 1.2 0 0 1 13.2 6.6v16.031a1.2 1.2 0 0 1-2.4 0V6.601zM4.8 9a1.2 1.2 0 0 1 2.4 0v10.794a1.199 1.199 0 1 1-2.4 0V9zm25.983 18.306c-.187.157-4.631 3.888-12.782 3.888-8.15 0-12.597-3.73-12.782-3.888a1.2 1.2 0 1 1 1.56-1.825c.07.058 4.04 3.312 11.222 3.312 7.27 0 11.18-3.278 11.218-3.31a1.2 1.2 0 1 1 1.564 1.823zm.418-7.512a1.2 1.2 0 0 1-2.4 0V9a1.2 1.2 0 0 1 2.4 0v10.794z"
      fill="#1F8DED"
    ></path>
  </svg>
);

type Props = {
  activated: boolean;
};

function IntercomIntegration(props: Props) {
  const { activated } = props;
  const [createIntercomLink, { data, loading, error }] = useMutation(
    CREATE_INTERCOM_LINK
  );

  const handleConnectIntercom = async () => {
    const result = await createIntercomLink();
    const link = result?.data?.createIntercomOAuthLink?.link;
    window.location.href = link;
  };

  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Logo></Logo>
        <div
          style={{ display: "flex", flexDirection: "column", paddingLeft: 8 }}
        >
          <div style={{ fontWeight: 700 }}>Intercom</div>
          <div style={{ fontSize: "0.9em" }}>
            Connect your Intercom conversations for more feedback.
          </div>
        </div>
      </div>
      <div>
        <Switch
          checked={activated}
          disabled={activated}
          height={24}
          onChange={handleConnectIntercom}
        />
      </div>
    </Container>
  );
}

export default IntercomIntegration;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

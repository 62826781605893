import styled from "@emotion/styled";

export default styled.div`
  background-color: rgb(70, 70, 74);
  border-radius: 4px;
  color: rgb(187, 188, 190);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router";
import SimpleBar from "simplebar-react";
import { DARK_PRIMARY } from "../../colors";
import {
  CreateManualNote,
  CurrentUserWithContact_me_user,
  CurrentUserWithContact_me_user_organization_contact_manualNotes
} from "../../graphql/generated/types";
import { CREATE_MANUAL_NOTE } from "../../graphql/mutations";
import { CURRENT_USER_WITH_CONTACT } from "../../graphql/queries";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import Note from "../notes/Note";
import ErrorShowIntercom from "../shared/ErrorShowIntercom";
import ContactActivityLog from "./ContactActivityLog";
import ContactProfile from "./ContactProfile";

type Props = {
  user: CurrentUserWithContact_me_user;
} & RouteComponentProps &
  ShortcutProps;

function Contact(props: Props) {
  const [currentNoteId, setCurrentNoteId] = useState<string>("");
  const [nextCurrentNoteId, setNextCurrentNoteId] = useState<string>("");
  const [intercomMessageId, setIntercomMessageId] = useState<string>("");
  const [createManualNote, { loading: createManualNoteLoading }] = useMutation<
    CreateManualNote
  >(CREATE_MANUAL_NOTE);

  const createNote = async () => {
    const payload = await createManualNote({
      variables: {
        input: {
          title: "New Note",
          contactId: props.user.organization?.contact?.id,
          content: `[{"type":"paragraph","children":[{"text":""}]}]`,
          tagIds: []
        }
      },
      refetchQueries: [
        {
          query: CURRENT_USER_WITH_CONTACT,
          variables: { id: props.user.organization?.contact?.id || "" }
        }
      ],
      awaitRefetchQueries: true
    });

    const noteId = payload.data?.createManualNote.note?.id as string;
    setNextCurrentNoteId(noteId);
    setCurrentNoteId("");
    setIntercomMessageId("");
  };

  useEffect(() => {
    props.manager.bind(
      "esc",
      () => props.history.goBack(),
      "Contact",
      "Go back to contacts list",
      2
    );
    props.manager.bind("n", createNote, "Contact", "Create new interview", 1);

    return () => {
      props.manager.unbind("esc", "Contact");
      props.manager.unbind("n", "Contact");
    };
  });

  useEffect(() => {
    setCurrentNoteId(nextCurrentNoteId);
  }, [nextCurrentNoteId]);

  const openNote = (id: string) => {
    setCurrentNoteId("");
    setIntercomMessageId("");
    setNextCurrentNoteId(id);
  };

  const openIntercomMessage = (id: string) => {
    setCurrentNoteId("");
    setIntercomMessageId("");
    setIntercomMessageId(id);
  };

  if (!props.user.organization?.contact) {
    return <ErrorShowIntercom></ErrorShowIntercom>;
  }

  let mainContent = (
    <ContactProfile contact={props.user.organization.contact} />
  );

  if (currentNoteId) {
    const note = props.user.organization.contact.manualNotes.find(note => {
      return note.id === currentNoteId;
    }) as CurrentUserWithContact_me_user_organization_contact_manualNotes;
    mainContent = (
      <SimpleBar
        style={{
          height: "100%"
        }}
      >
        <Note id={note.id}></Note>
      </SimpleBar>
    );
  } else if (intercomMessageId) {
    mainContent = <Note id={intercomMessageId} />;
  }

  return (
    <Container>
      <ContactActivityLog
        user={props.user}
        contact={props.user.organization.contact}
        openNote={openNote}
        openIntercomMessage={openIntercomMessage}
      />
      <ContentContainer>{mainContent}</ContentContainer>
    </Container>
  );
}

export default withRouter(withShortcuts(Contact));

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: ${DARK_PRIMARY};
`;

import { useQuery } from "@apollo/react-hooks";
import React from "react";
import Inbox from "../components/inbox/Inbox";
import InboxFilterProvider from "../components/inbox/InboxFilterProvider";
import DashboardShell from "../components/shared/DashboardShell";
import { CurrentUser } from "../graphql/generated/types";
import { CURRENT_USER } from "../graphql/queries";
import { TAB } from "../types";
import ErrorShowIntercom from "../components/shared/ErrorShowIntercom";

// fetch notes here

function InboxPage() {
  const { data: meData } = useQuery<CurrentUser>(CURRENT_USER);
  const user = meData?.me?.user;

  if (!user) {
    return <ErrorShowIntercom></ErrorShowIntercom>;
  }

  return (
    <DashboardShell activeTab={TAB.HOME} user={user}>
      <InboxFilterProvider>
        <Inbox user={user} />
      </InboxFilterProvider>
    </DashboardShell>
  );
}

export default InboxPage;

export const DARK_PRIMARY_DARK = "rgb(42, 46, 48)";
export const DARK_PRIMARY = "rgb(48, 52, 55)";
export const DARK_PRIMARY_LIGHT = "rgb(67, 67, 70)";
export const DARK_SECONDARY_DARK = "rgb(131, 133, 135)";
export const DARK_SECONDARY = "rgb(172, 174, 175)";
export const DARK_SECONDARY_LIGHT = "rgb(234, 234, 235)";
export const DARK_TERTIARY_DARK = "rgb(149, 126, 197)";
export const DARK_TERTIARY = "rgb(191, 169, 237)";
export const DARK_TERTIARY_LIGHT = "rgb(228, 215, 255)";
// DEPRECATED
export const PRIMARY_DARK = "rgb(42, 46, 48)";
export const PRIMARY = "rgb(48, 52, 55)";
export const PRIMARY_LIGHT = "rgb(67, 67, 70)";
export const SECONDARY_DARK = "rgb(131, 133, 135)";
export const SECONDARY = "rgb(172, 174, 175)";
export const SECONDARY_LIGHT = "rgb(234, 234, 235)";
export const TERTIARY_DARK = "rgb(149, 126, 197)";
export const TERTIARY = "rgb(191, 169, 237)";
export const TERTIARY_LIGHT = "rgb(228, 215, 255)";

export const DARK_WARNING = "rgb(255, 99, 99)";
import { ApolloProvider } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import WelcomePage from "../src/components/welcome/WelcomePage";
import { DARK_PRIMARY_DARK } from "./colors";
import AuthBrowserPage from "./components/AuthBrowserPage";
import AuthPage from "./components/AuthPage";
import AuthRoute from "./components/routes/AuthRoute";
import OnboardedRoute from "./components/routes/OnboardedRoute";
import Cheatsheet from "./components/shared/platform/Cheatsheet";
import Notification from "./components/shared/platform/Notification";
import ContactPage from "./containers/ContactPage";
import ContactsPage from "./containers/ContactsPage";
import InboxPage from "./containers/InboxPage";
import InsightPage from "./containers/InsightPage";
import InsightsPage from "./containers/InsightsPage";
import InvitationPage from "./containers/InvitationPage";
import SettingsPage from "./containers/SettingsPage";
import SharedInsightPage from "./containers/SharedInsightPage";
import introspectionQueryResultData from "./graphql/generated/fragmentTypes.json";
import ThemeProvider from "./providers/ThemeProvider";
import ShortcutContext from "./shortcuts/shortcutContext";
import ShortcutManager from "./shortcuts/ShortcutManager";
import { getBackendGraphqlUrl, getBackendSubscriptionsUrl } from "./utils/urls";

const httpLink = new HttpLink({
  uri: getBackendGraphqlUrl()
});
const wsLink = new WebSocketLink({
  uri: getBackendSubscriptionsUrl(),
  options: {
    reconnect: true
  }
});
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("omnitionauthtoken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const uploadLink = createUploadLink({
  uri: getBackendGraphqlUrl(),
  credentials: "same-origin"
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          // don't remove this log
          console.error(
            `[GraphQL error]: Message: ${JSON.stringify(
              message,
              null,
              2
            )}, Location: ${JSON.stringify(
              locations,
              null,
              2
            )}, Path: ${JSON.stringify(path, null, 2)}`
          )
        );
      // don't remove this log
      if (networkError)
        console.error(
          `[Network error]: ${JSON.stringify(networkError, null, 2)}`
        );
    }),
    authLink,
    link,
    uploadLink
  ]),
  cache: new InMemoryCache({ fragmentMatcher })
});

const shortcutManager = new ShortcutManager();

const App: React.FC = () => {
  const [notification, setNotification] = useState<string | null>(null);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [showCheatsheet, setShowCheatsheet] = useState<boolean>(false);

  useEffect(() => {
    shortcutManager.bind(
      "?",
      () => {
        if (showCheatsheet) {
          setShowCheatsheet(false);
        } else {
          setShowCheatsheet(true);
        }
      },
      "App",
      "Show / hide Shortcuts",
      1
    );
    return () => {
      shortcutManager.unbind("?", "App");
    };
  });

  const setNotificationAndTimestamp = (notificationAndTimestamp: {
    notification: string;
    timestamp: number;
  }) => {
    setNotification(notificationAndTimestamp.notification);
    setTimestamp(notificationAndTimestamp.timestamp);
  };

  return (
    <AppContainer>
      <ShortcutContext.Provider
        value={{
          manager: shortcutManager,
          notification: notification,
          notificationTimestamp: timestamp,
          setNotification: setNotificationAndTimestamp
        }}
      >
        <ApolloProvider client={client}>
          <ThemeProvider>
            <BrowserRouter>
              <Routes></Routes>
            </BrowserRouter>
          </ThemeProvider>
          <Notification />
          {showCheatsheet && (
            <Cheatsheet hideCheatsheet={() => setShowCheatsheet(false)} />
          )}
        </ApolloProvider>
      </ShortcutContext.Provider>
    </AppContainer>
  );
};

const Routes: React.FC = () => {
  const location = useLocation();
  React.useEffect(() => {
    (window as any).Intercom("update");
  }, [location]);
  return (
    <Switch>
      <OnboardedRoute exact path="/person" component={ContactPage as any} />
      <OnboardedRoute exact path="/people" component={ContactsPage as any} />
      <OnboardedRoute exact path="/insights" component={InsightsPage as any} />
      <OnboardedRoute path="/settings" component={SettingsPage as any} />
      <AuthRoute exact path="/welcome" component={WelcomePage} />
      <OnboardedRoute exact path="/insight" component={InsightPage} />
      <Route exact path="/login" component={AuthPage} />
      <Route eact path="/authbrowser" component={AuthBrowserPage} />
      <Route exact path="/invitations" component={InvitationPage} />
      <Route exact path="/shared-insight" component={SharedInsightPage} />
      <OnboardedRoute path="/" component={InboxPage as any} />
    </Switch>
  );
};

export default App;

const AppContainer = styled.div`
  display: flex;
  max-height: 100vh;
  max-width: 100vw;
  width: 100%;
  background-color: ${DARK_PRIMARY_DARK};
  overflow-x: hidden;
  justify-content: center;
  color: white;
`;

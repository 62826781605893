import styled from "@emotion/styled";
import * as React from "react";
import { Node } from "slate";
import {
  PRIMARY,
  PRIMARY_LIGHT,
  SECONDARY_LIGHT,
  DARK_PRIMARY_DARK,
  DARK_TERTIARY_DARK
} from "../../colors";
import {
  CurrentUserWithContact_me_user_organization_contact,
  CurrentUserWithContact_me_user_organization_contact_manualNotes
} from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import ContactAvatar from "../shared/ContactAvatar";

const AVATAR_SIZE = 20;

type Props = {
  contact: CurrentUserWithContact_me_user_organization_contact;
  note: CurrentUserWithContact_me_user_organization_contact_manualNotes;
  openNote: () => any;
  active?: boolean;
} & ShortcutProps;

function NoteItem(props: Props) {
  const { note, contact, active } = props;

  const rightContact = (note as any)?.contact || contact;

  return (
    <Container onClick={props.openNote} active={active}>
      <TitleRow>
        <ContactAvatar
          contact={rightContact}
          size={AVATAR_SIZE}
        ></ContactAvatar>
        <ContactName>
          {rightContact ? rightContact.name : "Anonymous"}
        </ContactName>
      </TitleRow>
      <Content>{note.title}</Content>
    </Container>
  );
}

export default withShortcuts(NoteItem);

const Container = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  background-color: ${DARK_PRIMARY_DARK};
  ${props => {
    if (props.active) {
      return `
        border-left: 4px solid ${DARK_TERTIARY_DARK};
      
      `;
    }
  }}
  cursor: pointer;
  :hover {
    background-color: ${PRIMARY_LIGHT};
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const ContactName = styled.div`
  color: "white";
  padding-left: 4px;
`;

const Content = styled.div`
  padding-left: 32px;
  color: ${SECONDARY_LIGHT};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { Spinner } from "evergreen-ui";
import gql from "graphql-tag";
import React from "react";
import { FiX } from "react-icons/fi";
import SimpleBar from "simplebar-react";
import {
  DARK_PRIMARY_DARK,
  DARK_PRIMARY_LIGHT,
  DARK_TERTIARY_DARK
} from "../../colors";
import {
  AddTagToHighlight,
  AddTagToManualNote,
  CreateTag,
  GetHighlightById,
  RemoveTagFromHighlight,
  RemoveTagFromNoteIfNoOccurrences
} from "../../graphql/generated/types";
import {
  ADD_TAG_TO_MANUAL_NOTE,
  CREATE_TAG,
  REMOVE_TAG_FROM_NOTE_IF_NO_OCCURRENCES
} from "../../graphql/mutations";
import { HighlightableTypes, NoteTypes } from "../../types";
import { Highlight } from "./HighlightableText";
import HighlightModalTag from "./HighlightModalTag";
import HighlightModalTagResults from "./HighlightModalTagResults";
import Input from "./Input";

const ADD_TAG_TO_HIGHLIGHT = gql`
  mutation AddTagToHighlight($input: AddTagToHighlightInput!) {
    addTagToHighlight(input: $input) {
      error {
        message
      }
      highlight {
        id
        content
        startOffset
        endOffset
        tags {
          id
          name
        }
      }
    }
  }
`;
const GET_HIGHLIGHT_BY_ID = gql`
  query GetHighlightById($id: ID!) {
    getHighlightById(id: $id) {
      highlight {
        id
        content
        tags {
          id
          name
        }
      }
    }
  }
`;

const REMOVE_TAG_FROM_HIGHLIGHT = gql`
  mutation RemoveTagFromHighlight($input: RemoveTagFromHighlightInput!) {
    removeTagFromHighlight(input: $input) {
      error {
        message
      }
      highlightDeleted
      highlight {
        id
        tags {
          id
          name
        }
      }
    }
  }
`;

type HighlightModalProps = {
  highlight: any; // the format differs still a little betweeen manual  note vs. everything else.... should standardize this next time
  targetType: string;
  targetId: string;
  onCreateNewHighlight: (highlight: Highlight) => void;
  onClose: () => void;
  onAddTag?: (tagId: string) => void; // manuaul note
  onRemoveTag?: (tagId: string) => void;
};

function HighlightModal(props: HighlightModalProps) {
  const {
    highlight,
    targetType,
    targetId,
    onCreateNewHighlight,
    onClose,
    onAddTag,
    onRemoveTag
  } = props;
  const [searchQuery, setSearchQuery] = React.useState("");
  const [
    addTagToManualNote,
    { loading: AddTagToManualNoteLoading }
  ] = useMutation<AddTagToManualNote>(ADD_TAG_TO_MANUAL_NOTE);
  const [createTag, { loading: createTagLoading }] = useMutation<CreateTag>(
    CREATE_TAG
  );
  const [removeTagFromNoteIfNoOccurences] = useMutation<
    RemoveTagFromNoteIfNoOccurrences
  >(REMOVE_TAG_FROM_NOTE_IF_NO_OCCURRENCES);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  // Removing tag mutation
  const [removeTagFromHighlight] = useMutation<RemoveTagFromHighlight>(
    REMOVE_TAG_FROM_HIGHLIGHT,
    {
      update: (cache, result) => {
        const highlightDeleted = result.data?.removeTagFromHighlight
          ?.highlightDeleted as boolean;
        if (highlightDeleted) {
          const data: any = cache.readFragment({
            id: `IntercomMessage:${targetId}`,
            fragment: gql`
              fragment readMessageDel on IntercomMessage {
                highlights {
                  id
                  content
                  startOffset
                  endOffset
                  tags {
                    id
                    name
                  }
                }
              }
            `
          });
          const { highlights: existingHighlights } = data;
          cache.writeFragment({
            id: `IntercomMessage:${targetId}`,
            fragment: gql`
              fragment writeMessageDel on IntercomMessage {
                highlights {
                  id
                  content
                  tags {
                    id
                    name
                  }
                }
              }
            `,
            data: {
              __typename: "IntercomMessage",
              highlights: existingHighlights.filter(
                (h: any) => h.id !== highlight.id
              )
            }
          });
          onClose();
        }
      }
    }
  );

  // Adding tag mutation
  const [addTagToHighlight] = useMutation<AddTagToHighlight>(
    ADD_TAG_TO_HIGHLIGHT,
    {
      update: (cache, result) => {
        const resultHighlight = result.data?.addTagToHighlight?.highlight;
        if (!highlight.id) {
          // Merge newly created highlight with the others for the intercommessage
          const data: any = cache.readFragment({
            id: `IntercomMessage:${targetId}`,
            fragment: gql`
              fragment readMessage on IntercomMessage {
                highlights {
                  id
                  content
                  startOffset
                  endOffset
                  tags {
                    id
                    name
                  }
                }
              }
            `
          });
          const { highlights: existingHighlights } = data;
          cache.writeFragment({
            id: `IntercomMessage:${targetId}`,
            fragment: gql`
              fragment writeMessage on IntercomMessage {
                highlights {
                  id
                  content
                  tags {
                    id
                    name
                  }
                }
              }
            `,
            data: {
              __typename: "IntercomMessage",
              highlights: [...existingHighlights, resultHighlight]
            }
          });
        }
      }
    }
  );
  const [
    getHighlight,
    { data: highlightData, loading: highlightLoading }
  ] = useLazyQuery<GetHighlightById>(GET_HIGHLIGHT_BY_ID, {
    variables: {
      id: highlight.id
    },
    fetchPolicy: "no-cache"
  });

  // Effects
  // Loads the new data in when a highlight is officially created
  React.useEffect(() => {
    if (highlight.id) {
      getHighlight();
    }
  }, [highlight, getHighlight]);

  if (highlightLoading) {
    return <Spinner></Spinner>;
  }

  const fetchedHighlight = highlightData?.getHighlightById?.highlight;
  const currentTagIds =
    fetchedHighlight?.tags.map(tag => tag.id) ||
    (highlight.tagIds as string[]) ||
    [];
  const isNewHighlight = highlight.id ? false : true;

  // Handles adding a new tag to a highlight/note
  const handleCreateTag = async () => {
    if (targetType === NoteTypes.MANUAL) {
      const result = await createTag({
        variables: {
          input: {
            name: searchQuery
          }
        }
      });

      const tagId = result?.data?.createTag?.tag?.id!;

      await addTagToManualNote({
        variables: {
          input: {
            tagId,
            noteId: targetId
          }
        }
      });
      if (onAddTag) {
        onAddTag(tagId);
      }
    } else if (targetType === HighlightableTypes.INTERCOM_MESSAGE) {
      if (isNewHighlight) {
        // Create a new highlight + new tag
        const result = await addTagToHighlight({
          variables: {
            input: {
              newTag: {
                name: searchQuery
              },
              newHighlight: {
                ...highlight,
                targetType,
                targetId
              }
            }
          }
        });
        const newHighlight = result?.data?.addTagToHighlight?.highlight!;
        onCreateNewHighlight(newHighlight);
      } else {
        await addTagToHighlight({
          variables: {
            input: {
              newTag: {
                name: searchQuery
              },
              highlightId: highlight.id
            }
          }
        });
        getHighlight();
      }
    }
    setSearchQuery("");
  };
  // Handles adding existing tag to a highlight/note
  const handleAddTag = async (tagId: string) => {
    console.log("HANDLE ADD");
    console.log(tagId);
    if (targetType === NoteTypes.MANUAL) {
      // gotta do some manual note shit
      addTagToManualNote({
        variables: {
          input: {
            tagId,
            noteId: targetId
          }
        }
      });
      if (onAddTag) {
        return onAddTag(tagId);
      }
      return;
    } else if (targetType === HighlightableTypes.INTERCOM_MESSAGE) {
      // For NOT manual notes
      if (isNewHighlight) {
        // Create a new highlight + new tag
        const result = await addTagToHighlight({
          variables: {
            input: {
              tagId,
              newHighlight: {
                ...highlight,
                targetType,
                targetId
              }
            }
          }
        });
        const newHighlight = result?.data?.addTagToHighlight?.highlight!;
        onCreateNewHighlight(newHighlight);
      } else {
        const result = await addTagToHighlight({
          variables: {
            input: {
              tagId,
              highlightId: highlight.id
            }
          }
        });
        getHighlight();
      }
    }
  };

  return (
    <Container>
      <ModalHeader>
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          "{highlight.content}"
        </div>
        <div style={{ position: "absolute", right: 12 }}>
          <CloseModalButton onClick={() => onClose()}>
            <FiX />
          </CloseModalButton>
        </div>
      </ModalHeader>
      {currentTagIds.length > 0 && (
        <CurrentTags>
          {currentTagIds.map(id => {
            return (
              <HighlightModalTag
                key={id}
                tagId={id}
                onClick={async () => {
                  if (targetType === HighlightableTypes.INTERCOM_MESSAGE) {
                    await removeTagFromHighlight({
                      variables: {
                        input: {
                          highlightId: highlight.id,
                          tagId: id
                        }
                      }
                    });
                    getHighlight();
                  } else if (targetType === "MANUAL") {
                    const result = await removeTagFromNoteIfNoOccurences({
                      variables: {
                        input: {
                          noteId: targetId,
                          tagId: id
                        }
                      }
                    });
                  }
                  if (onRemoveTag) {
                    onRemoveTag(id);
                  }
                }}
              />
            );
          })}
        </CurrentTags>
      )}
      <div>
        <Input
          forwardRef={inputRef}
          autoFocus
          style={{ width: "95%" }}
          type="text"
          placeholder="Search for features..."
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      <SimpleBar
        style={{ height: "100%", overflowX: "hidden", maxHeight: 300 }}
      >
        <HighlightModalTagResults
          currentTagIds={currentTagIds}
          targetType={targetType}
          targetId={targetId}
          onCreateTag={handleCreateTag}
          onAddTag={handleAddTag}
          searchQuery={searchQuery}
        ></HighlightModalTagResults>
      </SimpleBar>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${DARK_PRIMARY_DARK};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px black;
  overflow-x: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 447px;
`;

const CurrentTags = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${DARK_TERTIARY_DARK};
  padding: 16px;
  position: relative;
`;

const CloseModalButton = styled.div`
  cursor: pointer;
  :hover {
  }
`;

export default HighlightModal;

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Spinner } from "evergreen-ui";
import { createInviteLink } from "../../graphql/generated/types";
import ErrorShowIntercom from "../shared/ErrorShowIntercom";
import Input from "../shared/Input";
import { FiClipboard } from "react-icons/fi";
import styled from "@emotion/styled";
import { DARK_PRIMARY_LIGHT } from "../../colors";

const CREATE_INVITE_LINK = gql`
  query createInviteLink {
    createInviteLink {
      link
    }
  }
`;

function InviteTeammateLink() {
  const { data, loading } = useQuery<createInviteLink>(CREATE_INVITE_LINK);
  const [isCopied, setIsCopied] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  if (loading) {
    return <Spinner></Spinner>;
  }
  const link = data?.createInviteLink?.link;
  if (!link) {
    return <ErrorShowIntercom></ErrorShowIntercom>;
  }

  const handleCopy = () => {
    if (inputRef != null && inputRef.current != null) {
      inputRef.current.select();
      document.execCommand("copy");
      setIsCopied(true);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          forwardRef={inputRef}
          style={{ flex: 1 }}
          border
          value={link}
          readOnly
        ></Input>
        <CopyToClipboard onClick={handleCopy}>
          <FiClipboard size={24}></FiClipboard>
        </CopyToClipboard>
      </div>
      {isCopied && (
        <div style={{ marginTop: 4 }}>Copied link to clipboard!</div>
      )}
    </div>
  );
}

const CopyToClipboard = styled.div`
  padding: 8px;
  cursor: pointer;
  :hover {
    background-color: ${DARK_PRIMARY_LIGHT};
  }
`;

export default InviteTeammateLink;

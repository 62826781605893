import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import {
  DARK_TERTIARY,
  DARK_TERTIARY_DARK,
  DARK_TERTIARY_LIGHT
} from "../../colors";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";

type Props = {
  animated?: boolean;
  width: string;
  height: string;
} & ShortcutProps;

const COLORS = [
  DARK_TERTIARY,
  DARK_TERTIARY_LIGHT,
  DARK_TERTIARY,
  DARK_TERTIARY_DARK
];

function Logo(props: Props) {
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    const intervalId = props.animated
      ? setInterval(() => {
          setOffset((offset + 1) % 4);
        }, 300)
      : undefined;

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  });

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 300 300">
      <Path d="M0 150L150 0V70L70 150H0Z" color={COLORS[offset]} />
      <Path d="M150 0L300 150H230L150 70V0Z" color={COLORS[(offset + 1) % 4]} />
      <Path
        d="M300 150L150 300L150 230L230 150L300 150Z"
        color={COLORS[(offset + 2) % 4]}
      />
      <Path
        d="M150 300L-2.14537e-06 150L70 150L150 230L150 300Z"
        color={COLORS[(offset + 3) % 4]}
      />
    </Svg>
  );
}

export default withShortcuts(Logo);

const Svg = styled.svg``;

const Path = styled.path<{ color: string }>`
  fill: ${props => props.color};
  transition: fill 0.3s ease;
`;

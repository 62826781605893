// TODO: Finish

import styled from "@emotion/styled";
import isHotkey from "is-hotkey";
import * as React from "react";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_DARK,
  SECONDARY,
  SECONDARY_LIGHT,
  TERTIARY_DARK
} from "../../colors";

type Shortcut = {
  keys: string;
  callback: () => any;
};

type Props = {
  shortcuts: Shortcut[];
  border?: boolean;
  forwardRef?: React.Ref<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

type State = {};

class InputWithShortcuts extends React.Component<Props, State> {
  inputRef = React.createRef<HTMLInputElement>();

  map: any = {};

  focus = () => {
    this.inputRef.current && this.inputRef.current.focus();
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    for (const index in this.props.shortcuts) {
      const keys = this.props.shortcuts[index].keys;
      const callback = this.props.shortcuts[index].callback;
      if (isHotkey(keys, event as any)) {
        event.preventDefault();
        callback();
      }
    }
  };

  render() {
    const { border } = this.props;

    return (
      <Input
        border={border}
        ref={this.inputRef}
        {...this.props}
        onKeyDown={this.onKeyDown}
      ></Input>
    );
  }
}

export default InputWithShortcuts;

const Input = styled.input<any>`
  -webkit-appearance: none;
  border: 1px solid ${DARK_PRIMARY};
  background-image: none;
  background-color: ${DARK_PRIMARY_DARK};
  border-radius: 4px;
  color: ${SECONDARY_LIGHT};
  border: ${props => (props.border ? `1px solid ${TERTIARY_DARK}` : "none")};
  :focus {
    border: 1px solid ${TERTIARY_DARK};
    outline: none;
  }
  ::placeholder {
    color: ${SECONDARY};
  }
  padding: 10px 12px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.18);
  font-size: 14px;
`;

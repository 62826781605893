import styled from "@emotion/styled";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  DARK_PRIMARY,
  DARK_PRIMARY_DARK,
  DARK_SECONDARY_LIGHT,
  DARK_TERTIARY
} from "../../colors";
import { CurrentUser_me_user_organization_contacts } from "../../graphql/generated/types";
import Checkbox from "../shared/Checkbox";

type Props = {
  contact: CurrentUser_me_user_organization_contacts;
  focused: boolean;
  checked: boolean;
  focusCurrentElement: () => any;
  toggleCheckbox: () => any;
} & RouteComponentProps;

type State = {};

class ContactRow extends React.Component<Props, State> {
  redirectToContact = () => {
    this.props.history.push(`/person?id=${this.props.contact.id}`);
  };

  render() {
    return (
      <Container
        focused={this.props.focused}
        onClick={this.redirectToContact}
        onMouseEnter={this.props.focusCurrentElement}
      >
        <Checkbox
          checked={this.props.checked}
          onChange={this.props.toggleCheckbox}
        />
        <Name>{this.props.contact.name}</Name>
      </Container>
    );
  }
}

export default withRouter(ContactRow);

const Container = styled.div<{ focused: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 10px;
  color: ${DARK_SECONDARY_LIGHT};
  background-color: ${props =>
    props.focused ? DARK_PRIMARY : DARK_PRIMARY_DARK};
  padding-left: ${props => (props.focused ? "28px" : "30px")};
  border-left: ${props =>
    props.focused ? `2px solid ${DARK_TERTIARY}` : "none"};
`;

const Name = styled.div`
  margin-left: 20px;
`;

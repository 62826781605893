import styled from "@emotion/styled";
import * as React from "react";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import {
  DARK_PRIMARY_LIGHT,
  DARK_SECONDARY_DARK,
  DARK_TERTIARY,
  DARK_TERTIARY_DARK
} from "../../colors";
import {
  CurrentUserWithTags,
  CurrentUser_me_user
} from "../../graphql/generated/types";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import Input from "../shared/InputWithShortcuts";
import TagItem from "./TagItem";
import { useQuery } from "@apollo/react-hooks";
import { CURRENT_USER_WITH_TAGS } from "../../graphql/queries";
import { Spinner } from "evergreen-ui";

type Props = {
  user: CurrentUser_me_user;
} & ShortcutProps;

function Insights(props: Props) {
  const [search, setSearch] = useState<string>("");
  const { data: userData, loading: userLoading } = useQuery<
    CurrentUserWithTags
  >(CURRENT_USER_WITH_TAGS);

  if (userLoading) {
    return <Spinner></Spinner>;
  }

  const user = userData?.me?.user!;

  return (
    <Container>
      <SearchHeader>
        <SearchIcon>
          <FiSearch />
        </SearchIcon>
        <StyledInput
          shortcuts={[]}
          onChange={e => setSearch(e.target.value)}
          value={search}
          placeholder="Search for a feature..."
        />
      </SearchHeader>
      <SearchResults>
        {user.organization?.tags
          .filter(tag => {
            return tag.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          })
          .sort((a, b) => {
            if (a.noteCount < b.noteCount) {
              return 1;
            } else if (a.noteCount > b.noteCount) {
              return -1;
            }

            return a.contactCount < b.contactCount ? 1 : -1;
          })
          .map((tag, index) => {
            return <TagItem key={index} tag={tag} />;
          })}
      </SearchResults>
    </Container>
  );
}

export default withShortcuts(Insights);

const SearchHeader = styled.div`
  height: 59px;
  border-bottom: 1px solid ${DARK_PRIMARY_LIGHT};
  display: flex;
  align-items: center;
`;

const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${DARK_SECONDARY_DARK};
  margin-left: 30px;
  margin-right: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  border: none;
  :focus {
    border: none;
  }
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
`;

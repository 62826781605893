import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import gql from "graphql-tag";
import React from "react";
import { FiEdit2, FiUser } from "react-icons/fi";
import { DARK_PRIMARY_DARK, DARK_PRIMARY_LIGHT } from "../../colors";
import {
  getContacts_getContacts_contacts,
  GetInboxForUser_getInboxForUser_notes,
  updateNoteTitle,
} from "../../graphql/generated/types";
import useOutsideClick from "../../hooks/useClickedOutside";
import Input from "../shared/Input";
import ContactSelector from "./ContactSelector";

const UPDATE_NOTE_TITLE = gql`
  mutation updateNoteTitle($input: UpdateNoteTitleInput!) {
    updateNoteTitle(input: $input) {
      id
      title
    }
  }
`;

type Props = {
  note: GetInboxForUser_getInboxForUser_notes;
  contact: any;
  onClickContact: () => void;
};

function NoteHeader(props: Props) {
  const { note, contact, onClickContact } = props;
  const { title: noteTitle } = note;
  const [lastTitle, setLastTitle] = React.useState("");
  const [title, setTitle] = React.useState(noteTitle);
  const [updateNoteTitle] = useMutation<updateNoteTitle>(UPDATE_NOTE_TITLE);
  const [showSelectContact, setShowSelectContact] = React.useState(false);
  const contactSelectModal = React.useRef<HTMLDivElement>(null);
  const [isHoveringUser, setIsHoveringUser] = React.useState(false);

  useOutsideClick(contactSelectModal, () => {
    if (contactSelectModal.current && showSelectContact) {
      setShowSelectContact(false);
    }
  });

  const AUTOSAVE_INTERVAL = 1000;
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (lastTitle !== title) {
        updateNoteTitle({
          variables: {
            input: {
              noteType: note.type,
              noteId: note.id,
              newTitle: title,
            },
          },
        });
        setLastTitle(title);
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timer);
  }, [title, lastTitle, updateNoteTitle, note]);

  const handleClickUser = () => {
    onClickContact();
  };

  const handleSelectUser = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSelectContact(true);
  };

  const handleChangeContact = (contact: getContacts_getContacts_contacts) => {
    setShowSelectContact(false);
  };

  return (
    <Container style={{ display: "flex", flexDirection: "column" }}>
      <TitleInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Add Title"
      ></TitleInput>
      <UserRow
        onClick={handleClickUser}
        onMouseEnter={() => setIsHoveringUser(true)}
        onMouseLeave={() => setIsHoveringUser(false)}
      >
        {showSelectContact && (
          <ModalContainer ref={contactSelectModal}>
            <ContactSelector note={note} onContactClick={handleChangeContact} />
          </ModalContainer>
        )}
        <FiUser style={{ paddingRight: 8 }}></FiUser>
        {contact ? contact.name : "Anonymous"}
        {isHoveringUser && (
          <FiEdit2
            onClick={handleSelectUser}
            style={{ paddingLeft: 8 }}
          ></FiEdit2>
        )}
      </UserRow>
      {/* <TagRow>
        <FaTags style={{ paddingRight: 8 }}></FaTags>
      </TagRow> */}
      <div
        style={{
          backgroundColor: DARK_PRIMARY_LIGHT,
          height: 1,
          marginTop: 12,
        }}
      ></div>
    </Container>
  );
}

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  width: 300px;
  display: flex;
  z-index: 900;
  background-color: ${DARK_PRIMARY_DARK};
`;

const TitleInput = styled(Input)`
  display: flex;
  font-size: 1.5em;
  padding: 12px;
  margin-bottom: 4px;
`;

const UserRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

// const TagRow = styled.div`
//   display: flex;
//   padding: 8px 0;
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 40px;
  padding-bottom: 0px;
`;

export default NoteHeader;

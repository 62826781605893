import styled from "@emotion/styled";
import * as React from "react";
import SimpleBar from "simplebar-react";
import { DARK_PRIMARY_LIGHT, DARK_TERTIARY, DARK_WARNING } from "../../colors";
import withShortcuts from "../../shortcuts/withShortcuts";
import { ShortcutProps } from "../../types";
import LoadingScreen from "../LoadingScreen";
import Shortcut from "./Shortcut";
import { Button } from "./StyledComponents";

type Props = {
  loading?: boolean;
  onClickHeader?: () => any;
  tableListing: (
    index: number,
    element: any,
    checked: boolean,
    focused: boolean,
    focusCurrentElement: () => any,
    toggleCheckbox: () => any
  ) => any;
  onClickTableListing: (id: string) => any;
  tableArray: any[];
  currentPage: number;
  onNextPage: () => any;
  onPreviousPage: () => any;
  disableNext: boolean;
  disablePrevious: boolean;
  totalCount: number;
  elementName: string;
  notPaginated?: boolean;
  onDeleteElements?: (elements: any[]) => any;
} & ShortcutProps;

type State = {
  focusedIndex: number;
  selectedIndices: number[];
  focusedFilter: number;
};

class DashboardTable extends React.Component<Props, State> {
  state: State = {
    focusedFilter: 0,
    selectedIndices: [],
    focusedIndex: 0
  };

  UNSAFE_componentWillMount() {
    this.props.manager.bind(
      "j",
      this.focusNextElement,
      this.constructor.name,
      "Next item",
      1
    );
    this.props.manager.bind(
      "k",
      this.focusPreviousElement,
      this.constructor.name,
      "Previous item",
      1
    );
    this.props.manager.bind(
      "tab",
      this.nextFilter,
      this.constructor.name,
      "",
      1
    );
    this.props.manager.bind(
      "shift+tab",
      this.previousFilter,
      this.constructor.name,
      "",
      1
    );
    this.props.manager.bind(
      "enter",
      () => {
        this.props.onClickTableListing(
          this.props.tableArray[this.state.focusedIndex].id
        );
      },
      this.constructor.name,
      "Select item",
      1
    );
    this.props.manager.bind(
      "shift+j",
      this.selectNextElement,
      this.constructor.name,
      "Highlight current and next items",
      1
    );
    this.props.manager.bind(
      "shift+k",
      this.selectPreviousElement,
      this.constructor.name,
      "Highlight current and previous items",
      1
    );
    this.props.manager.bind(
      "space",
      this.selectCurrentElement,
      this.constructor.name,
      "Highlight current item",
      1
    );
  }

  componentWillUnmount() {
    this.props.manager.unbind("j", this.constructor.name);
    this.props.manager.unbind("k", this.constructor.name);
    this.props.manager.unbind("tab", this.constructor.name);
    this.props.manager.unbind("shift+tab", this.constructor.name);
    this.props.manager.unbind("enter", this.constructor.name);
    this.props.manager.unbind("shift+j", this.constructor.name);
    this.props.manager.unbind("shift+k", this.constructor.name);
    this.props.manager.unbind("space", this.constructor.name);
  }

  focusNextElement = () => {
    if (this.state.focusedIndex >= this.props.totalCount - 1) {
      return;
    }
    this.setState({
      focusedIndex: this.state.focusedIndex + 1
    });
  };

  focusPreviousElement = () => {
    if (this.state.focusedIndex === 0) {
      return;
    }
    this.setState({
      focusedIndex: this.state.focusedIndex - 1
    });
  };

  selectNextElement = () => {
    if (this.state.focusedIndex + 1 >= this.props.totalCount - 1) {
      return;
    }

    this.toggleSelectedIndex(this.state.focusedIndex + 1);

    this.setState({
      focusedIndex: this.state.focusedIndex + 1
    });
  };

  selectPreviousElement = () => {
    if (this.state.focusedIndex - 1 === 0) {
      return;
    }

    this.toggleSelectedIndex(this.state.focusedIndex - 1);

    this.setState({
      focusedIndex: this.state.focusedIndex - 1
    });
  };

  selectCurrentElement = () => {
    this.toggleSelectedIndex(this.state.focusedIndex);
  };

  nextFilter = (event: KeyboardEvent) => {
    event.preventDefault();
    this.setState({
      // TODO broken
      focusedFilter: (this.state.focusedFilter + 1) % 2,
      focusedIndex: 0
    });
  };

  previousFilter = (event: KeyboardEvent) => {
    event.preventDefault();
    this.setState({
      // TODO broken
      focusedFilter: (this.state.focusedFilter + 1) % 2,
      focusedIndex: 0
    });
  };

  toggleSelectedIndex = (index: number) => {
    if (
      this.state.selectedIndices.find(currentIndex => {
        return currentIndex === index;
      }) !== undefined
    ) {
      this.setState({
        selectedIndices: this.state.selectedIndices.filter(selectedIndex => {
          return selectedIndex !== index;
        })
      });
    } else {
      let newList = this.state.selectedIndices;
      newList.push(index);
      this.setState({
        selectedIndices: newList
      });
    }
  };

  onClickTableListing = () => {
    const id = this.props.tableArray[this.state.focusedIndex].id;
    this.props.onClickTableListing(id);
  };

  render() {
    const { tableArray } = this.props;

    const displayItems = this.props.notPaginated ? tableArray : tableArray;

    return (
      <TableContainer>
        {this.props.loading ? (
          <TableListingsBody>
            <LoadingScreen />
          </TableListingsBody>
        ) : (
          <SimpleBar style={{ height: "calc(100% - 60px)" }}>
            {displayItems.map((element, index) => {
              return this.props.tableListing(
                index,
                element,
                this.state.selectedIndices.find(currentIndex => {
                  return currentIndex === index;
                }) !== undefined,
                this.state.focusedIndex === index,
                () => {
                  this.setState({
                    focusedIndex: index
                  });
                },
                () => {
                  this.toggleSelectedIndex(index);
                }
              );
            })}
          </SimpleBar>
        )}
        {!this.state.selectedIndices.length ? (
          <TableFooter>
            <NumberOfRows>
              {this.props.totalCount} {this.props.elementName}
            </NumberOfRows>
            <PaginationSelector></PaginationSelector>
          </TableFooter>
        ) : (
          <TableFooter>
            <DeleteButton
              onClick={() => {
                if (!this.props.onDeleteElements) {
                  return;
                }
                this.props.onDeleteElements(
                  this.props.tableArray
                    .filter((element, index) => {
                      return this.state.selectedIndices.indexOf(index) >= 0;
                    })
                    .map(element => {
                      return element.id;
                    })
                );
              }}
            >
              <Delete>Delete</Delete>
              <Shortcut>D</Shortcut>
            </DeleteButton>
          </TableFooter>
        )}
      </TableContainer>
    );
  }
}

export default withShortcuts(DashboardTable);

const Delete = styled.div`
  margin-right: 10px;
`;

const DeleteButton = styled(Button)`
  margin-left: 40px;
  background-color: ${DARK_WARNING};
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  flex-direction: column;
`;

const TableFooter = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  height: 59px;
  width: 100%;
  border-top: 1px solid ${DARK_PRIMARY_LIGHT};
  color: ${DARK_TERTIARY};
`;

const NumberOfRows = styled.div`
  margin-left: 40px;
  font-size: 14px;
`;

const PaginationSelector = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-left: auto;
`;

const TableListingsBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: scroll;
`;

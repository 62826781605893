import React, { useEffect } from "react";

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const useMultipleOutsideClick = (refs: any[], callback: () => void) => {
  const handleClick = (e: any) => {
    let clickedOutsideAllRefs = true;
    refs.forEach(ref => {
      if (ref == null) {
        return;
      }
      if (ref.current) {
      }
      if (ref.current! && ref.current.contains(e.target)) {
        clickedOutsideAllRefs = false;
      }
    });
    if (clickedOutsideAllRefs) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useOutsideClick;
